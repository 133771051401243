import { alertActions } from './alert.actions'
import { loadingActions } from '../_actions/loading.actions'
import { messageService } from '../_services/message.service'
import { messageConstants as constants } from '../_constants/message.constants'

export const messageActions = {
  addMessage,
  clearState,
  getMessages,
  setConversation,
  getConversation,
  getConversations,
  getConversationAndMessages
}

function clearState() {
  return { type: constants.MESSAGES_INIT_STATE }
}

function addMessage(message) {
  return { type: constants.MESSAGES_ADD, message }
}

function setConversation(conversation) {
  return { type: constants.CONVERSATION_GET_SUCCESS, conversation }
}

function getConversations() {
  return (dispatch) => {
    dispatch(loadingActions.toggleLoading())
    dispatch(request())

    messageService
      .getConversations()
      .then((conversations) => {
        dispatch(success(conversations))
        dispatch(loadingActions.toggleLoading())
      })
      .catch(() => {
        window.scrollTo(0, 0)
        dispatch(failure())
        dispatch(loadingActions.toggleLoading())
        dispatch(
          alertActions.error(
            'An error occurred and we could not get your conversations at this time. Please try again.'
          )
        )
      })
  }
  function request() {
    return { type: constants.CONVERSATIONS_GET_REQUEST }
  }
  function failure() {
    return { type: constants.CONVERSATIONS_GET_FAILURE }
  }
  function success(conversations) {
    return { type: constants.CONVERSATIONS_GET_SUCCESS, conversations }
  }
}

function getConversation(conversationId) {
  return (dispatch) => {
    dispatch(loadingActions.toggleLoading())
    dispatch(request())

    messageService
      .getConversationById(conversationId)
      .then((conversation) => {
        dispatch(success(conversation))
        dispatch(loadingActions.toggleLoading())
      })
      .catch(() => {
        window.scrollTo(0, 0)
        dispatch(failure())
        dispatch(loadingActions.toggleLoading())
        dispatch(
          alertActions.error(
            'An error occurred and we could not get your conversation at this time. Please try again.'
          )
        )
      })
  }
  function request() {
    return { type: constants.CONVERSATION_GET_REQUEST }
  }
  function failure() {
    return { type: constants.CONVERSATION_GET_FAILURE }
  }
  function success(conversation) {
    return { type: constants.CONVERSATION_GET_SUCCESS, conversation }
  }
}

function getMessages(conversationId) {
  return (dispatch) => {
    dispatch(loadingActions.toggleLoading())
    dispatch(request())

    messageService
      .getMessagesByConversation(conversationId)
      .then((messages) => {
        dispatch(success(messages))
        dispatch(loadingActions.toggleLoading())
      })
      .catch(() => {
        window.scrollTo(0, 0)
        dispatch(failure())
        dispatch(loadingActions.toggleLoading())
        dispatch(
          alertActions.error(
            'An error occurred and we could not get your messages at this time. Please try again.'
          )
        )
      })
  }
  function request() {
    return { type: constants.MESSAGES_GET_REQUEST }
  }
  function failure() {
    return { type: constants.MESSAGES_GET_FAILURE }
  }
  function success(messages) {
    return { type: constants.MESSAGES_GET_SUCCESS, messages }
  }
}

function getConversationAndMessages(conversationId) {
  return (dispatch) => {
    dispatch(loadingActions.toggleLoading())
    dispatch(request())

    messageService
      .getConversationAndMessages(conversationId)
      .then((conversation) => {
        dispatch(success(conversation))
        dispatch(loadingActions.toggleLoading())
      })
      .catch(() => {
        window.scrollTo(0, 0)
        dispatch(failure())
        dispatch(loadingActions.toggleLoading())
        dispatch(
          alertActions.error(
            'An error occurred and we could not get your conversation at this time. Please try again.'
          )
        )
      })
  }
  function request() {
    return { type: constants.MESSAGES_GET_REQUEST }
  }
  function failure() {
    return { type: constants.MESSAGES_GET_FAILURE }
  }
  function success(conversation) {
    return { type: constants.MESSAGES_GET_SUCCESS, conversation }
  }
}
