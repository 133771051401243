import React, { Component } from 'react'
import queryString from 'query-string'
import { connect } from 'react-redux'

import PusherContext from '../../../_contexts/PusherContext'

import { history } from '../../../_helpers/history'
import { alertActions } from '../../../_actions/alert.actions'
import { doctorService } from '../../../_services/doctor.service'
import { messageActions } from '../../../_actions/message.actions'
import { messageService } from '../../../_services/message.service'

const withDataService = (WrappedComponent) => {
  class DataService extends Component {
    static contextType = PusherContext

    constructor(props) {
      super(props)

      this.state = {
        online: [],
        doctor: false,
        location: false
      }

      this.messagesInterval = false
      this.conversationInterval = false

      this.loadData = this.loadData.bind(this)
      this.handleTyping = this.handleTyping.bind(this)
      this.handleMessage = this.handleMessage.bind(this)
      this.handleAttachment = this.handleAttachment.bind(this)
    }

    componentDidMount() {
      if (this.props.match && this.props.match.params.id) {
        // Existing Conversation
        this.props.dispatch(
          messageActions.getConversation(this.props.match.params.id)
        )
        this.props.dispatch(
          messageActions.getMessages(this.props.match.params.id)
        )
      } else {
        // New Conversation
        this.setDoctor()
      }
    }

    componentDidUpdate(prevProps) {
      if (!prevProps.conversation && this.props.conversation) {
        this.setState({
          location: this.props.conversation.location || false
        })

        if (this.context.connection.state === 'unavailable') {
          this.initiatePolling()
        }

        this.initiateSocket()
      }

      if (prevProps.doctors !== this.props.doctors) {
        this.setDoctor()
      }
    }

    componentWillUnmount() {
      this.props.dispatch(messageActions.clearState())

      this.unSubcribeChannels()

      this.disablePolling()
    }

    setDoctor() {
      if (this.props.match && this.props.match.params.code) {
        // URL Doctor Code
        const queryParams = queryString.parse(window.location.search)

        doctorService
          .getByCode(this.props.match.params.code)
          .then((doctor) => {
            const location = doctor.locations.data.find((location) => {
              return location.id === parseInt(queryParams.location)
            })
            this.setState({ doctor, location })
          })
          .catch(() => {
            history.push('/messages')

            this.props.dispatch(
              alertActions.error(
                this.props.t('The healthcare provider was not found.')
              )
            )
          })
      } else {
        // History State Doctor
        const doctorId =
          this.props.history.location.state &&
          this.props.history.location.state.doctorId
            ? this.props.history.location.state.doctorId
            : null

        const doctor = this.props.doctors.find(
          (doctor) => doctor.id === doctorId
        )

        if (doctor) {
          const location = doctor.locations.data.find((location) => {
            return location.id === this.props.history.location.state.locationId
          })

          this.setState({ doctor, location })
        }
      }
    }

    initiateSocket() {
      this.presenceChannel = this.context.subscribe(
        `presence-conversation.${this.props.conversation.id}`
      )

      this.presenceChannel
        .bind('pusher:subscription_succeeded', (data) => {
          this.setState({
            online: Object.keys(data.members)
              .filter((memberId) => {
                return (
                  parseInt(memberId) !== parseInt(this.props.patient.userId)
                )
              })
              .map((key) => {
                return {
                  ...data.members[key],
                  typing: false
                }
              })
          })
        })
        .bind('pusher:member_added', (data) => {
          this.setState({
            online: [...this.state.online, { ...data.info, typing: false }]
          })
        })
        .bind('pusher:member_removed', (data) => {
          this.setState({
            online: this.state.online.filter((online) => {
              return online.userId !== data.id
            })
          })
        })
        .bind('client-typing', (data) => {
          this.setState({
            online: this.state.online.map((online) => {
              return {
                ...online,
                typing:
                  online.userId === data.userId ? data.typing : online.typing
              }
            })
          })
        })
        .bind('messageCreated', (message) => {
          this.props.dispatch(messageActions.addMessage(message))

          messageService.messageRead(this.props.conversation.id).catch(() => {})
        })
        .bind('conversationUpdatedPatient', (conversation) => {
          this.props.dispatch(messageActions.setConversation(conversation))
        })

      this.context.connection.bind('state_change', this.connectionChange, this)
    }

    connectionChange(state) {
      if (state.current === 'connected') {
        this.disablePolling()
      } else if (
        state.current === 'unavailable' ||
        state.current === 'failed'
      ) {
        this.initiatePolling()
      }
    }

    initiatePolling() {
      if (this.messagesInterval === false) {
        this.loadMessages(this.props.conversation.id)

        this.messagesInterval = setInterval(() => {
          this.loadMessages(this.props.conversation.id)
        }, 5000)
      }

      if (this.conversationInterval === false) {
        this.loadConversation(this.props.conversation.id)

        this.conversationInterval = setInterval(() => {
          this.loadConversation(this.props.conversation.id)
        }, 5000)
      }
    }

    disablePolling() {
      clearInterval(this.messagesInterval)
      clearInterval(this.conversationInterval)
    }

    loadMessages(conversationId) {
      this.props.dispatch(messageActions.getMessages(conversationId))
    }

    loadConversation(conversationId) {
      this.props.dispatch(messageActions.getConversation(conversationId))
    }

    unSubcribeChannels() {
      if (this.presenceChannel) {
        this.presenceChannel.unbind()
        this.context.unsubscribe(
          `presence-conversation.${this.props.conversation.id}`
        )
      }

      this.context.connection.unbind(
        'state_change',
        this.connectionChange,
        this
      )
    }

    handleMessage(from, message) {
      const conversationId = this.props.conversation
        ? this.props.conversation.id
        : null

      return messageService
        .sendOrCreateMessage(
          message,
          conversationId,
          this.state.doctor.id,
          this.state.location ? this.state.location.id : null,
          from.userProfileId,
          this.context.connection.socket_id
        )
        .then(this.loadData)
    }

    handleAttachment(from, attachment) {
      const conversationId = this.props.conversation
        ? this.props.conversation.id
        : null

      return messageService
        .sendOrCreateAttachment(
          attachment,
          conversationId,
          this.state.doctor.id,
          this.state.location ? this.state.location.id : null,
          from.userProfileId,
          this.context.connection.socket_id
        )
        .then(this.loadData)
    }

    loadData(data) {
      if (!this.props.conversation) {
        history.replace(`/messages/${data.id}/stream`)

        this.props.dispatch(messageActions.setConversation(data))
        this.props.dispatch(messageActions.addMessage(data.firstMessage))
      } else {
        this.props.dispatch(messageActions.addMessage(data))
      }
    }

    handleTyping(currentUser, typing) {
      if (this.presenceChannel) {
        this.presenceChannel.trigger('client-typing', {
          typing: typing,
          userId: currentUser.userId,
          firstName: currentUser.firstName,
          lastName: currentUser.lastName,
          profileUrl: currentUser.profileUrlSizes
            ? currentUser.profileUrlSizes.sml
            : currentUser.profileUrl
        })
      }
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          online={this.state.online}
          doctor={this.state.doctor}
          location={this.state.location}
          isTyping={this.handleTyping}
          sendMessage={this.handleMessage}
          sendAttachment={this.handleAttachment}
          loadConversation={() =>
            this.loadConversation(this.props.conversation.id)
          }
        />
      )
    }
  }

  const mapStateToProps = (state) => {
    return {
      doctors: state.user.doctors || [],
      patient: state.user.currentUser,
      messages: state.message.messages || [],
      dependents: state.user.dependents || [],
      conversation: state.message.conversation,
      messagesLoading: state.message.messagesLoading
    }
  }

  return connect(mapStateToProps)(DataService)
}

export default withDataService
