import { alertConstants } from '../_constants/alert.constants'

export const alertActions = {
  success,
  warning,
  error,
  info,
  clear,
  delay,
  clearDelay
}

function warning(message, heading = '') {
  return { type: alertConstants.WARNING, heading, message }
}

function info(message, heading = '') {
  return { type: alertConstants.INFO, heading, message }
}

function success(message, heading = '') {
  return { type: alertConstants.SUCCESS, heading, message }
}

function error(message, heading = '') {
  return { type: alertConstants.ERROR, heading, message }
}

function delay(message, color, heading = '') {
  return { type: alertConstants.DELAY, color, heading, message }
}

function clearDelay() {
  return { type: alertConstants.CLEAR_DELAY }
}

function clear() {
  return { type: alertConstants.CLEAR }
}
