import React from 'react'
import Alert from '../../_components/Alerts/Alert'
import VerifyCode from '../../_components/Verify/VerifyCode'
import ResetPasswordForm from './Components/ResetPasswordForm'
import ForgotPasswordForm from './Components/ForgotPasswordForm'

import { connect } from 'react-redux'
import { alertActions } from '../../_actions/alert.actions'
import { forgotService } from '../../_services/forgot.service'
import { withTranslation } from 'react-i18next'
import { isValidPassword } from '../../_helpers/regex'
import { registrationService } from '../../_services/registration.service'
import { authenticationService } from '../../_services/authentication.service'

class ForgotPasswordPage extends React.Component {
  constructor(props) {
    super(props)

    if (authenticationService.currentUserValue) {
      this.props.history.push('/')
    }

    this.state = {
      step: 1,
      reset: false,
      verify: false,
      loading: false,
      username: '',
      password: '',
      confirmPassword: '',
      userFound: null,
      resending: false,
      code: '',
      errors: []
    }
  }

  handleFindUser = (e) => {
    e.preventDefault()

    const errors = {}
    const { username } = this.state
    const { dispatch } = this.props

    if (username.length === 0) {
      errors.username = 'Username is required.'
    }
    this.setState({ errors })

    if (!errors.username) {
      this.toggleLoading()
      registrationService
        .usernameExists(username)
        .then((exists) => {
          if (exists === false) {
            dispatch(alertActions.error('Username Not Found'))
          } else {
            dispatch(alertActions.clear())
          }
          this.setState({ userFound: !!exists, loading: false })
        })
        .catch(() => {
          this.showErrorMessage()
          this.toggleLoading()
        })
    }
  }

  handleValidatePassword = () => {
    const errors = {}
    const { password, confirmPassword } = this.state

    if (isValidPassword(password) === false) {
      errors.password = 'Password is invalid.'
    }

    if (password.length === 0) {
      errors.password = 'New password is required.'
    }

    if (confirmPassword.length === 0) {
      errors.confirmPassword = 'New password is required.'
    }

    if (confirmPassword !== password) {
      errors.password = 'Passwords must match.'
      errors.confirmPassword = 'Passwords must match.'
    }

    this.setState({ errors })

    if (!errors.password && !errors.code) {
      this.toggleLoading()
      this.handleSendVerifyCode()
    }
  }

  handleSendVerifyCode = () => {
    const { step, username } = this.state
    forgotService
      .sendVerifyCode(username)
      .then((response) => {
        if (step === 1) {
          this.stepForward()
        }
        this.setState({ loading: false, resending: false })
      })
      .catch(() => {
        this.setState({ loading: false, resending: false })
        this.showErrorMessage()
      })
    this.scrollToTop()
  }

  handleResetPassword = () => {
    const errors = {}
    const { dispatch } = this.props
    const { username, password, code } = this.state

    if (code.length === 0) {
      errors.code = 'Verification code is required.'
    }
    this.setState({ errors })

    if (!errors.code) {
      this.toggleLoading()
      forgotService
        .resetPassword(username, password, code)
        .then((response) => {
          dispatch(
            alertActions.success(
              'Your password has been reset. Please login using your new password.'
            )
          )
          this.setState({ reset: true, loading: false })
        })
        .catch((error) => {
          if (error.response && error.response.status === 422) {
            this.setState({ errors: error.response.data, loading: false })
          } else {
            this.toggleLoading()
            this.showErrorMessage()
          }
        })
      this.scrollToTop()
    }
  }

  showErrorMessage = () => {
    this.props.dispatch(
      alertActions.error('An error occurred. Please try again.')
    )
  }

  scrollToTop = () => {
    window.scroll(0, 0)
  }

  stepForward = () => {
    this.scrollToTop()
    this.props.dispatch(alertActions.clear())
    this.setState((prevState) => {
      return {
        ...prevState,
        step: prevState.step + 1
      }
    })
  }

  stepBackward = () => {
    this.scrollToTop()
    this.props.dispatch(alertActions.clear())
    this.setState((prevState) => {
      return {
        ...prevState,
        step: prevState.step - 1
      }
    })
  }

  toggleLoading = () => {
    this.setState((prevState) => {
      return {
        ...prevState,
        loading: !prevState.loading
      }
    })
  }

  handleSetState = (value) => {
    this.setState(value)
  }

  render() {
    const { t, alert } = this.props
    const {
      step,
      code,
      reset,
      errors,
      loading,
      username,
      password,
      resending,
      userFound,
      confirmPassword
    } = this.state

    return (
      <div>
        {step === 1 && (
          <div className='p-4'>
            <h3 className='text-center py-3 px-sm-5'>{t('Forgot Password')}</h3>
            {userFound !== true && (
              <ForgotPasswordForm
                alert={alert}
                errors={errors}
                loading={loading}
                username={username}
                handleFindUser={this.handleFindUser}
                handleSetState={this.handleSetState}
              />
            )}
            {step === 1 && userFound === true && (
              <ResetPasswordForm
                code={code}
                alert={alert}
                reset={reset}
                errors={errors}
                loading={loading}
                password={password}
                resending={resending}
                confirmPassword={confirmPassword}
                handleSetState={this.handleSetState}
                handleResendCode={this.handleSendVerifyCode}
                handleResetPassword={this.handleValidatePassword}
              />
            )}
          </div>
        )}
        {step === 2 && userFound === true && (
          <div className='pb-4 mb-2'>
            <h3 className='text-center py-3 px-sm-5 mx-3'>
              {t('Forgot Password')}
            </h3>
            {reset === true ? (
              <Alert className='mx-4' alert={alert} />
            ) : (
              <VerifyCode
                code={code}
                alert={alert}
                errors={errors}
                loading={loading}
                resending={resending}
                submitTitle='Reset Password'
                checkCode={this.handleResetPassword}
                stepBackward={this.stepBackward}
                setParentState={this.handleSetState}
                resendVerifyCode={() =>
                  this.setState({ resending: true }, () =>
                    this.handleSendVerifyCode()
                  )
                }
                mutedInfoComponent={
                  <small>{t('Check your phone or email for your code.')}</small>
                }
                titleComponent={t(
                  'Please enter the digit code we sent you to verify your identity.'
                )}
              />
            )}
          </div>
        )}
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    alert: state.alert.nextRouteMessage
  }
}
export default connect(mapStateToProps)(withTranslation()(ForgotPasswordPage))
