import React, { useState, useEffect } from 'react'
import moment from 'moment'
import useWindowDimensions from '../../../_helpers/dimensions'

import { useDispatch } from 'react-redux'
import { userActions } from '../../../_actions/user.actions'
import { Modal, Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { dependentService } from '../../../_services/dependent.service'
import { PatientAddFamilyMember } from '@axshealth/design-library'

export default function EditFamilyMember({
  familyMember,
  showEditFamilyMember,
  hideEditFamilyMember
}) {
  const { t } = useTranslation()

  const [day, setDay] = useState('')
  const [year, setYear] = useState('')
  const [month, setMonth] = useState()
  const [gender, setGender] = useState('')
  const [errors, setErrors] = useState({})
  const [loading, setLoading] = useState(false)
  const [lastName, setLastName] = useState('')
  const [firstName, setFirstName] = useState('')
  const [dateOfBirth, setDateOfBirth] = useState('')

  const dispatch = useDispatch(null)

  const { width, breakPointSM } = useWindowDimensions()
  const mobile = width < breakPointSM

  useEffect(() => {
    if (familyMember) {
      setInitValues()
    }
  }, [familyMember])

  const setInitValues = () => {
    const current = familyMember
    setErrors({})
    setMonth(parseInt(moment(current.dateOfBirth, 'YYYY-MM-DD').format('M')))
    setYear(moment(current.dateOfBirth, 'YYYY-MM-DD').year())
    setDay(parseInt(moment(current.dateOfBirth, 'YYYY-MM-DD').format('D')))
    setGender(current.sex[0].toLowerCase())
    setLastName(current.lastName)
    setFirstName(current.firstName)
  }

  const handleHideEditFamilyMember = () => {
    setInitValues()
    hideEditFamilyMember()
  }

  const handleEditFamilyMember = () => {
    const errors = {}

    if (firstName.trim() === '') {
      errors.firstName = 'First name is required.'
    }
    if (lastName.trim() === '') {
      errors.lastName = 'Last name is required.'
    }
    if (gender.length === 0) {
      errors.gender = 'Gender is required.'
    }
    if (dateOfBirth.length === 0) {
      errors.dateOfBirth = 'Date of Birth is required.'
    }

    setErrors(errors)

    if (
      !errors.firstName &&
      !errors.lastName &&
      !errors.gender &&
      !errors.dateOfBirth
    ) {
      const updatedFamilyMember = {
        gender,
        lastName,
        firstName,
        dateOfBirth: moment(dateOfBirth).format('YYYY-MM-DD')
      }

      setLoading(true)

      dependentService
        .editDependent(familyMember.userProfileId, updatedFamilyMember)
        .then(() => {
          setLoading(false)
          handleHideEditFamilyMember()
          dispatch(userActions.storeUser())
          window.alert(t('Family Member Updated!'))
        })
        .catch((error) => {
          setLoading(false)
          window.alert(
            t(
              'Family Member could not be updated at this time. Please check your connection and try again.'
            )
          )
          console.log(error.response)
        })
    }
  }

  return (
    <Modal
      size='lg'
      show={showEditFamilyMember}
      onHide={handleHideEditFamilyMember}
      keyboard={false}
      centered={mobile}
    >
      <Modal.Header>
        <Modal.Title>{t('Edit Family Member')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <PatientAddFamilyMember
          day={day}
          year={year}
          month={month}
          gender={gender}
          errors={errors}
          lastName={lastName}
          firstName={firstName}
          setDay={(day) => setDay(day)}
          setYear={(year) => setYear(year)}
          setMonth={(month) => setMonth(month)}
          setGender={(gender) => setGender(gender)}
          setLastName={(lastName) => setLastName(lastName)}
          setFirstName={(firstName) => setFirstName(firstName)}
          setDateOfBirth={(dateOfBirth) => setDateOfBirth(dateOfBirth)}
        />
      </Modal.Body>
      <Modal.Footer
        style={
          mobile ? { flexDirection: 'column-reverse', flexWrap: 'nowrap' } : {}
        }
      >
        <Button
          block={mobile}
          disabled={loading}
          variant='outline-primary'
          onClick={handleHideEditFamilyMember}
        >
          {t('Back')}
        </Button>

        <Button
          block={mobile}
          disabled={loading}
          variant='success'
          onClick={handleEditFamilyMember}
        >
          {loading ? `${t('Updating')}...` : t('Update Family Member')}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
