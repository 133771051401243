export const authenticationConstants = {
  AUTHENTICATION_DISPLAY_PREFERRED_PLATFORM:
    'AUTHENTICATION_DISPLAY_PREFERRED_PLATFORM',
  AUTHENTICATION_SET_PREFERRED_PLATFORM:
    'AUTHENTICATION_SET_PREFERRED_PLATFORM',
  AUTHENTICATION_CLICK_PREFERRED_PLATFORM:
    'AUTHENTICATION_CLICK_PREFERRED_PLATFORM',

  AUTHENTICATION_CLEAR_VERIFIED_TOKEN: 'AUTHENTICATION_CLEAR_VERIFIED_TOKEN',
  AUTHENTICATION_SET_VERIFIED_TOKEN: 'AUTHENTICATION_SET_VERIFIED_TOKEN',
  AUTHENTICATION_REMEMBER_USERNAME: 'AUTHENTICATION_REMEMBER_USERNAME',
  AUTHENTICATION_LOGIN_REQUEST: 'AUTHENTICATION_LOGIN_REQUEST',
  AUTHENTICATION_LOGIN_SUCCESS: 'AUTHENTICATION_LOGIN_SUCCESS',
  AUTHENTICATION_LOGIN_FAILURE: 'AUTHENTICATION_LOGIN_FAILURE',
  AUTHENTICATION_SHOW_PASSWORD: 'AUTHENTICATION_SHOW_PASSWORD',
  AUTHENTICATION_SET_USERNAME: 'AUTHENTICATION_SET_USERNAME',
  AUTHENTICATION_LOGOUT: 'AUTHENTICATION_LOGOUT'
}
