import React from 'react'
import NumberFormat from 'react-number-format'
import RenderValidateMessage from '../Validation/RenderValidateMessage'

import { Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

export default function NumberInput(props) {
  const {
    errors,
    value,
    style,
    label,
    setValue,
    valueName,
    className,
    placeholder,
    handleEnter,
    disabled = false
  } = props
  const { t } = useTranslation()

  return (
    <Form.Group className='mb-0'>
      {label && (
        <Form.Label className={valueName in errors ? 'text-danger' : ''}>
          {t(label)}
        </Form.Label>
      )}
      <NumberFormat
        name='numberInput'
        value={value}
        style={style}
        format='##########'
        disabled={disabled}
        onKeyDown={handleEnter}
        placeholder={placeholder}
        className={`form-control ${className} ${
          valueName in errors ? 'is-invalid' : ''
        }`}
        onValueChange={(e) => setValue({ [valueName]: parseInt(e.value) })}
      />
      <RenderValidateMessage errors={errors} field={valueName} />
    </Form.Group>
  )
}
