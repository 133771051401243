import App from './App'
import React from 'react'
import ReactDOM from 'react-dom'
import ErrorBoundary from './_helpers/error-boundary'
import * as serviceWorker from './serviceWorker'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { store, persistor } from './_helpers/store'

import './style.css'
import './i18n/i18n'
import 'font-awesome/css/font-awesome.min.css'
import '@axshealth/design-library/dist/index.css'

if (process.env.REACT_APP_ENVIRONMENT !== 'development') {
  Sentry.init({
    dsn: process.env.REACT_APP_BUGCATCHER_URL,
    integrations: [new BrowserTracing()],
    tracesSampleRate: parseFloat(process.env.REACT_APP_BUGCATCHER_TRACE),
    environment: process.env.REACT_APP_ENVIRONMENT,
    release:
      process.env.REACT_APP_API_VERSION + ':' + process.env.REACT_APP_BUILD
  })
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
