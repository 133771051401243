import React from 'react'
import { Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

export default function ActionButton(props) {
  const {
    block = true,
    action,
    submit,
    style,
    loading,
    disabled,
    className,
    variant = 'primary'
  } = props
  const { t } = useTranslation()

  return (
    <Button
      block={block}
      style={style}
      variant={variant}
      onClick={submit}
      disabled={loading || disabled}
      className={className}
    >
      {loading ? <i className='fa fa-spinner fa-spin fa-fw' /> : t(action)}
    </Button>
  )
}
