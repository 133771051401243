import React from 'react'
import NotificationType from './NotificationTypes'

import { Dropdown } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { ConsentFullName } from '../../_helpers/licensed-professional-name'

import {
  NotificationNewMessage,
  NotificationNewCarePlan,
  NotificationNewTelehealthVideo,
  NotificationNewTelehealthMessage
} from '@axshealth/design-library'

export default function NotificationComponent({ notifications, handleClick }) {
  const doctors = useSelector((state) => state.user.doctors)

  const Notification = (notification) => {
    const type = notification.notificationTypeId
    const doctor = doctors.find((doctor) => doctor.id === notification.doctorId)

    notification.name = doctor ? ConsentFullName(doctor) : ''
    notification.time = notification.created

    switch (type) {
      case NotificationType.NEW_MESSAGE:
        return (
          <NotificationNewMessage
            notification={notification}
            handleClick={() => handleClick(notification)}
          />
        )
      case NotificationType.NEW_CARE_PLAN:
        return (
          <NotificationNewCarePlan
            notification={notification}
            handleClick={() => handleClick(notification)}
          />
        )
      case NotificationType.NEW_TELEHEALTH_VIDEO:
        return (
          <NotificationNewTelehealthVideo
            notification={notification}
            handleClick={() => handleClick(notification)}
          />
        )
      case NotificationType.NEW_TELEHEALTH_MESSAGE:
        return (
          <NotificationNewTelehealthMessage
            notification={notification}
            handleClick={() => handleClick(notification)}
          />
        )
    }
  }

  return (
    <div>
      {notifications.map((notification, index) => {
        const lastNotification =
          notification.length > 1 && notifications.length - 1 === index
        return (
          <Dropdown.Item
            key={notification.userNotificationId}
            style={{ whiteSpace: 'break-spaces' }}
            className={`${lastNotification ? '' : 'border-bottom'} p-2`}
          >
            {Notification(notification)}
          </Dropdown.Item>
        )
      })}
    </div>
  )
}
