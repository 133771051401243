import React, { useEffect } from 'react'

import { faSyncAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function SwitchCameraButton({ loading, facing, toggleCamera }) {
  useEffect(() => {
    const mediaBtn = document.getElementById('mediaSettingsBtn')
    facing === 'environment'
      ? mediaBtn.classList.remove('on')
      : mediaBtn.classList.add('on')
    loading
      ? mediaBtn.classList.add('disabled')
      : mediaBtn.classList.remove('disabled')
  }, [facing, loading])

  return (
    <button
      id='mediaSettingsBtn'
      type='button'
      disabled={loading}
      onClick={() => toggleCamera()}
      className='teleVideoBtn'
    >
      <FontAwesomeIcon icon={faSyncAlt} />
    </button>
  )
}
