import React, { Component, Fragment } from 'react'
import MainMenu from '../../_components/MainMenu'
import AddFamilyMember from './Components/AddFamilyMember'
import EditFamilyMember from './Components/EditFamilyMember'

import { connect } from 'react-redux'
import { faUserFriends } from '@fortawesome/free-solid-svg-icons'
import { userActions } from '../../_actions/user.actions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { withTranslation } from 'react-i18next'
import { dependentService } from '../../_services/dependent.service'
import { PatientFamilyMember } from '@axshealth/design-library'
import { Spinner, Row, Col, Card, Button, Container } from 'react-bootstrap'

class FamilyMembers extends Component {
  constructor(props) {
    super(props)

    this.state = {
      familyMember: false,
      showAddFamilyMember: false,
      showEditFamilyMember: false
    }

    this.handleAdd = this.handleAdd.bind(this)
    this.handleEdit = this.handleEdit.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
  }

  handleAdd = () => {
    this.setState({ showAddFamilyMember: true })
  }

  handleEdit = (familyMember) => {
    this.setState({ familyMember: familyMember, showEditFamilyMember: true })
  }

  handleDelete = (familyMember) => {
    const answer = window.confirm(
      this.props.t('Are you sure you want to delete this family member?')
    )
    if (answer && Object.keys(this.props.currentUser).length > 0) {
      const userProfileId = familyMember.userProfileId

      dependentService
        .deleteDependent(userProfileId)
        .then(() => {
          this.props.dispatch(userActions.storeUser())
          window.alert(this.props.t('Family Member Deleted!'))
        })
        .catch((error) => {
          window.alert(
            this.props.t(
              'Family Member could not be deleted at this time. Please check your connection and try again.'
            )
          )
          console.log(error.response)
        })
    }
  }

  Empty = () => {
    return (
      <Card style={{ border: '0px' }} className='mt-3'>
        <Card.Body>
          <Container fluid className='p-0'>
            <Row>
              <Col>
                <p className='font__size__main_mobile font-weight-bold'>
                  {this.props.t('No family members currently.')}
                </p>
                <div className='font__size__sub'>
                  {this.props.t(
                    'Click "Add Family Member" above to add a family member'
                  )}
                </div>
              </Col>
            </Row>
          </Container>
        </Card.Body>
      </Card>
    )
  }

  List = () => {
    return (
      <Fragment>
        <Row className='d-block d-sm-none mt-3'>
          <Col md={12}>
            {this.props.dependents &&
              Object.keys(this.props.dependents).length > 0 &&
              this.props.dependents.map((familyMember) => {
                return (
                  <div key={familyMember.userProfileId}>
                    <PatientFamilyMember
                      card
                      dark={false}
                      patient={familyMember}
                      handleEdit={() => this.handleEdit(familyMember)}
                      handleDelete={() => this.handleDelete(familyMember)}
                    />
                    <br />
                  </div>
                )
              })}
          </Col>
        </Row>
        <Row className='d-sm-block d-none'>
          <Col md={12}>
            <Card className='mt-3'>
              <Card.Body>
                {this.props.dependents &&
                  Object.keys(this.props.dependents).length > 0 &&
                  this.props.dependents.map((familyMember, index) => {
                    return (
                      <div key={familyMember.userProfileId}>
                        <PatientFamilyMember
                          card={false}
                          dark={false}
                          patient={familyMember}
                          handleEdit={() => this.handleEdit(familyMember)}
                          handleDelete={() => this.handleDelete(familyMember)}
                        />
                        {index < this.props.dependents.length - 1 && <hr />}
                      </div>
                    )
                  })}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Fragment>
    )
  }

  render() {
    return (
      <Row>
        <Col lg={2} className='pt-1 px-0 d-none d-lg-block'>
          <MainMenu />
        </Col>
        <Col sm={12} lg={10}>
          <Row>
            <Col>
              <FontAwesomeIcon icon={faUserFriends} className='mr-2' />
              <span style={{ fontSize: '6px', verticalAlign: 'middle' }}>
                •
              </span>
              <span className='ml-2'>{this.props.t('Family Members')}</span>
            </Col>
          </Row>
          <Row className='mt-3'>
            <Col>
              <Row>
                <Col lg={12}>
                  <div className='main__title main__text'>
                    {this.props.t('Your Family Members')}
                  </div>
                </Col>
                {this.props.dependents && this.props.dependents.length > 0 && (
                  <Col lg={12} className='mt-1 main__text mb-3'>
                    {this.props.t("Below you'll find your family members.")}
                  </Col>
                )}
              </Row>
            </Col>

            <Col xs={12} sm={4} md={3}>
              <Button
                block
                variant='success'
                className='my-3 my-sm-0 font-weight-light'
                onClick={this.handleAdd}
              >
                {this.props.t('Add Family Member')}
              </Button>
            </Col>
          </Row>

          <AddFamilyMember
            showAddFamilyMember={this.state.showAddFamilyMember}
            hideAddFamilyMember={() =>
              this.setState({ showAddFamilyMember: false })
            }
          />

          <EditFamilyMember
            familyMember={this.state.familyMember}
            showEditFamilyMember={this.state.showEditFamilyMember}
            hideEditFamilyMember={() =>
              this.setState({ showEditFamilyMember: false })
            }
          />

          <Row>
            <Col>
              {this.props.dependents === null ? (
                <Spinner animation='border' className='mt-2' />
              ) : (
                <Fragment>
                  {this.props.dependents.length === 0 && this.Empty()}
                  {this.props.dependents.length > 0 && this.List()}
                </Fragment>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }
}

function mapStateToProps(state) {
  return {
    dependents: state.user.dependents,
    currentUser: state.user.currentUser
  }
}
export default connect(mapStateToProps)(withTranslation()(FamilyMembers))
