import React from 'react'
import Logo from '../../images/logo@3x.png'
import { Image } from 'react-bootstrap'

export default function LoginSidebar(props) {
  return (
    <div className='instructions font-weight-light text-white text-center m-auto py-3 px-1'>
      {!props.loading &&
        props.loading !== undefined &&
        (props.provider && props.provider.logo ? (
          <Image
            className='my-3'
            fluid
            src={props.provider && props.provider.logo}
          />
        ) : (
          <Image className='my-3' width='180' height='180' src={Logo} />
        ))}
    </div>
  )
}
