/* eslint-disable react/no-did-update-set-state */
import React, { Component } from 'react'
import moment from 'moment'
import Online from './Components/Online'
import NewMsgImg from '../../../_assets/new-message.png'
import classNames from 'classnames'
import MessageSidebar from './Components/MessageSidebar'
import withDataService from './withDataService'
import AttachmentModal from '../../../_components/Messaging/AttachmentModal'
import AddFamilyMember from '../../FamilyMembers/Components/AddFamilyMember'
import SendMessageChatBox from './Components/SendMessageChatBox'

import { Link } from 'react-router-dom'
import { alertActions } from '../../../_actions/alert.actions'
import { withTranslation } from 'react-i18next'

import {
  Row,
  Col,
  Card,
  Alert,
  Image,
  Spinner,
  Breadcrumb
} from 'react-bootstrap'

import {
  Message,
  PatientConfirmList,
  PatientFamilyMemberDropdown,
  AddFamilyMember as AddFamilyMemberBtn
} from '@axshealth/design-library'

import './style.css'
import { authenticationActions } from '../../../_actions/authentication.actions'

function handleSetDoctor(conversation) {
  const name =
    conversation.type === 'PATIENT_INITIATED'
      ? `${conversation.licensedProfessional.firstName} ${conversation.licensedProfessional.lastName}`
      : `${conversation.firstMessage.sentBy.firstName} ${conversation.firstMessage.sentBy.lastName}`

  const profileUrl =
    conversation.type === 'PATIENT_INITIATED'
      ? conversation.licensedProfessional.profileUrl
      : conversation.firstMessage.sentBy.profile

  const providerName =
    conversation.type === 'PROVIDER_INITIATED'
      ? conversation.firstMessage.provider.name
      : conversation.licensedProfessional.provider.name

  return { name, profileUrl, provider: { name: providerName } }
}

class MessageStream extends Component {
  constructor(props) {
    super(props)

    this.state = {
      from: false,
      doctor: false,
      message: '',
      messages: [],
      showFile: false,
      showClose: false,
      isSending: false,
      charCount: 0,
      attachment: '',
      newMessage: false,
      conversation: null,
      showCharCount: false,
      invalidAttachment: false,
      showAddFamilyMember: false
    }

    this.publishTyping = this.publishTyping.bind(this)
    this.handleMessage = this.handleMessage.bind(this)
    this.handleSetMessage = this.handleSetMessage.bind(this)
    this.handleAttachment = this.handleAttachment.bind(this)
    this.observeLastMessage = this.observeLastMessage.bind(this)
    this.handleScrollToBottom = this.handleScrollToBottom.bind(this)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.conversation !== this.props.conversation) {
      this.setState({
        conversation: this.props.conversation,
        doctor: handleSetDoctor(this.props.conversation)
      })
    }

    if (prevProps.doctor !== this.props.doctor) {
      this.setState({
        doctor: this.props.doctor
      })
    }

    if (prevProps.messages !== this.props.messages) {
      this.setState(
        {
          messages: this.props.messages
        },
        () => {
          this.showHasNewMessage(prevProps)
        }
      )
    }
  }

  publishTyping(typing) {
    this.props.isTyping(this.props.patient, typing)
  }

  showHasNewMessage(prevProps) {
    const messagesLen = prevProps.messages.length
    const messagesPropLen = this.state.messages.length

    if (
      messagesLen !== messagesPropLen &&
      messagesLen !== 0 &&
      messagesPropLen !== 0
    ) {
      const lastMessage = this.state.messages[0]
      if (lastMessage.sentBy.id !== this.props.patient.userId) {
        this.observeLastMessage()
      } else {
        setTimeout(() => {
          this.handleScrollToBottom()
        }, 200)
      }
    }
  }

  observeLastMessage() {
    // eslint-disable-next-line no-undef
    const observer = new IntersectionObserver(
      (entries, observer) => {
        const lastMessageIsVisible = entries[0].isIntersecting
        this.setState({ newMessage: !lastMessageIsVisible })

        if (lastMessageIsVisible) {
          observer.unobserve(entries[0].target)
          this.handleScrollToBottom()
        }
      },
      {
        root: document.getElementById('message-container'),
        rootMargin: '0px 0px 200px 0px'
      }
    )
    observer.observe(document.querySelector('#message-container > div'))
  }

  handleScrollToBottom() {
    const scrollTarget = document.getElementById('message-container')
    if (scrollTarget) {
      scrollTarget.scrollTo({
        top: scrollTarget.scrollHeight,
        behavior: 'smooth'
      })
    }
  }

  handleAttachment() {
    if (!this.state.attachment) {
      this.setState({ invalidAttachment: true })
    } else {
      this.setState({ isSending: true, invalidAttachment: false })
      this.props.dispatch(alertActions.clear())

      this.props
        .sendAttachment(this.state.from, this.state.attachment)
        .then(() => {
          if (
            this.state.conversation.type === 'PATIENT_INITIATED' &&
            this.state.messages.length === 1
          ) {
            this.props.dispatch(
              authenticationActions.displayPreferredPlatform('CONVERSATIONS')
            )
          }
        })
        .catch(() => {
          this.props.dispatch(
            alertActions.error(
              'Could not send file at this time. Please try again.'
            )
          )
        })
        .then(() => {
          this.setState({ attachment: '', isSending: false, showFile: false })
        })
    }
  }

  handleMessage() {
    this.setState({ isSending: true })

    this.props.dispatch(alertActions.clear())

    this.props
      .sendMessage(this.state.from, this.state.message)
      .then(() => {
        if (
          this.state.conversation.type === 'PATIENT_INITIATED' &&
          this.state.messages.length === 1
        ) {
          this.props.dispatch(
            authenticationActions.displayPreferredPlatform('CONVERSATIONS')
          )
        }

        this.setState({ message: '' })
      })
      .catch(() => {
        this.props.dispatch(
          alertActions.error(
            'Could not send message at this time. Please try again.'
          )
        )
      })
      .then(() => {
        this.setState({ isSending: false })
      })
  }

  handleSetMessage(e) {
    const message = e.target.value
    if (message.length <= 500) {
      const count = 500 - message.length

      if (count <= 50) {
        this.setState({ charCount: count, showCharCount: true })
      } else {
        this.setState({ showCharCount: false })
      }
      this.setState({ message })
    }
  }

  handleReadReceipt(message) {
    return (
      this.props.patient.userId === message.sentBy.id && (
        <span>
          <span className='font-weight-bold px-2'>·</span>Sent
          {/* Patient Read <span className='text-success'>✔</span> */}
        </span>
      )
    )
  }

  render() {
    const { patient, dependents } = this.props

    const {
      from,
      doctor,
      message,
      messages,
      showFile,
      charCount,
      isSending,
      newMessage,
      attachment,
      conversation,
      showCharCount,
      invalidAttachment,
      showAddFamilyMember
    } = this.state

    const conversationId = conversation ? conversation.id : null

    const sendBtnStatus = classNames({
      'btn-success': message !== '',
      'btn-secondary': message === '',
      'no-click-events': isSending || message === ''
    })

    const newMessageBtn = classNames(
      'new-message-btn axs-rounded shadow bg-primary text-light',
      { 'new-message-btn-show': newMessage }
    )

    const messageChatBox = classNames({
      'd-none': conversation && conversation.status === 'closed'
    })

    const messageContainer = classNames('d-flex', {
      'init-message flex-column justify-content-around':
        !conversationId || messages.length === 0,
      'flex-column-reverse overflow-y-auto': conversationId,
      closed: conversation && conversation.status === 'closed'
    })

    return (
      <>
        <AddFamilyMember
          showAddFamilyMember={showAddFamilyMember}
          hideAddFamilyMember={() =>
            this.setState({ showAddFamilyMember: false })
          }
        />

        <Row className='d-none d-lg-block mt-3'>
          <Col>
            <Breadcrumb>
              <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/home' }}>
                {this.props.t('Home')}
              </Breadcrumb.Item>
              <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/messages' }}>
                {this.props.t('Messages')}
              </Breadcrumb.Item>
              <Breadcrumb.Item active>{this.props.t('Stream')}</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>

        <Row style={{ zIndex: 9, position: 'relative' }}>
          {!doctor && (
            <Col xs='12' lg='3'>
              <div className='my-3'>
                <Spinner animation='border' />
              </div>
            </Col>
          )}

          {doctor && (
            <>
              <Col className='d-none d-lg-block' xs='12' lg='3'>
                <div className='mb-3'>
                  <MessageSidebar
                    doctor={doctor}
                    location={this.props.location}
                  />
                </div>
              </Col>

              <Col xs='12' lg='9'>
                {doctor && !conversationId && !from && (
                  <div className='mt-3 px-3 px-lg-0'>
                    <Alert variant='primary'>
                      {this.props.t('Select who is sending the message')}.
                    </Alert>
                    <Card className='telehealth__card'>
                      <Card.Body>
                        <PatientConfirmList
                          btnText='Select'
                          confirmed=''
                          confirming={false}
                          dependents={dependents}
                          parentPatient={patient}
                          handleConfirm={(userProfileId) => {
                            this.setState({
                              from: [patient, ...dependents].find(
                                (familyMember) =>
                                  familyMember.userProfileId === userProfileId
                              )
                            })
                          }}
                        />
                      </Card.Body>
                    </Card>

                    <Row>
                      <Col xs='12' md='8'>
                        <AddFamilyMemberBtn
                          handleShowAddFamilyMember={() =>
                            this.setState({ showAddFamilyMember: true })
                          }
                        />
                      </Col>
                    </Row>
                  </div>
                )}

                {(conversationId || (doctor && from)) && (
                  <div id='message-card' className='card border-0 shadow-sm'>
                    {messages && messages.length === 0 && (
                      <div className='col-12'>
                        <div
                          style={{ height: '50px' }}
                          className='d-flex justify-content-between align-items-center'
                        >
                          <PatientFamilyMemberDropdown
                            familyMember={from}
                            familyMembers={[patient, ...dependents]}
                            handleAddFamilyMember={() =>
                              this.setState({ showAddFamilyMember: true })
                            }
                            handleSelectFamilyMember={(selected) =>
                              this.setState({ from: selected })
                            }
                          />
                        </div>
                      </div>
                    )}

                    {conversationId && conversation.settings.showOnline && (
                      <div id='user___online'>
                        {this.props.online.map((user) => {
                          return <Online key={user.userId} user={user} />
                        })}
                      </div>
                    )}

                    <div id='message-container' className={messageContainer}>
                      {messages && messages.length === 0 ? (
                        <div
                          className='d-flex flex-column h-100'
                          style={{ justifyContent: 'space-evenly' }}
                        >
                          <div className='media'>
                            <div className='media-body text-center'>
                              <Image width={400} fluid src={NewMsgImg} />
                            </div>
                          </div>
                          <div className='text-center'>
                            <p className='font-weight-light m-0'>
                              {this.props.t(
                                'This is the very beginning of your messages with'
                              )}
                              &nbsp;
                              {doctor &&
                                `${doctor.firstName} ${doctor.lastName}`}
                            </p>
                          </div>
                        </div>
                      ) : (
                        messages &&
                        messages.length > 0 &&
                        messages.map((message, index) => {
                          return (
                            <Message
                              id={null}
                              key={message.id}
                              index={index}
                              message={message}
                              // readReceipt={handleReadReceipt(message, currentUser)}
                              // showReadReceipts
                              currentUser={patient.userId}
                              showCarePlan={() => null}
                              startTelehealthVideo={() => null}
                              handleClickLink={(url) =>
                                window.open(url, '_blank')
                              }
                              handleClickAttachment={(url) =>
                                window.open(url, '_blank')
                              }
                            />
                          )
                        })
                      )}
                    </div>

                    <div id='new-message-btn-container'>
                      <div
                        onClick={this.handleScrollToBottom}
                        className={newMessageBtn}
                      >
                        <i className='fas fa-comment' />
                        &nbsp;New Message
                      </div>
                    </div>

                    {conversation && conversation.status === 'closed' && (
                      <div id='closed-container'>
                        {conversation.closedBy === undefined && (
                          <div className=' text-center text-muted'>
                            This conversation has been closed.
                          </div>
                        )}

                        {conversation &&
                          conversation.status === 'closed' &&
                          conversation.closedBy && (
                            <div className=' text-center text-muted'>
                              The message was closed by{' '}
                              {conversation.closedBy.firstName}{' '}
                              {conversation.closedBy.lastName}
                              &nbsp;on{' '}
                              {moment
                                .tz(
                                  conversation.closedAt.date,
                                  conversation.closedAt.timezone
                                )
                                .local()
                                .format('LL [at] LT')}
                            </div>
                          )}
                      </div>
                    )}

                    <div
                      className={messageChatBox}
                      style={{ padding: '0 15px' }}
                    >
                      <div id='show-char-count'>
                        {showCharCount && (
                          <div>
                            &nbsp;{charCount}&nbsp;
                            {this.props.t('Characters Remaining')}
                          </div>
                        )}
                      </div>

                      <SendMessageChatBox
                        isTyping={this.publishTyping}
                        message={message}
                        isSending={isSending}
                        sendBtnStatus={sendBtnStatus}
                        handleShowFile={() => this.setState({ showFile: true })}
                        handleSetMessage={this.handleSetMessage}
                        handleSendMessage={this.handleMessage}
                      />

                      <AttachmentModal
                        attachment={attachment}
                        isSending={isSending}
                        sendAttachment={this.handleAttachment}
                        invalidAttachment={invalidAttachment}
                        showAddAttachment={showFile}
                        hideModal={() =>
                          this.setState({ showFile: false, attachment: '' })
                        }
                        setAttachment={(attachment) =>
                          this.setState({ attachment })
                        }
                      />
                    </div>
                  </div>
                )}
              </Col>
            </>
          )}
        </Row>
      </>
    )
  }
}

export default withTranslation()(withDataService(MessageStream))
