import apiVersion from '../_helpers/api-version'
import axiosInstance from '../_helpers/axiosConfig'
import { authHeader } from '../_helpers/auth-header'

export const formsService = {
  getAll,
  getById,
  verifyDob,
  getByToken,
  getAnswers,
  getRequest,
  getQuestions,
  updatePatient,
  confirmPatient,
  submitQuestions,
  getByVerifiedToken,
  submitRequestQuestions
}

function getAll() {
  return axiosInstance
    .request({
      method: 'GET',
      headers: authHeader(),
      url: `${process.env.REACT_APP_API_URL}/${apiVersion()}/patient/form`
    })
    .then((response) => {
      return response.data.data
    })
}

function getById(formId) {
  return axiosInstance
    .request({
      method: 'GET',
      headers: authHeader(),
      url: `${
        process.env.REACT_APP_API_URL
      }/${apiVersion()}/patient/form/${formId}`
    })
    .then((response) => {
      return response.data
    })
}

function getByToken(token) {
  return axiosInstance.request({
    method: 'GET',
    headers: authHeader(),
    url: `${
      process.env.REACT_APP_API_URL
    }/${apiVersion()}/patient/form/${token}/deep-link`
  })
}

function getByVerifiedToken(dlToken, verifiedToken) {
  return axiosInstance.request({
    method: 'GET',
    headers: authHeader(),
    url: `${
      process.env.REACT_APP_API_URL
    }/${apiVersion()}/patient/form/${dlToken}/confirm/${verifiedToken}`
  })
}

function verifyDob(dateOfBirth, dlToken) {
  return axiosInstance.request({
    method: 'POST',
    url: `${
      process.env.REACT_APP_API_URL
    }/${apiVersion()}/patient/form/${dlToken}/deep-link`,
    headers: authHeader(),
    data: { dateOfBirth }
  })
}

function getQuestions(formId) {
  return axiosInstance
    .request({
      method: 'GET',
      headers: authHeader(),
      url: `${
        process.env.REACT_APP_API_URL
      }/${apiVersion()}/patient/form/${formId}/questions`
    })
    .then((response) => {
      return response.data
    })
}

function getRequest(formId) {
  return axiosInstance
    .request({
      method: 'GET',
      headers: authHeader(),
      url: `${
        process.env.REACT_APP_API_URL
      }/${apiVersion()}/patient/form/request?formId=${formId}`
    })
    .then((response) => {
      return response.data
    })
}

function submitQuestions(formId, questions) {
  return axiosInstance
    .request({
      method: 'POST',
      headers: authHeader(),
      url: `${
        process.env.REACT_APP_API_URL
      }/${apiVersion()}/patient/form/${formId}`,
      data: { resource: questions }
    })
    .then((response) => {
      return response.data
    })
}

function submitRequestQuestions(formId, resource, patientId, licensedId) {
  return axiosInstance
    .request({
      method: 'POST',
      headers: authHeader(),
      url: `${
        process.env.REACT_APP_API_URL
      }/${apiVersion()}/patient/form/request`,
      data: {
        formId: formId,
        resource: resource,
        patientId: patientId,
        licensedId: licensedId
      }
    })
    .then((response) => {
      return response.data
    })
}

function getAnswers(formId) {
  return axiosInstance
    .request({
      method: 'GET',
      headers: authHeader(),
      url: `${
        process.env.REACT_APP_API_URL
      }/${apiVersion()}/patient/form/${formId}`
    })
    .then((response) => {
      return response.data
    })
}

function confirmPatient(profileId, dlToken, verifiedToken) {
  return axiosInstance.request({
    method: 'POST',
    headers: authHeader(),
    url: `${
      process.env.REACT_APP_API_URL
    }/${apiVersion()}/patient/form/${dlToken}/family-member`,
    data: { profileId: profileId, verifiedToken: verifiedToken }
  })
}

function updatePatient(formId, profileId) {
  return axiosInstance.request({
    method: 'PATCH',
    headers: authHeader(),
    url: `${
      process.env.REACT_APP_API_URL
    }/${apiVersion()}/patient/form/${formId}/family-member`,
    data: { familyMemberId: profileId }
  })
}
