import { userConstants as constants } from '../_constants/user.constants'

const initialState = {
  currentUser: {},
  dependents: null,
  doctors: null
}

export function user(state = initialState, action) {
  if (action.type === constants.USER_SET_CURRENT_USER) {
    return {
      ...state,
      currentUser: action.user.account,
      dependents: action.user.dependents.data,
      doctors: action.user.doctors.data
    }
  }
  return state
}
