export const formConstants = {
  ADD_ITEM: 'ADD_ITEM',
  SET_ITEM: 'SET_ITEM',

  SET_ANSWERS: 'SET_ANSWERS',
  UPDATE_ITEM: 'UPDATE_ITEM',

  INIT_ANSWERS: 'INIT_ANSWERS',
  INITIAL_STATE: 'INITIAL_STATE',

  FORM_COMPLETE_REQUEST: 'FORM_COMPLETE_REQUEST',
  FORM_COMPLETE_SUCCESS: 'FORM_COMPLETE_SUCCESS',
  FORM_COMPLETE_FAILURE: 'FORM_COMPLETE_FAILURE',

  SET_ELECTRONIC_SIGNATURE: 'SET_ELECTRONIC_SIGNATURE',
  REMOVE_ELECTRONIC_SIGNATURE: 'REMOVE_ELECTRONIC_SIGNATURE'
}
