import React, { useEffect, useState } from 'react'
import moment from 'moment'
import MainMenu from '../../../_components/MainMenu'

import { history } from '../../../_helpers/history'
import { useDispatch } from 'react-redux'
import { faMobileAlt } from '@fortawesome/free-solid-svg-icons'
import { alertActions } from '../../../_actions/alert.actions'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { telehealthService } from '../../../_services/telehealth.service'
import { telehealthConstants } from '../../../_constants/telehealth.constants'
import { Spinner, Alert, Col, Row } from 'react-bootstrap'

import {
  TelehealthCardBase,
  TelehealthCardEmpty,
  TelehealthModalExpired,
  TelehealthModalCanceled
} from '@axshealth/design-library'

export default function TelehealthList() {
  const dispatch = useDispatch(null)
  const { t } = useTranslation()
  const [doctor, setDoctor] = useState(null)
  const [loading, setLoading] = useState(true)
  const [telehealths, setTelehealths] = useState(null)
  const [showExpired, setShowExpired] = useState(false)
  const [showCanceled, setShowCanceled] = useState(false)

  useEffect(() => {
    setLoading(true)
    telehealthService
      .getAll()
      .then((telehealths) => {
        setTelehealths(telehealths)
        setLoading(false)
      })
      .catch(() => {
        dispatch(
          alertActions.error(
            'An error occurred and we could not retrieve your telehealth appointments at this time. Please try again.'
          )
        )
        setLoading(false)
      })
  }, [])

  useEffect(() => {
    dispatch({
      type: telehealthConstants.TELEHEALTH_MESSAGES_GET_SUCCESS,
      messages: null
    })
  }, [])

  const messageProvider = () => {
    history.push(`/messages/stream`, { doctorId: doctor })
  }

  const onClick = (telehealth) => {
    if (telehealth.status === 'SCHEDULED') {
      window.alert(
        t(
          `Your healthcare provider would like the information to be completed at a specific time.`
        )
      )
    } else if (telehealth.status === 'SENT') {
      history.push(`/telehealth/consent`, {
        id: telehealth.id,
        type: 'telehealth',
        referrer: telehealth.referrer,
        telehealth: telehealth
      })
    } else if (
      telehealth.status === 'HOLD' ||
      telehealth.status === 'READY' ||
      telehealth.status === 'COMPLETED'
    ) {
      history.push(`/telehealth/${telehealth.id}/encounter`, {
        referrer: telehealth.referrer,
        telehealth: telehealth
      })
    } else if (telehealth.status === 'EXPIRED') {
      setDoctor(telehealth.doctor.id)
      setShowExpired(true)
    } else if (telehealth.status === 'CANCELED') {
      setDoctor(telehealth.doctor.id)
      setShowCanceled(true)
    }
  }

  return (
    <>
      <Row>
        <Col lg={2} className='pt-1 px-0 d-none d-lg-block'>
          <MainMenu />
        </Col>
        <Col md={12} lg={10}>
          <Row>
            <Col className='mb-3 sub__text'>
              <FontAwesomeIcon icon={faMobileAlt} className='mr-2' />
              <span style={{ fontSize: '6px', verticalAlign: 'middle' }}>
                •
              </span>
              <span className='ml-2'>{t('Telehealth')}</span>
            </Col>
          </Row>

          {history.location.state &&
            history.location.state.preQuestionsSubmitted && (
              <Row>
                <Col>
                  <Alert variant='success'>
                    {t('You are now ready for your telehealth appointment on')}
                    &nbsp;
                    <b className='main__text'>
                      {moment
                        .tz(
                          history.location.state.date,
                          history.location.state.timezone
                        )
                        .local()
                        .format('MMMM D, YYYY [@] h:mm A')}
                    </b>
                  </Alert>
                </Col>
              </Row>
            )}

          <Row className='main__text'>
            <Col>
              <div className='main__title font-weight-bold my-auto'>
                {t('Your Telehealth Appointments')}
              </div>
              {telehealths && telehealths.length > 0 && (
                <div className='my-2'>
                  {t(
                    "Below you'll find upcoming and prior telehealth appointments."
                  )}
                </div>
              )}
            </Col>
          </Row>

          {loading ? (
            <Spinner animation='border' className='mt-2' />
          ) : telehealths !== null && telehealths.length > 0 ? (
            <Row>
              <TelehealthModalExpired
                show={showExpired}
                message={messageProvider}
                hide={() => setShowExpired(false)}
              />
              <TelehealthModalCanceled
                show={showCanceled}
                message={messageProvider}
                hide={() => setShowCanceled(false)}
              />

              {telehealths.map((telehealth) => {
                return (
                  <Col xs={12} key={telehealth.id}>
                    <TelehealthCardBase
                      telehealth={telehealth}
                      handleClick={() => onClick(telehealth)}
                    />
                  </Col>
                )
              })}
            </Row>
          ) : (
            <div className='mt-3'>
              <TelehealthCardEmpty />
            </div>
          )}
        </Col>
      </Row>
    </>
  )
}
