export const telehealthConstants = {
  TELEHEALTH_GET_REQUEST: 'TELEHEALTH_GET_REQUEST',
  TELEHEALTH_GET_FAILURE: 'TELEHEALTH_GET_FAILURE',
  TELEHEALTH_GET_SUCCESS: 'TELEHEALTH_GET_SUCCESS',

  TELEHEALTH_CLEAR_STATE: 'TELEHEALTH_CLEAR_STATE',
  TELEHEALTH_MESSAGE_ADD: 'TELEHEALTH_MESSAGE_ADD',
  TELEHEALTH_PREPEND_MSG: 'TELEHEALTH_PREPEND_MSG',

  TELEHEALTH_MESSAGES_GET_REQUEST: 'TELEHEALTH_MESSAGES_GET_REQUEST',
  TELEHEALTH_MESSAGES_GET_FAILURE: 'TELEHEALTH_MESSAGES_GET_FAILURE',
  TELEHEALTH_MESSAGES_GET_SUCCESS: 'TELEHEALTH_MESSAGES_GET_SUCCESS',

  QUESTIONS_COMPLETE_REQUEST: 'QUESTIONS_COMPLETE_REQUEST',
  QUESTIONS_COMPLETE_SUCCESS: 'QUESTIONS_COMPLETE_SUCCESS',
  QUESTIONS_COMPLETE_FAILURE: 'QUESTIONS_COMPLETE_FAILURE'
}
