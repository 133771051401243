import React from 'react'
import Select from 'react-select'
import RenderValidateMessage from '../Validation/RenderValidateMessage'

import { Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

export default function GenderInput(props) {
  const {
    errors,
    value,
    setValue,
    handleEnter,
    placeholder = '',
    showTitle = true,
    disabled = false
  } = props
  const { t } = useTranslation()

  const genderOptions = [
    { value: 'm', label: t('Male') },
    { value: 'f', label: t('Female') }
  ]

  const selected = genderOptions.find((option) => {
    return option.value === value
  })

  return (
    <Form.Group>
      {showTitle && (
        <Form.Label className={'gender' in errors ? 'text-danger' : ''}>
          {t('Gender')}
        </Form.Label>
      )}

      <Select
        value={selected || ''}
        options={genderOptions}
        isDisabled={disabled}
        onChange={(e) => setValue({ gender: e.value })}
        onKeyDown={handleEnter}
        placeholder={placeholder}
      />
      <RenderValidateMessage errors={errors} field='gender' />
    </Form.Group>
  )
}
