import home from './json/home.json'
import forms from './json/forms.json'
import login from './json/login.json'
import event from './json/event.json'
import common from './json/common.json'
import months from './json/months.json'
import sidebar from './json/sidebar.json'
import welcome from './json/welcome.json'
import register from './json/register.json'
import settings from './json/settings.json'
import deeplink from './json/deeplink.json'
import messages from './json/messages.json'
import mainMenu from './json/main-menu.json'
import carePlan from './json/care-plan.json'
import notFound from './json/not-found.json'
import loginInfo from './json/login-info.json'
import attachment from './json/attachment.json'
import navigation from './json/navigation.json'
import validation from './json/validation.json'
import esignature from './json/esignature.json'
import telehealth from './json/telehealth.json'
import eventTypes from './json/event-types.json'
import contactInfo from './json/contact-info.json'
import userProfile from './json/user-profile.json'
import appointments from './json/appointments.json'
import notifications from './json/notifications.json'
import questionnaire from './json/questionnaire.json'
import familyMembers from './json/family-members.json'
import forgotUsername from './json/forgot-username.json'
import forgotPassword from './json/forgot-password.json'
import selectLanguage from './json/select-language.json'
import passwordChecklist from './json/password-checklist.json'
import preferredPlatform from './json/preferred-platform.json'

const en = {
  translation: {
    ...home.en,
    ...forms.en,
    ...login.en,
    ...event.en,
    ...common.en,
    ...months.en,
    ...sidebar.en,
    ...welcome.en,
    ...register.en,
    ...settings.en,
    ...deeplink.en,
    ...messages.en,
    ...mainMenu.en,
    ...carePlan.en,
    ...notFound.en,
    ...loginInfo.en,
    ...navigation.en,
    ...validation.en,
    ...esignature.en,
    ...telehealth.en,
    ...attachment.en,
    ...eventTypes.en,
    ...contactInfo.en,
    ...userProfile.en,
    ...appointments.en,
    ...notifications.en,
    ...questionnaire.en,
    ...familyMembers.en,
    ...forgotUsername.en,
    ...forgotPassword.en,
    ...selectLanguage.en,
    ...passwordChecklist.en,
    ...preferredPlatform.en
  }
}

const es = {
  translation: {
    ...home.es,
    ...forms.es,
    ...login.es,
    ...event.es,
    ...common.es,
    ...months.es,
    ...sidebar.es,
    ...welcome.es,
    ...register.es,
    ...settings.es,
    ...deeplink.es,
    ...messages.es,
    ...mainMenu.es,
    ...carePlan.es,
    ...notFound.es,
    ...loginInfo.es,
    ...navigation.es,
    ...validation.es,
    ...esignature.es,
    ...telehealth.es,
    ...attachment.es,
    ...eventTypes.es,
    ...contactInfo.es,
    ...userProfile.es,
    ...appointments.es,
    ...notifications.es,
    ...questionnaire.es,
    ...familyMembers.es,
    ...forgotUsername.es,
    ...forgotPassword.es,
    ...selectLanguage.es,
    ...passwordChecklist.es,
    ...preferredPlatform.es
  }
}

export { en, es }
