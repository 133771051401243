import apiVersion from '../_helpers/api-version'
import axiosInstance from '../_helpers/axiosConfig'
import { authHeader } from '../_helpers/auth-header'

export const telehealthService = {
  getAll,
  getById,
  consent,
  verifyDob,
  getByToken,
  getMessages,
  sendMessage,
  getQuestions,
  updatePatient,
  getVideoToken,
  confirmPatient,
  sendAttachment,
  submitQuestions,
  getByVerifiedToken
}

function consent(telehealthId) {
  return axiosInstance.request({
    method: 'POST',
    headers: authHeader(),
    url: `${
      process.env.REACT_APP_API_URL
    }/${apiVersion()}/patient/telehealth/${telehealthId}/consent`
  })
}

function getByVerifiedToken(dlToken, verifiedToken) {
  return axiosInstance.request({
    method: 'GET',
    headers: authHeader(),
    url: `${
      process.env.REACT_APP_API_URL
    }/${apiVersion()}/patient/telehealth/${dlToken}/confirm/${verifiedToken}`
  })
}

function verifyDob(dateOfBirth, dlToken) {
  return axiosInstance.request({
    method: 'POST',
    url: `${
      process.env.REACT_APP_API_URL
    }/${apiVersion()}/patient/telehealth/${dlToken}/verify`,
    headers: authHeader(),
    data: { dateOfBirth }
  })
}

function getAll() {
  return axiosInstance
    .request({
      method: 'GET',
      headers: authHeader(),
      url: `${process.env.REACT_APP_API_URL}/${apiVersion()}/patient/telehealth`
    })
    .then((response) => {
      return response.data.data
    })
}

function getById(telehealthId) {
  return axiosInstance
    .request({
      method: 'GET',
      headers: authHeader(),
      url: `${
        process.env.REACT_APP_API_URL
      }/${apiVersion()}/patient/telehealth/${telehealthId}`
    })
    .then((response) => {
      return response.data
    })
}

function getByToken(token) {
  return axiosInstance.request({
    method: 'GET',
    headers: authHeader(),
    url: `${
      process.env.REACT_APP_API_URL
    }/${apiVersion()}/patient/telehealth/${token}/deep-link`
  })
}

function getMessages(telehealthId) {
  return axiosInstance
    .request({
      method: 'GET',
      headers: authHeader(),
      url: `${
        process.env.REACT_APP_API_URL
      }/${apiVersion()}/patient/telehealth/${telehealthId}/message`
    })
    .then((response) => {
      return response.data.data
    })
}

function sendMessage(telehealthId, message, socketId) {
  return axiosInstance
    .request({
      method: 'POST',
      headers: authHeader(),
      url: `${
        process.env.REACT_APP_API_URL
      }/${apiVersion()}/patient/telehealth/${telehealthId}/message`,
      data: { type: 'message', text: message, socketId }
    })
    .then((response) => {
      return response.data
    })
}

function sendAttachment(telehealthId, attachment, socketId) {
  return axiosInstance
    .request({
      method: 'POST',
      headers: authHeader(),
      url: `${
        process.env.REACT_APP_API_URL
      }/${apiVersion()}/patient/telehealth/${telehealthId}/message`,
      data: { type: 'attachment', attachment: attachment.base64, socketId }
    })
    .then((response) => {
      return response.data
    })
}

function getQuestions(telehealthId) {
  return axiosInstance
    .request({
      method: 'GET',
      headers: authHeader(),
      url: `${
        process.env.REACT_APP_API_URL
      }/${apiVersion()}/patient/telehealth/${telehealthId}/questions`
    })
    .then((response) => {
      return response.data
    })
}

function submitQuestions(telehealthId, questions) {
  return axiosInstance
    .request({
      method: 'POST',
      headers: authHeader(),
      url: `${
        process.env.REACT_APP_API_URL
      }/${apiVersion()}/patient/telehealth/${telehealthId}/questions`,
      data: { resource: questions }
    })
    .then((response) => {
      return response.data
    })
}

function getVideoToken(telehealthId) {
  return axiosInstance
    .request({
      method: 'POST',
      headers: authHeader(),
      url: `${
        process.env.REACT_APP_API_URL
      }/${apiVersion()}/patient/telehealth/${telehealthId}/video`
    })
    .then((response) => {
      return response.data
    })
}

function confirmPatient(profileId, dlToken, verifiedToken) {
  return axiosInstance.request({
    method: 'POST',
    headers: authHeader(),
    url: `${
      process.env.REACT_APP_API_URL
    }/${apiVersion()}/patient/telehealth/${dlToken}/family-member`,
    data: { profileId: profileId, verifiedToken: verifiedToken }
  })
}

function updatePatient(telehealthId, profileId) {
  return axiosInstance.request({
    method: 'PATCH',
    headers: authHeader(),
    url: `${
      process.env.REACT_APP_API_URL
    }/${apiVersion()}/patient/telehealth/${telehealthId}/family-member`,
    data: { familyMemberId: profileId }
  })
}
