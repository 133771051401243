import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { history } from '../../_helpers/history'
import { Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faFileAlt, faComments } from '@fortawesome/free-regular-svg-icons'
import {
  faMobileAlt,
  faBriefcaseMedical,
  faHome
} from '@fortawesome/free-solid-svg-icons'

import './style.css'

export default function MainMenu() {
  const { t } = useTranslation()
  const [location, setLocation] = useState('')

  useEffect(() => {
    setLocation(history.location.pathname)
  }, [])

  return (
    <Row className='sub__text'>
      <Col>
        <Row>
          <Col className='pl-2'>{t('MENU')}</Col>
        </Row>
        <Link to='/home' className='sub__text'>
          <Row
            className={`icon pt-3 ${
              location.indexOf('/home') === 0 ? 'active' : ''
            }`}
          >
            <Col lg='2' className='px-0 text-center'>
              <FontAwesomeIcon icon={faHome} />
            </Col>
            <Col className='pl-1'>{t('Home')}</Col>
          </Row>
        </Link>
        <Link to='/forms' className='sub__text'>
          <Row
            className={`icon pt-3 ${
              location.indexOf('/forms') === 0 ? 'active' : ''
            }`}
          >
            <Col lg='2' className='px-0 text-center'>
              <FontAwesomeIcon icon={faFileAlt} />
            </Col>
            <Col className='pl-1'>{t('Forms')}</Col>
          </Row>
        </Link>
        <Link to='/telehealth' className='sub__text'>
          <Row
            className={`icon pt-3 ${
              location.indexOf('/telehealth') === 0 ? 'active' : ''
            }`}
          >
            <Col lg='2' className='px-0 text-center'>
              <FontAwesomeIcon icon={faMobileAlt} />
            </Col>
            <Col className='pl-1'>{t('Telehealth')}</Col>
          </Row>
        </Link>
        <Link to='/messages' className='sub__text'>
          <Row
            className={`icon pt-3 ${
              location.indexOf('/messages') === 0 ? 'active' : ''
            }`}
          >
            <Col lg='2' className='px-0 text-center'>
              <FontAwesomeIcon icon={faComments} />
            </Col>
            <Col className='pl-1'>{t('Messages')}</Col>
          </Row>
        </Link>
        <Link to='/care-plans' className='sub__text'>
          <Row
            className={`icon pt-3 ${
              location.indexOf('/care-plans') === 0 ? 'active' : ''
            }`}
          >
            <Col lg='2' className='px-0 text-center'>
              <FontAwesomeIcon icon={faBriefcaseMedical} />
            </Col>
            <Col className='pl-1'>{t('Care Plans')}</Col>
          </Row>
        </Link>
      </Col>
    </Row>
  )
}
