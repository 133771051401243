import apiVersion from '../_helpers/api-version'
import axiosInstance from '../_helpers/axiosConfig'
import { store } from '../_helpers/store'
import { removeCookie } from '../_helpers/cookieJar'

export const authenticationService = {
  login,
  logout,
  getUser,
  getToken
}

function login(username, password) {
  return axiosInstance
    .request({
      method: 'POST',
      url: `${
        process.env.REACT_APP_API_URL
      }/${apiVersion()}/patient/authentication`,
      data: {
        username,
        password,
        grant_type: 'password',
        client_id: process.env.REACT_APP_CLIENT_ID,
        client_secret: process.env.REACT_APP_CLIENT_SECRET
      }
    })
    .then((response) => {
      const authToken = response.data.access_token

      return getUser(authToken)
        .then((response) => {
          const patientData = response.data
          const patient = { ...patientData, auth: { authToken } }
          return { patient }
        })
        .catch((a) => {
          logout()
          window.location.reload()
        })
    })
}

function getUser(authToken = null) {
  const token = authToken || getToken()
  return axiosInstance.request({
    method: 'GET',
    url: `${process.env.REACT_APP_API_URL}/${apiVersion()}/patient/account`,
    headers: { Authorization: `Bearer ${token}` }
  })
}

function logout() {
  removeCookie('direction')
  // window.localStorage.clear()
}

function getToken() {
  const authToken = store.getState().authentication.jwt
  return authToken || null
}
