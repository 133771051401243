import React, { Fragment } from 'react'
import useWindowDimensions from '../../../../_helpers/dimensions'
import RenderProfilePicture from '../../../../_helpers/profile-picture'

import { faBuilding, faAddressBook } from '@fortawesome/free-regular-svg-icons'
import { Card, Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function MessageSidebar({ doctor, location }) {
  const { width, breakPointMD } = useWindowDimensions()
  const mobile = width < breakPointMD

  if (!doctor) {
    return null
  }

  return (
    <Fragment>
      {mobile ? (
        <Card className='telehealth__card p-3 text-center'>
          <Row className='my-auto'>
            <Col xs='auto'>
              <b>
                {doctor.name
                  ? doctor.name
                  : `${doctor.firstName} ${doctor.lastName}`}
              </b>
            </Col>
            <Col xs='auto' className='sub__text'>
              <FontAwesomeIcon
                icon={faBuilding}
                style={{ fontSize: '10px', color: '#AFAFAF' }}
              />
              &nbsp;
              {doctor.provider.name}
            </Col>
          </Row>
        </Card>
      ) : (
        <Card className='telehealth__card p-3 text-center'>
          <Row className='mb-2'>
            <Col>
              <RenderProfilePicture
                image={doctor.profileUrl}
                cached
                width={60}
                height={60}
                className='m-auto'
              />

              <h5>
                {doctor.name
                  ? doctor.name
                  : `${doctor.firstName} ${doctor.lastName}`}
              </h5>

              {location && (
                <div className='mt-3'>
                  <FontAwesomeIcon icon={faAddressBook} />
                  &nbsp;
                  {location.name}
                </div>
              )}

              <div className='mt-1'>
                <FontAwesomeIcon icon={faBuilding} />
                &nbsp;
                {doctor.provider.name}
              </div>
            </Col>
          </Row>
        </Card>
      )}
    </Fragment>
  )
}
