import React from 'react'
import LoginForm from './LoginForm'
import queryString from 'query-string'

import { connect } from 'react-redux'
import { userActions } from '../../_actions/user.actions'
import { registerConstants } from '../../_constants/register.constants'
import { authenticationService } from '../../_services/authentication.service'
import { authenticationConstants } from '../../_constants/authentication.constants'

class LoginPage extends React.Component {
  constructor(props) {
    super(props)

    if (authenticationService.currentUserValue) {
      this.props.history.push('/')
    }
    this.state = {
      errors: [],
      invalid: false,
      username: this.props.rememberUsername ? this.props.username : '',
      password: '',
      submitting: false
    }
    this.handleLogin = this.handleLogin.bind(this)
    this.handleSetState = this.handleSetState.bind(this)
    this.handleSetShowPassword = this.handleSetShowPassword.bind(this)
    this.handleSetRememberUsername = this.handleSetRememberUsername.bind(this)
  }

  componentDidMount() {
    this.props.dispatch({ type: registerConstants.SET_CURRENT_STEP, step: 1 })
  }

  handleLogin(e) {
    e.preventDefault()

    const errors = {}
    const { dispatch } = this.props
    const { username, password } = this.state

    if (username.length === 0) {
      errors.username = 'Username is required.'
    }

    if (password.length === 0) {
      errors.password = 'Password is required.'
    }

    this.setState({ errors })

    if (!errors.username && !errors.password) {
      const referrer = this.props.location.state
        ? this.props.location.state.from
        : '/'

      dispatch(userActions.login(username, password, referrer))
    }
  }

  handleSetShowPassword() {
    this.props.dispatch({
      type: authenticationConstants.AUTHENTICATION_SHOW_PASSWORD,
      showPassword: !this.props.showPassword
    })
  }

  handleSetRememberUsername() {
    this.props.dispatch({
      type: authenticationConstants.AUTHENTICATION_REMEMBER_USERNAME,
      rememberUsername: !this.props.rememberUsername
    })
  }

  handleSetState(value) {
    this.setState(value)
  }

  render() {
    const queryParams = queryString.parse(this.props.location.search)

    const { errors, username, password } = this.state

    const {
      loggingIn,
      showPassword,
      alertNextRoute,
      rememberUsername,
      alertDelayMessage
    } = this.props

    return (
      <LoginForm
        errors={errors}
        expired={queryParams.expired}
        username={username}
        password={password}
        loggingIn={loggingIn}
        handleLogin={this.handleLogin}
        showPassword={showPassword}
        handleSetState={this.handleSetState}
        alertNextRoute={alertNextRoute}
        rememberUsername={rememberUsername}
        alertDelayMessage={alertDelayMessage}
        setShowPassword={this.handleSetShowPassword}
        setRememberUsername={this.handleSetRememberUsername}
      />
    )
  }
}

function mapStateToProps(state) {
  return {
    alertDelayMessage: state.alert.delayMessage,
    rememberUsername: state.authentication.rememberUsername,
    alertNextRoute: state.alert.nextRouteMessage,
    showPassword: state.authentication.showPassword,
    loggingIn: state.authentication.loggingIn,
    username: state.authentication.username
  }
}

export default connect(mapStateToProps)(LoginPage)
