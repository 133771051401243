import React, { Component } from 'react'
import { connect } from 'react-redux'
import { history } from './_helpers/history'
import { getCookie } from './_helpers/cookieJar'
import { alertActions } from './_actions/alert.actions'
import { deepLinkActions } from './_actions/deeplink.actions'
import { languageActions } from './_actions/language.actions'
import { Switch, Route, Router, Redirect } from 'react-router-dom'

// Home
import Home from './_pages/Home'
import Event from './_pages/Event'

// Login
import Login from './_pages/Login'
import Register from './_pages/Register'
import NotFound from './_pages/NotFound'
import ForgotPassword from './_pages/Forgot/ForgotPassword'
import ForgotUsername from './_pages/Forgot/ForgotUsername'

// Forms
import Forms from './_pages/Forms/List'
import FormAnswers from './_pages/Forms/View'
import FormDetails from './_pages/Forms/Details'
import FormRequest from './_pages/Forms/Request'
import FormQuestions from './_pages/Forms/Questions'

// Messages
import Messages from './_pages/Messages'

// Care Plans
import CarePlans from './_pages/CarePlans'

// User Settings
import UserSettings from './_pages/UserSettings'
import FamilyMembers from './_pages/FamilyMembers'

// Telehealth
import Consent from './_pages/Telehealth/Encounter/Consent'
import Encounter from './_pages/Telehealth/Encounter/Encounter'
import Telehealth from './_pages/Telehealth/List'
import TelehealthVideo from './_pages/Telehealth/Video'
import TelehealthDetails from './_pages/Telehealth/Details'
import PreVisitQuestions from './_pages/Telehealth/Encounter/PreVisitQuestions'

// Deep Link
import DeepLink from './_pages/DeepLink/'
import FormDeepLink from './_pages/DeepLink/Forms'
import EventDeepLink from './_pages/DeepLink/Event'
import TelehealthDeepLink from './_pages/DeepLink/Telehealth'

// Layouts
import AppLayout from './_layouts/App/App'
import LoginLayout from './_layouts/Login'
import ChatStreamLayout from './_layouts/ChatStream'

// Routes
import PublicRoute from './_helpers/public.route.js'
import AuthorizedRoute from './_helpers/authorized.route.js'

// Utilities
import ScrollToTop from './_components/ScrollToTop/ScrollToTop'
import SelectLanguageModal from './_components/Language/SelectLanguageModal'

import 'moment/locale/es'
import * as momentTZ from 'moment-timezone'
import Verified from './_pages/Register/Verified'

class App extends Component {
  componentDidMount() {
    const language = getCookie('language') || 'en'

    momentTZ.locale(language)

    this.unlisten = history.listen((location) => {
      this.props.dispatch(alertActions.clear())
    })

    window.branch.init(process.env.REACT_APP_BRANCHIO_KEY, (err, data) => {
      if (!err && data.data_parsed['+clicked_branch_link']) {
        this.props.dispatch(deepLinkActions.setData(data.data_parsed))
      }
    })
  }

  componentWillUnmount() {
    this.unlisten()
  }

  toggleLanguageModal = () => {
    this.props.dispatch(languageActions.toggleLanguageModal())
  }

  render() {
    const { showLanguageModal } = this.props
    return (
      <Router history={history}>
        <ScrollToTop>
          <SelectLanguageModal
            show={showLanguageModal}
            hide={this.toggleLanguageModal}
          />

          <Switch>
            <PublicRoute path='/login' layout={LoginLayout} component={Login} />
            <PublicRoute
              path='/register'
              layout={LoginLayout}
              component={Register}
            />
            <PublicRoute
              path='/forgot-username'
              layout={LoginLayout}
              component={ForgotUsername}
            />
            <PublicRoute
              path='/forgot-password'
              layout={LoginLayout}
              component={ForgotPassword}
            />
            <PublicRoute
              path='/telehealth/:token/deep-link'
              component={TelehealthDeepLink}
            />

            <PublicRoute path='/deep-link/registration' component={Verified} />

            <PublicRoute
              path='/forms/:token/deep-link'
              component={FormDeepLink}
            />
            <PublicRoute
              path='/event/:token/deep-link'
              component={EventDeepLink}
            />

            <PublicRoute path='/deep-link' component={DeepLink} />

            <PublicRoute exact path='/'>
              <Redirect to='/home' />
            </PublicRoute>

            <AuthorizedRoute path='/home' layout={AppLayout} component={Home} />

            <AuthorizedRoute
              path='/settings'
              layout={AppLayout}
              component={UserSettings}
            />
            <AuthorizedRoute
              path='/family-members'
              layout={AppLayout}
              component={FamilyMembers}
            />
            <AuthorizedRoute
              path='/event/:id'
              layout={AppLayout}
              component={Event}
            />
            <AuthorizedRoute
              path='/forms/questions'
              layout={AppLayout}
              component={FormQuestions}
            />
            <AuthorizedRoute
              path='/forms/request/:id/:domain'
              layout={AppLayout}
              component={FormRequest}
            />
            <AuthorizedRoute
              path='/forms/:id/details'
              layout={AppLayout}
              component={FormDetails}
            />
            <AuthorizedRoute
              path='/forms/view'
              layout={AppLayout}
              component={FormAnswers}
            />
            <AuthorizedRoute
              path='/forms'
              layout={AppLayout}
              component={Forms}
            />

            <AuthorizedRoute path='/messages' component={Messages} />

            <AuthorizedRoute
              path='/care-plans'
              layout={AppLayout}
              component={CarePlans}
            />
            <AuthorizedRoute
              path='/telehealth/:id/encounter'
              layout={ChatStreamLayout}
              component={Encounter}
            />
            <AuthorizedRoute
              path='/telehealth/questions'
              layout={AppLayout}
              component={PreVisitQuestions}
            />
            <AuthorizedRoute
              path='/telehealth/:id/details'
              layout={AppLayout}
              component={TelehealthDetails}
            />
            <AuthorizedRoute
              path='/telehealth/consent'
              layout={AppLayout}
              component={Consent}
            />
            <AuthorizedRoute
              path='/telehealth/video'
              component={TelehealthVideo}
            />
            <AuthorizedRoute
              path='/telehealth'
              layout={AppLayout}
              component={Telehealth}
            />
            <Route component={NotFound} />
          </Switch>
        </ScrollToTop>
      </Router>
    )
  }
}

function mapStateToProps(state) {
  return {
    showLanguageModal: state.language.show
  }
}

export default connect(mapStateToProps)(App)
