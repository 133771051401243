import React, { Component } from 'react'
import FadeIn from 'react-fade-in'

import { withTranslation } from 'react-i18next'
import { Jumbotron, Container } from 'react-bootstrap'
import httpClient from '../_helpers/axiosConfig'

import '../_pages/NotFound/NotFoundStyle.css'
import getApiVersion from './api-version'

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    console.log('***ErrorBoundary***', error, errorInfo)

    // httpClient
    //   .post(
    //     `${
    //       process.env.REACT_APP_API_URL
    //     }/${getApiVersion()}/patient/bug-catcher`,
    //     {
    //       platform: process.env.REACT_APP_PLATFORM,
    //       version: process.env.REACT_APP_API_VERSION,
    //       message: error.message,
    //       stackTrace: errorInfo.componentStack
    //     }
    //   )
    //   .catch(() => {
    //     console.log(error)
    //   })
  }

  render() {
    if (this.state.hasError) {
      return (
        <FadeIn>
          <div className='not-found-container container-fluid text-left text-sm-center'>
            <div className='row h-100'>
              <div className='col my-auto'>
                <div className='row'>
                  <div className='col col-sm-8 offset-sm-2'>
                    <Jumbotron fluid className='shadow lead'>
                      <Container>
                        <p style={{ fontSize: 'xx-large' }}>
                          {this.props.t('Oh no! Something went wrong')}
                        </p>
                        <p>
                          {this.props.t(
                            'Please refresh the page and try again while we fix the error.'
                          )}
                        </p>

                        <p>{this.props.t('Thank you for your patience.')}</p>

                        <a
                          className='btn btn-link'
                          onClick={() => window.location.reload()}
                        >
                          {this.props.t('Click here to refresh')}
                        </a>
                      </Container>
                    </Jumbotron>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </FadeIn>
      )
    }

    return this.props.children
  }
}

export default withTranslation()(ErrorBoundary)
