import React from 'react'
import EditProfile from './EditProfile'
import AuthorizedRoute from '../../_helpers/authorized.route'

import { Link, Switch } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserCog } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'

export default function UserSettings({ history }) {
  const { t } = useTranslation()

  function isActive(path) {
    return history.location.pathname === path ? 'active' : ''
  }

  return (
    <div className='row'>
      <nav
        id='sidebarMenu'
        className='col-lg-2 d-none d-lg-block nav flex-column nav-pills'
      >
        <h3 className='mb-5'>{t('User Settings')}</h3>

        <ul className='nav flex-column'>
          <li className='nav-item mb-2'>
            <Link
              to='/settings/edit-profile'
              className={`nav-link ${isActive('/settings/edit-profile')}`}
            >
              <FontAwesomeIcon icon={faUserCog} />
              &nbsp;{t('Edit Profile')}
            </Link>
          </li>
          <li className='nav-item mb-2' />
        </ul>
      </nav>

      <main
        role='main'
        className='col-lg-10 col-xs-12 ml-sm-auto px-lg-3 mt-lg-5'
      >
        <Switch>
          <AuthorizedRoute
            path='/settings/edit-profile'
            component={EditProfile}
          />
        </Switch>
      </main>
    </div>
  )
}
