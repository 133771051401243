import React from 'react'
import Notification from './Notification'
import NotificationType from './NotificationTypes'

import { faBell } from '@fortawesome/free-regular-svg-icons'
import { history } from '../../_helpers/history'
import { Dropdown } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './style.css'
import { NotificationAlertIcon } from '@axshealth/design-library'

export default function NotificationDropdown() {
  const { t } = useTranslation()

  const notifications = useSelector((state) => state.notification.notifications)
  const notificationCount = useSelector((state) => state.notification.count)

  const handleNotificationClick = (notification) => {
    const type = notification.notificationTypeId

    switch (type) {
      case NotificationType.NEW_MESSAGE:
        history.push(`/messages/${notification.genericId}/view`, {})
        break
      case NotificationType.NEW_CARE_PLAN:
        history.push('/care-plans')
        break

      case NotificationType.NEW_TELEHEALTH_VIDEO:
      case NotificationType.NEW_TELEHEALTH_MESSAGE:
        history.push(`/telehealth/${notification.genericId}/encounter`, {
          referrer: '',
          telehealth: {}
        })
        break
    }
  }

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
      ref={ref}
      onClick={(e) => {
        e.preventDefault()
        onClick(e)
      }}
    >
      {children}
    </div>
  ))

  return (
    <Dropdown>
      <Dropdown.Toggle as={CustomToggle}>
        {notificationCount > 0 && (
          <span id='NotificationDot'>
            <NotificationAlertIcon />
          </span>
        )}
        <FontAwesomeIcon icon={faBell} />
      </Dropdown.Toggle>

      <Dropdown.Menu id='NotificationDropdown' className='p-0'>
        <Dropdown.Header className='border-bottom text-muted font-weight-bold px-3'>
          {t('Notifications')}
        </Dropdown.Header>

        <div id='NotificationDropdownMenu'>
          {notifications && notifications.length > 0 ? (
            <Notification
              notifications={notifications}
              handleClick={handleNotificationClick}
            />
          ) : (
            <Dropdown.Item className='p-3'>
              {t('No notifications at this time')}
            </Dropdown.Item>
          )}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  )
}
