import apiVersion from '../_helpers/api-version'
import axiosInstance from '../_helpers/axiosConfig'
import { authHeader } from '../_helpers/auth-header'

export const messageService = {
  messageRead,
  sendOrCreateMessage,
  sendOrCreateAttachment,
  sendMessage,
  createMessage,
  sendAttachment,
  getConversations,
  getConversationById,
  getMessagesByConversation,
  getConversationAndMessages,
  createMessageWithAttachment
}

function messageRead(conversationId) {
  return axiosInstance
    .request({
      method: 'POST',
      headers: authHeader(),
      url: `${
        process.env.REACT_APP_API_URL
      }/${apiVersion()}/patient/conversation/${conversationId}/read`
    })
    .then(async (response) => {
      return response.data
    })
}

function sendOrCreateMessage(
  message,
  conversationId = null,
  licensedProfessionalId = null,
  locationId = null,
  patientId = null,
  socketId = null
) {
  let baseUrl = `${
    process.env.REACT_APP_API_URL
  }/${apiVersion()}/patient/conversation`

  if (conversationId) {
    baseUrl = `${baseUrl}/${conversationId}/message`
  }

  return axiosInstance
    .request({
      url: baseUrl,
      headers: authHeader(),
      method: 'POST',
      data: {
        type: 'message',
        message,
        socketId,
        patientId,
        locationId,
        licensedProfessionalId
      }
    })
    .then((response) => {
      return response.data
    })
}

function sendOrCreateAttachment(
  attachment,
  conversationId = null,
  licensedProfessionalId = null,
  locationId = null,
  patientId = null,
  socketId = null
) {
  let baseUrl = `${
    process.env.REACT_APP_API_URL
  }/${apiVersion()}/patient/conversation`

  if (conversationId) {
    baseUrl = `${baseUrl}/${conversationId}/message`
  }

  return axiosInstance
    .request({
      url: baseUrl,
      headers: authHeader(),
      method: 'POST',
      data: {
        type: 'attachment',
        socketId,
        patientId,
        attachment: attachment.base64,
        licensedProfessionalId,
        locationId
      }
    })
    .then((response) => {
      return response.data
    })
}

function sendMessage(message, conversationId) {
  return axiosInstance
    .request({
      method: 'POST',
      headers: authHeader(),
      url: `${
        process.env.REACT_APP_API_URL
      }/${apiVersion()}/patient/conversation/${conversationId}/message`,
      data: { type: 'message', message: message }
    })
    .then((response) => {
      return getConversationAndMessages(conversationId)
    })
}

function sendAttachment(attachment, conversationId) {
  return axiosInstance
    .request({
      method: 'POST',
      headers: authHeader(),
      url: `${
        process.env.REACT_APP_API_URL
      }/${apiVersion()}/patient/conversation/${conversationId}/message`,
      data: { type: 'attachment', attachment: attachment.base64 }
    })
    .then((response) => {
      return getConversationAndMessages(conversationId)
    })
}

function createMessage(message, licensedProfessionalId, patientId) {
  return axiosInstance
    .request({
      method: 'POST',
      headers: authHeader(),
      url: `${
        process.env.REACT_APP_API_URL
      }/${apiVersion()}/patient/conversation`,
      data: {
        licensedProfessionalId: licensedProfessionalId,
        patientId: patientId,
        type: 'message',
        message: message
      }
    })
    .then((response) => {
      return getConversationAndMessages(response.data.Id)
    })
}

function createMessageWithAttachment(
  attachment,
  licensedProfessionalId,
  patientId
) {
  return axiosInstance
    .request({
      method: 'POST',
      headers: authHeader(),
      url: `${
        process.env.REACT_APP_API_URL
      }/${apiVersion()}/patient/conversation`,
      data: {
        licensedProfessionalId: licensedProfessionalId,
        patientId: patientId,
        type: 'attachment',
        attachment: attachment.base64
      }
    })
    .then((response) => {
      return getConversationAndMessages(response.data.Id)
    })
}

function getConversations() {
  return axiosInstance
    .request({
      method: 'GET',
      headers: authHeader(),
      url: `${
        process.env.REACT_APP_API_URL
      }/${apiVersion()}/patient/conversation`
    })
    .then((response) => {
      return response.data.data
    })
}

function getConversationById(conversationId) {
  return axiosInstance
    .request({
      method: 'GET',
      headers: authHeader(),
      url: `${
        process.env.REACT_APP_API_URL
      }/${apiVersion()}/patient/conversation/${conversationId}`
    })
    .then(async (response) => {
      return response.data
    })
}

function getMessagesByConversation(conversationId) {
  return axiosInstance
    .request({
      method: 'GET',
      headers: authHeader(),
      url: `${
        process.env.REACT_APP_API_URL
      }/${apiVersion()}/patient/conversation/${conversationId}/message`
    })
    .then((response) => {
      return response.data.data
    })
}

async function getConversationAndMessages(conversationId) {
  const conversation = await getConversationById(conversationId)
  const messages = await getMessagesByConversation(conversationId)
  return { ...conversation, messages: messages }
}
