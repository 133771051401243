import apiVersion from '../_helpers/api-version'
import axiosInstance from '../_helpers/axiosConfig'
import { authHeader } from '../_helpers/auth-header'

export const dependentService = {
  addDependent,
  editDependent,
  deleteDependent
}

function addDependent(userId, dependent) {
  return axiosInstance.request({
    method: 'POST',
    headers: authHeader(),
    url: `${process.env.REACT_APP_API_URL}/${apiVersion()}/patient/dependents`,
    data: { ...dependent }
  })
}

function editDependent(userProfileId, dependent) {
  return axiosInstance.request({
    method: 'PATCH',
    headers: authHeader(),
    url: `${
      process.env.REACT_APP_API_URL
    }/${apiVersion()}/patient/dependents/${userProfileId}`,
    data: { ...dependent }
  })
}

function deleteDependent(userProfileId) {
  return axiosInstance.request({
    method: 'DELETE',
    headers: authHeader(),
    url: `${
      process.env.REACT_APP_API_URL
    }/${apiVersion()}/patient/dependents/${userProfileId}`
  })
}
