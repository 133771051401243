import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import rootReducer from '../_reducers/root.reducer'
import createFilter from 'redux-persist-transform-filter'
import thunkMiddleware from 'redux-thunk'

import { authentication } from '../_reducers/authentication.reducer'
import { createStore, applyMiddleware } from 'redux'

import {
  persistStore,
  persistReducer,
  persistCombineReducers
} from 'redux-persist'

const middleware = [thunkMiddleware]

if (process.env.NODE_ENV === 'development') {
  const { logger } = require(`redux-logger`)

  middleware.push(logger)
}

const auth = createFilter('authentication', [
  'jwt',
  'username',
  'hasAccount',
  'showPassword',
  'rememberUsername',
  'preferredPlatform',
  'preferredTimestamp'
])

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['authentication'],
  transforms: [auth]
}

persistCombineReducers(persistConfig, { auth: authentication })

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = createStore(
  persistedReducer,
  applyMiddleware(...middleware)
)
export const persistor = persistStore(store)
