import React from 'react'
import moment from 'moment'

import { connect } from 'react-redux'
import { alertActions } from '../../_actions/alert.actions'
import { userActions } from '../../_actions/user.actions'
import { withTranslation } from 'react-i18next'
import { isValidPassword } from '../../_helpers/regex'
import { registerConstants } from '../../_constants/register.constants'
import { registrationService } from '../../_services/registration.service'
import { authenticationService } from '../../_services/authentication.service'

import { PatientRegister } from '@axshealth/design-library'

class RegisterPage extends React.Component {
  constructor(props) {
    super(props)

    if (authenticationService.currentUserValue) {
      this.props.history.push('/')
    }

    this.state = {
      step: 1,
      refs: {},
      code: '',
      fname: '',
      lname: '',
      email: '',
      terms: false,
      gender: '',
      errors: [],
      loading: false,
      username: '',
      password: '',
      submitted: false,
      resending: false,
      verifyType: '',
      phoneNumber: '',
      dateOfBirth: '',
      showPassword: false,
      formattedPhone: ''
    }
  }

  componentDidMount() {
    this.setState({
      refs: {
        fname: React.createRef(),
        lname: React.createRef(),
        terms: React.createRef(),
        email: React.createRef(),
        gender: React.createRef(),
        username: React.createRef(),
        password: React.createRef(),
        dateOfBirth: React.createRef(),
        phoneNumber: React.createRef()
      }
    })
  }

  validate = () => {
    const errors = {}
    const { fname, lname, gender, username, password, dateOfBirth } = this.state

    this.toggleLoading()

    if (fname.length === 0) {
      errors.fname = 'First name is required.'
    }
    if (lname.length === 0) {
      errors.lname = 'Last name is required.'
    }
    if (gender.length === 0) {
      errors.gender = 'Gender is required.'
    }
    if (username.length === 0) {
      errors.username = 'Username is required.'
    }
    if (this.isValidUsername(username) === false) {
      errors.username =
        'Username can not have spaces and must be all lowercase characters.'
    }
    if (isValidPassword(password) === false) {
      errors.password = 'Password is invalid.'
    }
    if (password.length === 0) {
      errors.password = 'Password is required.'
    }
    if (dateOfBirth.length === 0) {
      errors.dateOfBirth = 'Date of Birth is required.'
    } else if (dateOfBirth.replace(/\D/g, '').length !== 8) {
      errors.dateOfBirth = 'Invalid format ex. 03/15/1980.'
    } else if (!moment(dateOfBirth, 'MM/DD/YYYY').isValid()) {
      errors.dateOfBirth = 'Date of Birth is not valid.'
    } else if (moment().diff(dateOfBirth, 'years') < 18) {
      errors.dateOfBirth =
        'You must be 18 years or older to create an AxS Health account.'
    }

    this.setState({ errors }, () => this.scrollToError())

    if (
      !errors.fname &&
      !errors.lname &&
      !errors.gender &&
      !errors.username &&
      !errors.password &&
      !errors.dateOfBirth
    ) {
      this.checkUsernameTaken(username)
    } else this.toggleLoading()
  }

  checkUsernameTaken = (username) => {
    registrationService
      .usernameExists(username)
      .then((exists) => {
        if (exists) {
          this.setState(
            { errors: { username: 'This username is taken.' } },
            () => this.scrollToError()
          )
        } else {
          this.stepForward()
        }
        this.toggleLoading()
      })
      .catch(() => {
        this.props.dispatch(
          alertActions.error('An error occurred. Please try again.')
        )
        this.scrollToTop()
        this.toggleLoading()
      })
  }

  handleSendVerifyCode = () => {
    const errors = {}
    const { email, verifyType, phoneNumber } = this.state

    this.toggleLoading()

    if (verifyType === 'sms' && phoneNumber.length < 10) {
      errors.phoneNumber = 'Phone number required.'
    }

    if (verifyType === 'email' && email.length === 0) {
      errors.email = 'Email address required.'
    }
    this.setState({ errors })

    if (
      (verifyType === 'sms' && !errors.phoneNumber) ||
      (verifyType === 'email' && !errors.email)
    ) {
      this.sendVerifyCodeRequest()
    } else this.toggleLoading()
  }

  sendVerifyCodeRequest = () => {
    const { step, verifyType, phoneNumber, email } = this.state
    registrationService
      .sendVerifyCode(verifyType, phoneNumber, email)
      .then(() => {
        if (step === 3) {
          this.stepForward()
        }
        this.setState({ resending: false, loading: false })
      })
      .catch((error) => {
        this.props.dispatch(
          alertActions.error('An error occurred. Please try again.')
        )
        this.scrollToTop()
        this.setState({
          errors: error.response ? error.response.data : '',
          loading: false,
          resending: false
        })
      })
  }

  handleCheckVerifyCode = () => {
    const errors = {}
    const { terms, code } = this.state

    if (terms === false) {
      errors.terms = 'Please agree to the Terms of Use.'
    }
    if (code.length === 0) {
      errors.code = 'Verification code is required.'
    }
    this.setState({ errors })

    if (!errors.terms && !errors.code) {
      this.registerUser()
    }
  }

  registerUser = () => {
    const {
      code,
      fname,
      lname,
      email,
      terms,
      gender,
      username,
      password,
      phoneNumber,
      dateOfBirth,
      verifyType
    } = this.state

    let licensedUser

    if (
      this.props.location.state &&
      this.props.location.state.from &&
      this.props.location.state.from.pathname &&
      this.props.location.state.from.pathname.includes('/messages/stream')
    ) {
      licensedUser = this.props.location.state.from.pathname.split('/')[3]
    }

    this.toggleLoading()

    registrationService
      .register(
        fname,
        lname,
        email,
        terms,
        gender,
        username,
        password,
        phoneNumber,
        dateOfBirth,
        verifyType,
        code,
        licensedUser
      )
      .then(() => {
        const referrer = this.props.location.state
          ? this.props.location.state.from
          : '/'

        this.props.dispatch(userActions.login(username, password, referrer))
      })
      .catch((error) => {
        if (!error.response) {
          this.props.dispatch(
            alertActions.error('An error occurred. Please try again.')
          )
        }
        this.scrollToTop()
        this.setState({
          errors: error.response ? error.response.data : '',
          loading: false
        })
      })
  }

  scrollToTop = () => {
    window.scroll(0, 0)
  }

  scrollToError = () => {
    const { refs, errors } = this.state
    for (const ref in refs) {
      if (ref in errors) {
        refs[ref].current.scrollIntoView()
        window.scrollBy(0, -200)
        break
      }
    }
  }

  toggleLoading = () => {
    this.setState((prevState) => {
      return {
        ...prevState,
        loading: !prevState.loading
      }
    })
  }

  stepForward = () => {
    this.scrollToTop()
    this.props.dispatch(alertActions.clear())
    this.setState((prevState) => {
      const step = prevState.step + 1
      this.props.dispatch({ type: registerConstants.SET_CURRENT_STEP, step })
      return { ...prevState, step: step }
    })
  }

  stepBackward = () => {
    this.scrollToTop()
    this.props.dispatch(alertActions.clear())
    this.setState((prevState) => {
      const step = prevState.step - 1
      this.props.dispatch({ type: registerConstants.SET_CURRENT_STEP, step })
      return { ...prevState, step: prevState.step - 1 }
    })
  }

  handleTermsClick = (e) => {
    e.preventDefault()
    window.open('https://www.axshealthapp.com/terms-of-use/', '_blank')
  }

  handleSetState = (value) => {
    this.setState(value)
  }

  handleSetUsername = (value) => {
    const errors = {}
    this.setState(value)

    if (!this.isValidUsername(value.username)) {
      errors.username =
        'Username can not have spaces and must be all lowercase characters.'
    }
    this.setState({ errors })
  }

  isValidUsername = (username) => {
    const hasSpace = /\s+/
    const hasUpperCase = /[A-Z]+/
    return !(hasSpace.test(username) || hasUpperCase.test(username))
  }

  render() {
    const {
      step,
      refs,
      code,
      email,
      fname,
      lname,
      terms,
      errors,
      gender,
      loading,
      username,
      password,
      resending,
      verifyType,
      dateOfBirth,
      phoneNumber,
      showPassword,
      formattedPhone
    } = this.state

    const { alert } = this.props

    return (
      <PatientRegister
        step={step}
        refs={refs}
        code={code}
        alert={alert}
        email={email}
        fname={fname}
        lname={lname}
        terms={terms}
        errors={errors}
        gender={gender}
        loading={loading}
        username={username}
        password={password}
        resending={resending}
        verifyType={verifyType}
        dateOfBirth={dateOfBirth}
        phoneNumber={phoneNumber}
        showPassword={showPassword}
        formattedPhone={formattedPhone}
        validate={this.validate}
        stepForward={this.stepForward}
        stepBackward={this.stepBackward}
        handleSetState={this.handleSetState}
        handleTermsClick={this.handleTermsClick}
        handleSetUsername={this.handleSetUsername}
        handleSendVerifyCode={this.handleSendVerifyCode}
        handleCheckVerifyCode={this.handleCheckVerifyCode}
        handleResendVerifyCode={this.sendVerifyCodeRequest}
      />
    )
  }
}

function mapStateToProps(state) {
  return {
    alert: state.alert.nextRouteMessage
  }
}
export default connect(mapStateToProps)(withTranslation()(RegisterPage))
