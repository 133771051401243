import React from 'react'
import Alert from '../../../_components/Alerts/Alert'
import ActionButton from '../../../_components/Buttons/ActionButton'
import PasswordChecklist from '../../../_components/Validation/PasswordChecklist'
import ConfirmPasswordInput from '../../../_components/Inputs/ConfirmPasswordInput'

import { Form } from 'react-bootstrap'
import { handleEnter } from '../../../_helpers/handleEnter'
import { useTranslation } from 'react-i18next'

export default function ResetPasswordForm(props) {
  const { t } = useTranslation()

  const {
    alert,
    errors,
    loading,
    password,
    confirmPassword,
    handleSetState,
    handleResetPassword
  } = props

  return (
    <Form>
      <p className='text-center my-4'>
        {t('Enter and confirm your new password.')}
      </p>
      <Alert alert={alert} />
      <div>
        <ConfirmPasswordInput
          errors={errors}
          label='New Password'
          confirmLabel='Confirm New Password'
          password={password}
          setValue={handleSetState}
          confirmPassword={confirmPassword}
          valueName='password'
          confirmValueName='confirmPassword'
          handleEnter={(e) => (handleEnter(e) ? handleResetPassword(e) : '')}
          stacked
        />
        <div className='my-4'>
          <PasswordChecklist password={password} />
        </div>
        <div className='form-group pt-4'>
          <ActionButton
            action='Next'
            variant='success'
            submit={handleResetPassword}
            loading={loading}
          />
        </div>
      </div>
    </Form>
  )
}
