import React from 'react'
import FadeIn from 'react-fade-in'
import { useTranslation } from 'react-i18next'
import { Jumbotron, Container } from 'react-bootstrap'
import './NotFoundStyle.css'

export default function NotFound() {
  const { t } = useTranslation()

  return (
    <FadeIn>
      <div className='not-found-container container-fluid text-left text-sm-center'>
        <div className='row h-100'>
          <div className='col my-auto'>
            <div className='row'>
              <div className='col col-sm-8 offset-sm-2'>
                <Jumbotron fluid className='shadow lead'>
                  <Container>
                    <p style={{ fontSize: 'xxx-large' }}>
                      {t('Page not found')}
                    </p>
                    <p>
                      {t(
                        "Sorry we couldn't find the page you were looking for"
                      )}
                    </p>
                  </Container>
                </Jumbotron>
              </div>
            </div>
          </div>
        </div>
      </div>
    </FadeIn>
  )
}
