import React, { Fragment, useEffect, useState } from 'react'
import moment from 'moment'
import MainMenu from '../../_components/MainMenu'

import { useDispatch } from 'react-redux'
import { alertActions } from '../../_actions/alert.actions'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { carePlanService } from '../../_services/care_plan.service'
import { faBriefcaseMedical } from '@fortawesome/free-solid-svg-icons'
import { Spinner, Col, Row, Card } from 'react-bootstrap'

import {
  CarePlanCardBase,
  CarePlanCardEmpty,
  MessageModalCarePlan
} from '@axshealth/design-library'

export default function CarePlans() {
  const dispatch = useDispatch(null)
  const { t } = useTranslation()
  const [loading, setLoading] = useState(true)
  const [carePlan, setCarePlan] = useState({})
  const [carePlans, setCarePlans] = useState(null)
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    setLoading(true)
    carePlanService
      .getCarePlans()
      .then((carePlans) => {
        setLoading(false)
        setCarePlans(sortCarePlansByTime(carePlans))
      })
      .catch(() => {
        window.scrollTo(0, 0)
        setLoading(false)
        dispatch(
          alertActions.error(
            'An error occurred and we could not get your care plans at this time. Please try again.'
          )
        )
      })
  }, [])

  const sortCarePlansByTime = (carePlans) => {
    return carePlans.sort((a, b) => {
      return moment(a.appointmentDate.date).isBefore(
        moment(b.appointmentDate.date)
      )
        ? 1
        : -1
    })
  }

  const handleClickAttachment = (url) => {
    window.open(url, '_blank')
  }

  const showCarePlan = (carePlan) => {
    setCarePlan(carePlan)
    setShowModal(true)
  }

  return (
    <Row>
      <Col lg={2} className='pt-1 px-0 d-none d-lg-block'>
        <MainMenu />
      </Col>
      <Col xs={12} lg={10}>
        <Row>
          <Col className='mb-3 sub__text'>
            <FontAwesomeIcon icon={faBriefcaseMedical} className='mr-2' />
            <span style={{ fontSize: '6px', verticalAlign: 'middle' }}>•</span>
            <span className='ml-2'>{t('Care Plans')}</span>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className='main__title font-weight-bold my-auto'>
              {t('Your Care Plans')}
            </div>
            {carePlans && carePlans.length > 0 && (
              <div className='my-2'>
                {t("Below you'll find your care plans.")}
              </div>
            )}
          </Col>
        </Row>

        {loading ? (
          <Spinner animation='border' className='mt-2' />
        ) : (
          carePlans !== null && (
            <Fragment>
              {Object.keys(carePlan).length > 0 && (
                <MessageModalCarePlan
                  hide={() => setShowModal(false)}
                  show={showModal}
                  carePlan={carePlan}
                  handleClickAttachment={handleClickAttachment}
                />
              )}

              <Row className='d-block d-sm-none mt-3'>
                <Col xs={12}>
                  {loading ? null : carePlans !== null &&
                    carePlans.length > 0 ? (
                    <Row>
                      {carePlans.length > 0 &&
                        carePlans.map((carePlan) => {
                          return (
                            <Col xs='12' key={carePlan.id}>
                              <Card>
                                <Card.Body className='pb-0'>
                                  <CarePlanCardBase
                                    carePlan={carePlan}
                                    showModal={() => showCarePlan(carePlan)}
                                  />
                                </Card.Body>
                              </Card>
                              <br />
                            </Col>
                          )
                        })}
                    </Row>
                  ) : (
                    <CarePlanCardEmpty />
                  )}
                </Col>
              </Row>

              <Row className='d-none d-sm-block'>
                <Col xs={12} style={{ fontWeight: 300 }}>
                  {loading ? null : carePlans !== null &&
                    carePlans.length > 0 ? (
                    <Card className='mt-3'>
                      <Card.Body>
                        <Row style={{ fontWeight: 300 }}>
                          <Col xs='6' sm='4'>
                            <span>{t('Healthcare Provider')}</span>
                          </Col>
                          <Col xs='6' sm='4' className='pl-0 pl-sm-3'>
                            {t('Appointment Date')}
                          </Col>
                        </Row>
                        <hr className='my-2' />

                        {carePlans &&
                          carePlans.length > 0 &&
                          carePlans.map((carePlan) => {
                            return (
                              <Fragment key={carePlan.id}>
                                <CarePlanCardBase
                                  carePlan={carePlan}
                                  showModal={() => showCarePlan(carePlan)}
                                />
                              </Fragment>
                            )
                          })}
                      </Card.Body>
                    </Card>
                  ) : (
                    <div className='mt-4'>
                      <CarePlanCardEmpty />
                    </div>
                  )}
                </Col>
              </Row>
            </Fragment>
          )
        )}
      </Col>
    </Row>
  )
}
