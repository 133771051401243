import React from 'react'
import { useSelector } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'
import { authenticationService } from '../_services/authentication.service'

export default ({
  component: Component,
  layout: Layout,
  props: cProps,
  roles,
  ...rest
}) => {
  const hasAccount = useSelector((state) => state.authentication.hasAccount)

  return (
    <Route
      {...rest}
      render={(props) => {
        const authToken = authenticationService.getToken()

        // Authentication
        if (authToken === null) {
          if (!hasAccount) {
            return (
              <Redirect
                to={{ pathname: '/register', state: { from: props.location } }}
              />
            )
          } else {
            return (
              <Redirect
                to={{ pathname: '/login', state: { from: props.location } }}
              />
            )
          }
        }

        if (!Layout) {
          return <Component {...props} {...cProps} />
        }

        return (
          <Layout>
            <Component {...props} {...cProps} />
          </Layout>
        )
      }}
    />
  )
}
