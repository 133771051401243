import { history } from '../_helpers/history'
import { alertActions } from './alert.actions'
import { loadingActions } from './loading.actions'
import { telehealthService } from '../_services/telehealth.service'
import { telehealthConstants as constants } from '../_constants/telehealth.constants'

export const telehealthActions = {
  clearState,
  setTelehealth,
  prependMessage,
  getTelehealthById,
  addTelehealthMessage,
  getTelehealthMessages,
  sendTelehealthMessage,
  preVisitQuestionsCompleted
}

function formatMessages(messages) {
  return messages.map((message) => {
    return {
      id: message.message_id,
      sentAt: message.sent_at,
      sentBy: message.from,
      ...message
    }
  })
}

function setTelehealth(telehealth) {
  return { type: constants.TELEHEALTH_GET_SUCCESS, telehealth }
}

function prependMessage(message) {
  return {
    type: constants.TELEHEALTH_MESSAGE_ADD,
    message: {
      id: message.message_id,
      sentAt: message.sent_at,
      sentBy: message.from,
      ...message
    }
  }
}

function clearState() {
  return { type: constants.TELEHEALTH_CLEAR_STATE }
}

function addTelehealthMessage(message) {
  return { type: constants.TELEHEALTH_MESSAGE_ADD, message }
}

function getTelehealthById(telehealthId) {
  return (dispatch) => {
    dispatch(request())

    telehealthService
      .getById(telehealthId)
      .then((telehealth) => {
        dispatch(success(telehealth))
      })
      .catch(() => {
        dispatch(failure())

        dispatch(
          alertActions.error(
            'Could not retrieve the telehealth at this time. Please try again.'
          )
        )
      })
  }

  function request() {
    return { type: constants.TELEHEALTH_GET_REQUEST }
  }
  function success(telehealth) {
    return { type: constants.TELEHEALTH_GET_SUCCESS, telehealth }
  }
  function failure() {
    return { type: constants.TELEHEALTH_GET_FAILURE }
  }
}

function sendTelehealthMessage(message, telehealthId) {
  return (dispatch) => {
    dispatch(loadingActions.toggleLoading())

    telehealthService
      .sendMessage(message, telehealthId)
      .then(() => {
        dispatch(loadingActions.toggleLoading())
      })
      .catch((error) => {
        console.error(error)
        window.scrollTo(0, 0)
        dispatch(loadingActions.toggleLoading())
        dispatch(
          alertActions.error(
            'There was a problem with your request. Please check your internet connection and try again.'
          )
        )
      })
  }
}

function getTelehealthMessages(telehealthId) {
  return (dispatch) => {
    dispatch(loadingActions.toggleLoading())
    dispatch(request())

    telehealthService
      .getMessages(telehealthId)
      .then((messages) => {
        dispatch(success(formatMessages(messages)))
        dispatch(loadingActions.toggleLoading())
      })
      .catch(() => {
        window.scrollTo(0, 0)
        dispatch(failure())
        dispatch(loadingActions.toggleLoading())
        dispatch(
          alertActions.error(
            'An error occurred and we could not get your telehealth messages at this time. Please try again.'
          )
        )
      })
  }
  function request() {
    return { type: constants.TELEHEALTH_MESSAGES_GET_REQUEST }
  }
  function failure() {
    return { type: constants.TELEHEALTH_MESSAGES_GET_FAILURE }
  }
  function success(messages) {
    return { type: constants.TELEHEALTH_MESSAGES_GET_SUCCESS, messages }
  }
}

function preVisitQuestionsCompleted(
  id,
  telehealth,
  questions,
  referrer = null
) {
  return (dispatch) => {
    dispatch(request())

    telehealthService
      .submitQuestions(id, questions)
      .then(() => {
        dispatch(success())
        history.push(referrer ? referrer.path : `/telehealth/${id}/details`, {
          date: telehealth.appointmentDate.date,
          prompt: 'preferred',
          timezone: telehealth.appointmentDate.timezone,
          preQuestionsSubmitted: true
        })
      })
      .catch(() => {
        window.scrollTo(0, 0)
        dispatch(failure())
        dispatch(
          alertActions.error(
            'An error occurred and your pre-visit questions could not be submitted at this time. Please try again.'
          )
        )
      })
  }
  function request() {
    return { type: constants.QUESTIONS_COMPLETE_REQUEST }
  }
  function success() {
    return { type: constants.QUESTIONS_COMPLETE_SUCCESS }
  }
  function failure() {
    return { type: constants.QUESTIONS_COMPLETE_FAILURE }
  }
}
