import { carePlanConstants as constants } from '../_constants/care_plan.constants'

const initialState = {
  carePlans: [],
  carePlanLoadFailed: false
}

export function carePlan(state = initialState, action) {
  if (action.type === constants.CARE_PLANS_GET_SUCCESS) {
    return {
      ...state,
      carePlans: action.carePlans
    }
  }

  if (action.type === constants.CARE_PLANS_GET_FAILURE) {
    return {
      ...state,
      carePlanLoadFailed: true
    }
  }

  return state
}
