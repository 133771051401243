import { registerConstants as constants } from '../_constants/register.constants'

const initialState = {
  currentStep: 1
}

export function register(state = initialState, action) {
  if (action.type === constants.SET_CURRENT_STEP) {
    return {
      ...state,
      currentStep: action.step
    }
  }
  return state
}
