import React, { Fragment, useEffect, useState } from 'react'
import MainMenu from '../../../_components/MainMenu'

import { Link } from 'react-router-dom'
import { history } from '../../../_helpers/history'
import { faComments } from '@fortawesome/free-regular-svg-icons'
import { alertActions } from '../../../_actions/alert.actions'
import { useTranslation } from 'react-i18next'
import { messageService } from '../../../_services/message.service'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Card, Button, Spinner } from 'react-bootstrap'

import {
  MessageCardBase,
  MessageCardEmpty,
  MessageCardNoProviders
} from '@axshealth/design-library'

export default function Messages() {
  const dispatch = useDispatch(null)
  const { t } = useTranslation()
  const [loading, setLoading] = useState(true)
  const [conversations, setConversations] = useState(null)

  const doctors = useSelector((state) => state.user.doctors || [])

  useEffect(() => {
    setLoading(true)
    getConversations()

    const messageInterval = setInterval(() => {
      getConversations()
    }, 3500)

    return () => {
      clearInterval(messageInterval)
    }
  }, [])

  const getConversations = () => {
    messageService
      .getConversations()
      .then((conversations) => {
        setLoading(false)
        setConversations(conversations)
      })
      .catch(() => {
        window.scrollTo(0, 0)
        setLoading(false)
        dispatch(
          alertActions.error(
            'An error occurred and we could not get your messages at this time. Please try again.'
          )
        )
      })
  }

  const showConversation = (id) => {
    history.push(`/messages/${id}/stream`)
  }

  const MessagesList = () => {
    return (
      <Fragment>
        <Row className='d-block d-sm-none'>
          <Col md={12}>
            {conversations ? (
              <Row>
                {Object.keys(conversations).length > 0 &&
                  conversations.map((conversation) => {
                    conversation.status = !conversation.read
                      ? 'open'
                      : conversation.status
                    return (
                      <Col xs='12' key={conversation.id}>
                        <Card key={conversation.id}>
                          <Card.Body className='pb-0'>
                            <MessageCardBase
                              isUnread={!conversation.read}
                              conversation={conversation}
                              showConversation={() =>
                                showConversation(conversation.id)
                              }
                            />
                          </Card.Body>
                        </Card>
                        <br />
                      </Col>
                    )
                  })}
              </Row>
            ) : (
              <MessageCardEmpty />
            )}
          </Col>
        </Row>
        <Row className='d-sm-block d-none'>
          <Col md={12}>
            <Card className='mt-3'>
              <Card.Body>
                <Row style={{ fontWeight: 300 }}>
                  <Col
                    xs='3'
                    sm='2'
                    className='pl-sm-4 text-center text-sm-left'
                  >
                    <span>{t('Status')}</span>
                  </Col>
                  <Col xs='4' sm='2'>
                    {t('Initiated By')}
                  </Col>
                  <Col xs='3' sm='2'>
                    {t('Sent To')}
                  </Col>
                  <Col xs='auto' sm='3' lg='3'>
                    {t('Received At')}
                  </Col>
                </Row>
                <hr className='my-2' />

                {conversations &&
                  Object.keys(conversations).length > 0 &&
                  conversations.map((conversation) => {
                    conversation.status = !conversation.read
                      ? 'open'
                      : conversation.status
                    return (
                      <Fragment key={conversation.id}>
                        <MessageCardBase
                          isUnread={!conversation.read}
                          conversation={conversation}
                          showConversation={() =>
                            showConversation(conversation.id)
                          }
                        />
                      </Fragment>
                    )
                  })}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Fragment>
    )
  }

  return (
    <Row>
      <Col lg={2} className='pt-1 px-0 d-none d-lg-block'>
        <MainMenu />
      </Col>
      <Col sm={12} lg={10}>
        <Row>
          <Col className='mb-3 sub__text'>
            <FontAwesomeIcon icon={faComments} className='mr-2' />
            <span style={{ fontSize: '6px', verticalAlign: 'middle' }}>•</span>
            <span className='ml-2'>{t('Messages')}</span>
          </Col>
        </Row>

        <Row className='main__text'>
          <Col>
            <div className='main__title font-weight-bold my-auto'>
              {t('Your Messages')}
            </div>
            {conversations && conversations.length > 0 && (
              <div className='my-2'>
                {t("Below you'll find your messages.")}
              </div>
            )}
          </Col>

          {doctors.length > 0 && (
            <Col xs='auto' sm={4} md={3}>
              <Button
                as={Link}
                to='/messages/providers'
                block
                className='my-auto my-sm-0 font-weight-light'
              >
                {t('Send Message')}
              </Button>
            </Col>
          )}
        </Row>

        {loading ? (
          <Spinner animation='border' className='mt-2' />
        ) : (
          <Fragment>
            <div className='mt-3'>
              {conversations !== null &&
                conversations.length === 0 &&
                doctors.length === 0 && <MessageCardNoProviders />}

              {conversations !== null &&
                conversations.length === 0 &&
                doctors.length > 0 && <MessageCardEmpty />}
            </div>

            {conversations !== null &&
              conversations.length > 0 &&
              doctors.length > 0 && <MessagesList />}
          </Fragment>
        )}
      </Col>
    </Row>
  )
}
