import React, { useState } from 'react'
import './style.css'

export default function RenderProfilePicture({
  image,
  cached,
  width,
  height,
  className = ''
}) {
  const [cachedImage, setCachedImage] = useState(image)

  return (
    <div
      className={`circle bio__avatar ${className}`}
      style={{
        background: `url(${cached ? cachedImage : image})`,
        width: width,
        height: height
      }}
    >
      {null}
    </div>
  )
}
