import React, { useState, useEffect } from 'react'

import { history } from '../../../_helpers/history'
import { useDispatch } from 'react-redux'
import { alertActions } from '../../../_actions/alert.actions'
import { TelehealthConsent } from '@axshealth/design-library'
import { telehealthService } from '../../../_services/telehealth.service'

export default function Consent() {
  const dispatch = useDispatch(null)
  const [telehealth, setTelehealth] = useState('')

  const beginPreVisitQuestions = () => {
    telehealthService
      .consent(telehealth.id)
      .then(() => {
        history.push('/telehealth/questions', {
          id: history.location.state.id,
          type: history.location.state.type,
          referrer: history.location.state.referrer,
          telehealth: history.location.state.telehealth
        })
      })
      .catch(() => {
        dispatch(
          alertActions.error(
            'An error occurred and we could not get your telehealth appointment at this time. Please try again.'
          )
        )
      })
  }

  useEffect(() => {
    if (history.location.state === undefined) {
      history.push('/telehealth')
      dispatch(
        alertActions.error(
          'An error occurred and we could not get your telehealth appointment at this time. Please try again.'
        )
      )
    } else {
      setTelehealth(history.location.state.telehealth)
    }
  }, [])

  const handleTermsClick = (e) => {
    e.preventDefault()
    window.open('https://www.axshealthapp.com/terms-of-use/', '_blank')
  }

  return (
    <TelehealthConsent
      telehealth={telehealth}
      handleClick={handleTermsClick}
      beginPreVisitQuestions={beginPreVisitQuestions}
    />
  )
}
