import React from 'react'
import Attachment from './Attachment'
import ActionButton from '../Buttons/ActionButton'
import useWindowDimensions from '../../_helpers/dimensions'

import { Button, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

export default function AttachmentModal({
  attachment,
  isSending,
  hideModal,
  setAttachment,
  sendAttachment,
  invalidAttachment,
  showAddAttachment
}) {
  const { t } = useTranslation()
  const { width, breakPointMD } = useWindowDimensions()

  return (
    <Modal
      backdrop={attachment && isSending ? 'static' : true}
      centered={width < breakPointMD}
      show={showAddAttachment}
      onHide={hideModal}
    >
      <Modal.Header closeButton>
        <Modal.Title>{t('Send a File')}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {invalidAttachment && (
          <div className='alert alert-danger'>{t('Please select a file')}.</div>
        )}
        <Attachment
          attachment={attachment}
          selected={(file) => setAttachment(file)}
          removeSelected={() => setAttachment('')}
        />
      </Modal.Body>

      <Modal.Footer>
        <Button variant='secondary' disabled={isSending} onClick={hideModal}>
          {t('Cancel')}
        </Button>

        <ActionButton
          block={false}
          style={{ width: '138px' }}
          action='Send File'
          submit={() => sendAttachment(attachment)}
          loading={isSending}
          disabled={isSending || !attachment}
          variant='success'
        />
      </Modal.Footer>
    </Modal>
  )
}
