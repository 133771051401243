import React, { Component } from 'react'
import { connect } from 'react-redux'
import { PusherProvider } from '../../_contexts/PusherContext'
import { withTranslation } from 'react-i18next'

import Alert from '../../_components/Alerts/Alert'
import Pusher from 'pusher-js/with-encryption'
import NavBar from '../../_components/NavBar/NavBar'
import SideMenu from '../../_components/SideMenu'
import PreferredPlatform from '../../_components/PreferredPlatform/'

import {
  addSidebarClickListener,
  removeSidebarClickListener
} from '../../_helpers/setup-sidebar'

const pusherClient = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
  authEndpoint: process.env.REACT_APP_PUSHER_AUTH,
  wsHost: process.env.REACT_APP_PUSHER_HOST,
  wsPort: process.env.REACT_APP_PUSHER_PORT,
  wssPort: process.env.REACT_APP_PUSHER_PORT,
  encryptionMasterKeyBase64: process.env.REACT_APP_PUSHER_ENCRYPT_KEY,
  forceTLS: true,
  encrypted: true,
  disableStats: true,
  enabledTransports: ['ws', 'wss'],
  auth: { headers: { Authorization: null } },
  disabledTransports: ['sockjs', 'xhr_polling', 'xhr_streaming']
})

class ChatStream extends Component {
  componentDidMount() {
    addSidebarClickListener()
  }

  componentWillUnmount() {
    removeSidebarClickListener()
  }

  render() {
    const { token } = this.props

    pusherClient.config.auth.headers.Authorization = `Bearer ${token}`

    return (
      <PusherProvider value={pusherClient}>
        <NavBar />
        <SideMenu />

        <div className='container-lg h-100'>
          <Alert className='mt-4' alert={this.props.alertNextRoute} />
          <Alert className='mt-4' alert={this.props.alertDelay} />

          <PreferredPlatform />

          {this.props.children}
        </div>
      </PusherProvider>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.authentication.jwt,
    alertDelay: state.alert.delayMessage,
    alertNextRoute: state.alert.nextRouteMessage
  }
}
export default connect(mapStateToProps)(withTranslation()(ChatStream))
