import React, { Component, Fragment } from 'react'
import { Trans, withTranslation } from 'react-i18next'

class PasswordChecklist extends Component {
  constructor(props) {
    super(props)

    this.state = {
      password: props.password || ''
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.password !== this.props.password) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ password: this.props.password })
    }
  }

  requirementLength() {
    const requirement = /^(?=.{8,})/

    return requirement.test(this.state.password)
  }

  requirementUppercase() {
    const requirement = /^(?=.*[A-Z])/

    return requirement.test(this.state.password)
  }

  requirementNumber() {
    const requirement = /^(?=.*[0-9])/

    return requirement.test(this.state.password)
  }

  requirementSpecialCharacter() {
    const requirement = /^(?=.*[!@#$%^&*])/

    return requirement.test(this.state.password)
  }

  render() {
    const { t } = this.props
    return (
      <Fragment>
        <p className='d-none d-sm-block text-muted'>
          <small>
            <Trans i18nKey='passwordChecklistDesktop'>
              Your password must be{' '}
              <span
                className={`font-weight-bold ${
                  this.requirementLength() ? 'text-success' : ''
                }`}
              >
                8 Characters
              </span>{' '}
              long, include a{' '}
              <span
                className={`font-weight-bold ${
                  this.requirementNumber() ? 'text-success' : ''
                }`}
              >
                Number
              </span>{' '}
              (0-9), include an{' '}
              <span
                className={`font-weight-bold ${
                  this.requirementUppercase() ? 'text-success' : ''
                }`}
              >
                Uppercase Letter
              </span>{' '}
              (A-Z), and a{' '}
              <span
                className={`font-weight-bold ${
                  this.requirementSpecialCharacter() ? 'text-success' : ''
                }`}
              >
                Special Character
              </span>{' '}
              (!, @, #, $, %, ^, &, *)
            </Trans>
          </small>
        </p>
        <div className='d-sm-none d-block text-muted'>
          <small>
            {t('Your password must have')}
            <ul>
              <li>
                <span
                  className={`font-weight-bold ${
                    this.requirementNumber() ? 'text-success' : ''
                  }`}
                >
                  {t('Number')}
                </span>{' '}
                (0-9)
              </li>
              <li>
                <span
                  className={`font-weight-bold ${
                    this.requirementLength() ? 'text-success' : ''
                  }`}
                >
                  {t('8 Characters')}
                </span>
              </li>
              <li>
                <span
                  className={`font-weight-bold ${
                    this.requirementUppercase() ? 'text-success' : ''
                  }`}
                >
                  {t('Uppercase Letter')}
                </span>{' '}
                (A-Z)
              </li>
              <li>
                <span
                  className={`font-weight-bold ${
                    this.requirementSpecialCharacter() ? 'text-success' : ''
                  }`}
                >
                  {t('Special Character')}
                </span>{' '}
                (!, @, #, $, %, ^, &, *)
              </li>
            </ul>
          </small>
        </div>
      </Fragment>
    )
  }
}

export default withTranslation()(PasswordChecklist)
