import apiVersion from '../_helpers/api-version'
import axiosInstance from '../_helpers/axiosConfig'
import { authHeader } from '../_helpers/auth-header'

export const doctorService = {
  getDoctor,
  getDoctors,
  getByCode
}

function getByCode(code) {
  return axiosInstance
    .request({
      method: 'GET',
      headers: authHeader(),
      url: `${
        process.env.REACT_APP_API_URL
      }/${apiVersion()}/patient/doctor/${code}`
    })
    .then((response) => {
      return response.data
    })
}

function getDoctors() {
  return axiosInstance
    .request({
      method: 'GET',
      headers: authHeader(),
      url: `${
        process.env.REACT_APP_API_URL
      }/${apiVersion()}/patient/appointment`
    })
    .then((response) => {
      const doctors = []
      Object.values(response.data).map((type) => {
        if (type.data) {
          type.data.map((element) => {
            if (
              doctors.length === 0 ||
              doctors.find((doctor) => doctor.id !== element.doctor.id)
            ) {
              doctors.push(element.doctor)
            }
          })
        }
      })
      return doctors
    })
}

async function getDoctor(doctorId) {
  const doctors = await getDoctors()
  const doctor = doctors.find((doctor) => doctor.id === parseInt(doctorId))
  return doctor
}
