const {
  connect,
  createLocalTracks,
  createLocalVideoTrack,
  createLocalAudioTrack
} = require('twilio-video')

class SynchronousVideo {
  constructor(
    localMediaLoader,
    localMediaContainer,
    remoteMediaContainer,
    deviceType,
    throwError,
    returnToMessages,
    t
  ) {
    this.t = t
    this.room = null
    this.observer = null
    this.deviceType = deviceType
    this.throwError = throwError
    this.localTracks = null
    this.localVideoTrack = null
    this.localAudioInputTrack = null
    this.localAudioOutputTrack = null
    this.localAudioOutputDeviceId = 'default'
    this.localMediaLoader = localMediaLoader
    this.localMediaContainer = localMediaContainer
    this.remoteMediaContainer = remoteMediaContainer
    this.handleReturnToMessages = returnToMessages
  }

  subscribe(fn) {
    this.observer = fn
  }

  handleUpdateVideoDevice(deviceId, cameraFacing = null) {
    const deviceType = this.deviceType
    const localVideoTrack = this.localVideoTrack
    const localMediaLoader = this.localMediaLoader
    const localParticipant = this.room.localParticipant
    const localMediaContainer = this.localMediaContainer
    const updateVideoTrack = (videoTrack) => {
      this.localVideoTrack = videoTrack
    }

    if (deviceId !== '') {
      // Show local video spinner
      localMediaContainer.style.display = 'none'
      localMediaLoader.style.display = 'block'

      return createLocalVideoTrack({
        deviceId: { exact: deviceId }
      })
        .then(function (track) {
          localVideoTrack.stop()
          localParticipant.unpublishTrack(localVideoTrack)
          localParticipant.publishTrack(track)

          const localVideo = Array.from(localMediaContainer.children).find(
            (child) => child.tagName === 'VIDEO'
          )

          // If local media container is not empty
          if (localVideo) {
            localVideo.remove()
          }

          localMediaContainer.appendChild(track.attach())
          updateVideoTrack(track)

          // If device is mobile or tablet, transform the local preview so it is correctly oriented
          if (deviceType !== undefined && cameraFacing !== null) {
            const videoTag = localMediaContainer.getElementsByTagName('video')

            if (cameraFacing === 'environment') {
              videoTag[0].style.transform = 'scaleX(1)'
            } else {
              videoTag[0].style.transform = 'scaleX(-1)'
            }
          }
          // Hide local video spinner
          localMediaContainer.style.display = 'block'
          localMediaLoader.style.display = 'none'

          return true
        })
        .catch(() => {
          window.alert(
            this.t(
              'An error occurred and your video device could not be updated at this time.'
            )
          )
          return false
        })
    }
  }

  handleUpdateAudioInputDevice(deviceId) {
    const localParticipant = this.room.localParticipant
    const localAudioInputTrack = this.localAudioInputTrack
    const updateAudioTrack = (audioTrack) => {
      this.localAudioInputTrack = audioTrack
    }

    if (deviceId !== '') {
      return createLocalAudioTrack({
        deviceId: { exact: deviceId }
      })
        .then(function (track) {
          localAudioInputTrack.stop()
          localParticipant.unpublishTrack(localAudioInputTrack)
          localParticipant.publishTrack(track)
          updateAudioTrack(track)
          return true
        })
        .catch(() => {
          window.alert(
            this.t(
              'An error occurred and your audio input could not be updated at this time.'
            )
          )
          return false
        })
    }
  }

  handleUpdateAudioOutputDevice(deviceId) {
    if (this.localAudioOutputTrack === null) {
      this.localAudioOutputDeviceId = deviceId
      return true
    } else if (deviceId) {
      this.localAudioOutputTrack
        .detach()
        .forEach((mediaElement) => mediaElement.remove())

      this.localAudioOutputTrack.attach()

      if (typeof this.localAudioOutputTrack.sinkId !== 'undefined') {
        this.localAudioOutputTrack.setSinkId(deviceId)
      }
      return true
    }
    return false
  }

  handleStopVideo(isStopped) {
    this.localVideoTrack && this.localVideoTrack.enable(isStopped)
  }

  handleMute(isMuted) {
    this.localAudioInputTrack && this.localAudioInputTrack.enable(isMuted)
  }

  endCall() {
    if (this.localVideoTrack !== null && this.localVideoTrack !== undefined) {
      this.localVideoTrack.stop()
    }
    if (
      this.localAudioInputTrack !== null &&
      this.localAudioInputTrack !== undefined
    ) {
      this.localAudioInputTrack.stop()
    }
    if (this.localTracks !== null) {
      this.localTracks.forEach((track) => {
        track.stop()
        const mediaElements = track.detach()
        mediaElements.forEach((mediaElement) => mediaElement.remove())
      })
    }
    if (this.room && this.room !== null) {
      this.room.disconnect()
    }
  }

  connect(token, getMediaDevices) {
    const deviceType = this.deviceType

    createLocalTracks({ audio: true, video: true })
      .then((localTracks) => {
        localTracks.forEach((track) => {
          if (track.kind === 'video') {
            this.localVideoTrack = track
          } else if (track.kind === 'audio') {
            this.localAudioInputTrack = track
          }
          // If it's a desktop device, append the track.
          // Mobile will append track using getMediaDevices()
          if (deviceType === undefined) {
            this.localMediaContainer.appendChild(track.attach())
            this.localMediaLoader.style.display = 'none'
            this.localMediaContainer.style.display = 'block'
          }
        })

        this.localTracks = localTracks

        return connect(token, {
          tracks: this.localTracks
        })
      })
      .then((room) => {
        this.observer(this.t('Waiting for your healthcare provider'))

        this.room = room

        setTimeout(() => {
          getMediaDevices()
        }, 1500)

        // Doctor already in the room
        this.room.participants.forEach((participant) => {
          this.observer(
            this.t('Participant has joined', { name: participant.identity })
          )

          // participant.tracks.forEach(publication => {
          //     publication.on('subscribed', remoteTrack => {
          //         this.remoteMediaContainer.appendChild(remoteTrack.attach());
          //     });
          //     publication.on('unsubscribed', remoteTrack => {
          //         const mediaElements = remoteTrack.detach();
          //         mediaElements.forEach(mediaElement => mediaElement.remove());
          //     });
          // });

          // Listen for future publish tracks
          participant.on('trackSubscribed', (track) => {
            if (track.kind === 'audio' && this.deviceType === undefined) {
              this.localAudioOutputTrack = track
              this.handleUpdateAudioOutputDevice(this.localAudioOutputDeviceId)
            }
            this.remoteMediaContainer.appendChild(track.attach())
          })
          participant.on('trackUnsubscribed', (track) => {
            const mediaElements = track.detach()
            mediaElements.forEach((mediaElement) => mediaElement.remove())
          })
        })

        this.room.on('participantConnected', (participant) => {
          this.observer(
            this.t('Participant has joined', { name: participant.identity })
          )

          // Already publish participants for this participant tracks
          participant.tracks.forEach((publication) => {
            if (publication.isSubscribed) {
              const track = publication.track
              this.remoteMediaContainer.appendChild(track.attach())
            }
          })

          // Listen for future publish tracks
          participant.on('trackSubscribed', (track) => {
            if (track.kind === 'audio' && this.deviceType === undefined) {
              this.localAudioOutputTrack = track
              this.handleUpdateAudioOutputDevice(this.localAudioOutputDeviceId)
            }
            this.remoteMediaContainer.appendChild(track.attach())
          })
          participant.on('trackUnsubscribed', (track) => {
            const mediaElements = track.detach()
            mediaElements.forEach((mediaElement) => mediaElement.remove())
          })
        })

        this.room.on('participantDisconnected', (participant) => {
          this.endCall()
          this.handleReturnToMessages()
        })

        this.room.on('disconnected', (room) => {
          this.room.localParticipant.tracks.forEach((publication) => {
            const attachedElements = publication.track.detach()
            attachedElements.forEach((element) => element.remove())
          })
        })
      })
      .catch((error) => {
        console.log(error)
        this.throwError()
        this.endCall()
        this.handleReturnToMessages()
      })
  }
}

export default SynchronousVideo
