import React, { Fragment, useEffect, useState } from 'react'
import moment from 'moment'
import MainMenu from '../../_components/MainMenu'

import { faHome } from '@fortawesome/free-solid-svg-icons'
import { history } from '../../_helpers/history'
import { alertActions } from '../../_actions/alert.actions'
import { eventService } from '../../_services/event.service'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Spinner, Col, Row } from 'react-bootstrap'
import { connect, useDispatch } from 'react-redux'
import { getCookie, setCookie } from '../../_helpers/cookieJar'

import {
  FormModalExpired,
  FormModalCanceled,
  EventCardNoProviders,
  EventVerticalTimeline,
  TelehealthModalExpired,
  TelehealthModalCanceled
} from '@axshealth/design-library'

import 'react-vertical-timeline-component/style.min.css'
import { authenticationActions } from '../../_actions/authentication.actions'

const Home = ({ patient, doctors }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch(null)
  const [doctor, setDoctor] = useState(null)
  const [direction, setDirection] = useState('')
  const [hasPrevious, setHasPrevious] = useState(false)
  const [upcomingEvents, setUpcomingEvents] = useState(null)
  const [previousEvents, setPreviousEvents] = useState(null)
  const [showFormExpired, setShowFormExpired] = useState(false)
  const [showFormCanceled, setShowFormCanceled] = useState(false)
  const [showTelehealthExpired, setShowTelehealthExpired] = useState(false)
  const [showTelehealthCanceled, setShowTelehealthCanceled] = useState(false)

  const today = moment().local().format('Y-MM-DD')

  useEffect(() => {
    setTimeout(() => {
      dispatch(authenticationActions.displayPreferredPlatform('HOME'))
    }, 3000)
  }, [])

  useEffect(() => {
    const direction = getCookie('direction') || 'upcoming'
    setDirection(direction)
  }, [])

  useEffect(() => {
    eventService
      .getTimeline('previous', today)
      .then((response) => {
        setHasPrevious(response.data.length > 0)
        setPreviousEvents(response.data)
      })
      .catch((error) => {
        console.error(error)
        if (direction === 'previous') {
          dispatch(
            alertActions.error(
              'An error occurred and we could not get your upcoming forms and telehealth appointments at this time. Please try again.'
            )
          )
        }
      })
  }, [])

  useEffect(() => {
    eventService
      .getTimeline('upcoming', today)
      .then((response) => {
        setUpcomingEvents(response.data)
      })
      .catch((error) => {
        console.error(error)
        if (direction === 'upcoming') {
          dispatch(
            alertActions.error(
              'An error occurred and we could not get your upcoming forms and telehealth appointments at this time. Please try again.'
            )
          )
        }
      })
  }, [])

  const handleToggleDirection = (e) => {
    e.preventDefault()
    const newDirection = direction === 'upcoming' ? 'previous' : 'upcoming'
    setCookie('direction', newDirection)
    setDirection(newDirection)
  }

  const messageProvider = () => {
    history.push(`/messages/stream`, { doctorId: doctor })
  }

  const handleClick = (item) => {
    let type = ''
    if ('telemedicineType' in item) {
      type = 'telehealth'
    } else if ('type' in item) {
      type = 'event'
    } else {
      type = 'form'
    }

    if (item.status === 'SCHEDULED') {
      window.alert(
        t(
          `Your healthcare provider would like the information to be completed at a specific time.`
        )
      )
    } else if (type === 'form') {
      if (item.status === 'SENT' || item.status === 'READY') {
        history.push(`/forms/questions`, {
          id: item.id,
          type: 'form',
          referrer: item.referrer
        })
      } else if (item.status === 'COMPLETED') {
        history.push(`/forms/view`, { id: item.id, referrer: item.referrer })
      } else if (item.status === 'EXPIRED') {
        setDoctor(item.doctor.id)
        setShowFormExpired(true)
      } else if (item.status === 'CANCELED') {
        setDoctor(item.doctor.id)
        setShowFormCanceled(true)
      }
    } else if (type === 'telehealth') {
      if (item.status === 'SENT') {
        history.push(`/telehealth/consent`, {
          id: item.id,
          type: 'telehealth',
          referrer: item.referrer,
          telehealth: item
        })
      } else if (
        item.status === 'HOLD' ||
        item.status === 'READY' ||
        item.status === 'COMPLETED'
      ) {
        history.push(`/telehealth/${item.id}/encounter`, {
          referrer: item.referrer,
          telehealth: item
        })
      } else if (item.status === 'EXPIRED') {
        setDoctor(item.doctor.id)
        setShowTelehealthExpired(true)
      } else if (item.status === 'CANCELED') {
        setDoctor(item.doctor.id)
        setShowTelehealthCanceled(true)
      }
    } else {
      history.push(`event/${item.id}`)
    }
  }

  return (
    <>
      <Row>
        <Col lg={2} className='pt-1 px-0 d-none d-lg-block'>
          <MainMenu />
        </Col>
        <Col sm={12} lg={10}>
          <Row className='sub__text'>
            <Col>
              <FontAwesomeIcon icon={faHome} className='mr-2' />
              <span style={{ fontSize: '6px', verticalAlign: 'middle' }}>
                •
              </span>
              <span className='ml-2'>{t('Home')}</span>
            </Col>
          </Row>
          <Row>
            <Col className='main__title main__text font-weight-bold mt-3'>
              {patient.firstName
                ? t('Welcome, name!', {
                    name: `${patient.firstName} ${patient.lastName}`
                  })
                : t('Welcome!')}
            </Col>
          </Row>
          <Row>
            <Col>
              {upcomingEvents === null || previousEvents === null ? (
                <Spinner animation='border' className='mt-2' />
              ) : doctors.length === 0 ? (
                <div className='mt-4'>
                  <EventCardNoProviders />
                </div>
              ) : (
                <Fragment>
                  <FormModalExpired
                    show={showFormExpired}
                    message={messageProvider}
                    hide={() => setShowFormExpired(false)}
                  />

                  <FormModalCanceled
                    show={showFormCanceled}
                    message={messageProvider}
                    hide={() => setShowFormCanceled(false)}
                  />

                  <TelehealthModalExpired
                    show={showTelehealthExpired}
                    message={messageProvider}
                    hide={() => setShowTelehealthExpired(false)}
                  />

                  <TelehealthModalCanceled
                    show={showTelehealthCanceled}
                    message={messageProvider}
                    hide={() => setShowTelehealthCanceled(false)}
                  />

                  <EventVerticalTimeline
                    events={
                      direction === 'upcoming' ? upcomingEvents : previousEvents
                    }
                    loading={false}
                    direction={direction}
                    hasPrevious={hasPrevious}
                    handleClick={handleClick}
                    toggleDirection={handleToggleDirection}
                  />
                </Fragment>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )
}

function mapStateToProps(state) {
  return {
    patient: state.user.currentUser,
    doctors: state.user.doctors || []
  }
}
export default connect(mapStateToProps)(Home)
