import React from 'react'
import { Switch, useRouteMatch } from 'react-router-dom'

import AppLayout from '../../_layouts/App/App'
import AuthorizedRoute from '../../_helpers/authorized.route'
import ChatStreamLayout from '../../_layouts/ChatStream'

import List from './List'
import Stream from './Stream'
import Provider from './Providers'

function Messages() {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <AuthorizedRoute
        layout={ChatStreamLayout}
        path={`${path}/:id/stream/:code?`}
        component={Stream}
      />
      <AuthorizedRoute
        layout={ChatStreamLayout}
        path={`${path}/stream/:code?`}
        component={Stream}
      />
      <AuthorizedRoute
        layout={ChatStreamLayout}
        path={`${path}/providers`}
        component={Provider}
      />
      <AuthorizedRoute layout={AppLayout} path={path} component={List} />
    </Switch>
  )
}

export default Messages
