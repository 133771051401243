import React, { useEffect, useState } from 'react'
import AxSLogo from '../../images/logo@2x.png'
import { useTranslation } from 'react-i18next'

import { getCookie } from '../../_helpers/cookieJar'

export default function LoginSidebar(props) {
  const [logo, setLogo] = useState()
  const [copyName, setCopyName] = useState()

  useEffect(() => {
    const clientLogo = getCookie('client-logo')
    const clientName = getCookie('client-name')

    setLogo(clientLogo || AxSLogo)
    setCopyName(clientName || 'Your healthcare provider')
  }, [])

  const { t } = useTranslation()
  return (
    <div className='instructions h-100 font-weight-light text-white text-center pt-4 px-4'>
      <img src={logo} alt='Logo' style={{ height: '5em' }} />
      <div>
        <br />
        <div className='py-4 mt-2'>
          {props.location.indexOf('/login') === 0 && (
            <>
              <p>{t('Intro Client Copy', { clientName: t(copyName) })}</p>
              <p>{t('Please login to continue.')}</p>
            </>
          )}

          {props.location.indexOf('/register') === 0 && props.step === 1 ? (
            <>
              <p>{t('Intro Client Copy', { clientName: t(copyName) })}</p>
              <p>{t('Please create an account to continue.')}</p>
            </>
          ) : props.step === 4 ? (
            <>
              <h5 className='pb-4 mb-4'>{t('Last Step!')}</h5>
              <br />
            </>
          ) : (
            props.step > 1 && (
              <>
                <h5 className='pb-2'>{t('Almost Done!')}</h5>
                <br />
              </>
            )
          )}
        </div>
        <br />
        {props.location.indexOf('/login') === 0 && (
          <div className='pt-4'>{t("Don't have an account?")}</div>
        )}
        {props.location.indexOf('/register') === 0 && (
          <div>{t('Already have an account?')}</div>
        )}
      </div>
      {props.children}
    </div>
  )
}
