export default function handleValueType(type) {
  switch (type) {
    case 'date':
      return 'valueDate'
    case 'text':
      return 'valueString'
    case 'string':
      return 'valueString'
    case 'choice':
      return 'valueString'
    case 'display':
      return 'valueDisplay'
    case 'integer':
      return 'valueInteger'
    case 'boolean':
      return 'valueBoolean'
    case 'attachment':
      return 'valueAttachment'
    case 'open-choice':
      return 'valueString'
    default:
      return `value${type.charAt(0).toUpperCase() + type.slice(1)}`
  }
}
