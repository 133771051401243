import React from 'react'
import LinkButton from '../Buttons/LinkButton'
import { Spinner } from 'react-bootstrap'

export default function ResendCode(props) {
  const { resending, resendCode } = props

  return (
    <>
      {resending ? (
        <Spinner animation='border' />
      ) : (
        <LinkButton
          link='/'
          handleClick={(e) => {
            e.preventDefault()
            resendCode()
          }}
          title='Resend Verification Code'
        />
      )}
    </>
  )
}
