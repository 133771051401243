import React, { Component } from 'react'
import moment from 'moment'
import Deeplink from '../../../_layouts/Deeplink'
import AppLayout from '../../../_layouts/App/App'
import AddFamilyMember from '../../FamilyMembers/Components/AddFamilyMember'

import { connect } from 'react-redux'
import { history } from '../../../_helpers/history'
import { Spinner } from 'react-bootstrap'
import { alertActions } from '../../../_actions/alert.actions'
import { userActions } from '../../../_actions/user.actions'
import { withTranslation } from 'react-i18next'
import { telehealthService } from '../../../_services/telehealth.service'
import { authenticationService } from '../../../_services/authentication.service'
import { authenticationConstants } from '../../../_constants/authentication.constants'

import {
  TelehealthVerificationGuest,
  TelehealthVerificationVerify,
  TelehealthVerificationConfirm
} from '@axshealth/design-library'

class TelehealthDeepLink extends Component {
  constructor(props) {
    super(props)

    this.state = {
      telehealth: null,
      error: '',
      loading: true,
      assigned: false,
      verifying: false,
      confirmed: '',
      confirming: false,
      inputErrors: {},
      authenticated: authenticationService.getToken(),
      showAddFamilyMember: false
    }
    this.verifyDOB = this.verifyDOB.bind(this)
    this.handleFindPatient = this.handleFindPatient.bind(this)
    this.handleConfirmPatient = this.handleConfirmPatient.bind(this)
  }

  componentDidMount() {
    if (this.props.verifiedToken) {
      this.retrieveTelehealthAndPatient(this.props.verifiedToken)
    } else {
      this.retrieveTelehealth()
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.currentUser !== prevProps.currentUser &&
      this.state.telehealth !== null
    ) {
      this.handleFindPatient()
    }
  }

  retrieveTelehealth() {
    telehealthService
      .getByToken(this.props.match.params.token)
      .then((response) => {
        this.setState({
          telehealth: response.data,
          loading: false
        })
      })
      .catch((error) => {
        this.setState({
          telehealth: false,
          error: error.response && error.response.data.message,
          loading: false
        })
      })
  }

  retrieveTelehealthAndPatient(verifiedToken) {
    telehealthService
      .getByVerifiedToken(this.props.match.params.token, verifiedToken)
      .then((response) => {
        this.setState({
          telehealth: response.data,
          loading: this.state.authenticated,
          assigned: response.data.assigned
        })
        if (response.data.assigned && this.state.authenticated) {
          history.push(`/telehealth/questions`, {
            id: response.data.id,
            type: 'telehealth',
            referrer: ''
          })
        } else if (this.state.authenticated) {
          this.props.dispatch(userActions.storeUser())
        }
      })
      .catch((error) => {
        this.setState(
          { error: error.response && error.response.data.message },
          () => this.setState({ telehealth: false, loading: false })
        )
        if (error.response && error.response.status === 403) {
          this.setState({
            error:
              'We could not verify your identity. Please click on the email or text message link again.'
          })
        }
      })
  }

  verifyDOB(dob) {
    const inputErrors = {}
    this.setState({ verifying: true, inputErrors: {} })
    this.props.dispatch(alertActions.clear())

    if (dob.length === 0) {
      inputErrors.dateOfBirth = 'Date of Birth is required.'
    } else if (dob.replace(/\D/g, '').length !== 8) {
      inputErrors.dateOfBirth = 'Invalid telehealthat ex. 03/15/1980.'
    } else if (!moment(dob, 'MM/DD/YYYY').isValid()) {
      inputErrors.dateOfBirth = 'Date of Birth is not valid.'
    }

    if (!inputErrors.dateOfBirth) {
      const dlToken = this.props.match.params.token
      const dobFormatted = moment(dob).format('YYYY-MM-DD')

      telehealthService
        .verifyDob(dobFormatted, dlToken)
        .then((response) => {
          if (response.data.telehealth.assigned && this.state.authenticated) {
            history.push(`/telehealth/questions`, {
              id: response.data.telehealth.id,
              type: 'telehealth',
              referrer: ''
            })
          } else {
            this.props.dispatch({
              type: authenticationConstants.AUTHENTICATION_SET_VERIFIED_TOKEN,
              token: response.data.verifiedToken
            })
            if (this.state.authenticated) {
              this.retrieveTelehealthAndPatient(response.data.verifiedToken)
            } else {
              this.setState({ telehealth: response.data.telehealth }, () => {
                this.setState({ verifying: false })
              })
            }
          }
        })
        .catch((error) => {
          window.scroll(0, 0)
          this.setState({ verifying: false })
          this.props.dispatch(alertActions.error(error.response.data.message))
        })
    } else {
      this.setState({ inputErrors, verifying: false })
    }
  }

  handleFindPatient() {
    if (Object.values(this.props.currentUser).length > 0) {
      let found = false
      const patients = [this.props.currentUser, ...this.props.dependents]

      patients.map((patient) => {
        if (
          patient.dateOfBirth === this.state.telehealth.patientDateOfBirth &&
          patient.firstName.toLowerCase() ===
            this.state.telehealth.patientFirstName.toLowerCase() &&
          patient.lastName.toLowerCase() ===
            this.state.telehealth.patientLastName.toLowerCase()
        ) {
          this.handleConfirmPatient(patient.userProfileId, false)
          found = true
        }
      })
      if (!found) {
        this.setState({ loading: false, verifying: false })
      }
    }
  }

  handleConfirmPatient(profileId, clicked = true) {
    this.setState({ confirmed: profileId, confirming: true }, () => {
      telehealthService
        .confirmPatient(
          profileId,
          this.props.match.params.token,
          this.props.verifiedToken
        )
        .then(() => {
          this.props.dispatch(userActions.loadUser())
          if (this.state.telehealth.status === 'SENT') {
            history.push(`/telehealth/consent`, {
              id: this.state.telehealth.id,
              type: 'telehealth',
              telehealth: this.state.telehealth
            })
          } else {
            history.push(`/telehealth/${this.state.telehealth.id}/details`)
          }
        })
        .catch((error) => {
          this.setState({ error: error.response.data.message }, () => {
            this.setState({ confirmed: '', confirming: false, loading: false })
          })
          if (clicked) {
            this.props.dispatch(
              alertActions.error(
                'We could not confirm your selection at this time. Check your connection and try again.'
              )
            )
          }
        })
    })
  }

  telehealthLoading() {
    if (this.props.verifiedToken) {
      return (
        <AppLayout>
          <Spinner animation='border' role='status'>
            <span className='sr-only'>Loading...</span>
          </Spinner>
        </AppLayout>
      )
    } else {
      return (
        <Deeplink loading={this.state.loading}>
          <Spinner animation='border' role='status'>
            <span className='sr-only'>Loading...</span>
          </Spinner>
        </Deeplink>
      )
    }
  }

  verifyTelehealth() {
    return (
      <Deeplink
        loading={this.state.loading}
        provider={this.state.telehealth.provider}
      >
        <TelehealthVerificationVerify
          telehealth={this.state.telehealth}
          error={this.state.error}
          verifying={this.state.verifying}
          verifyDOB={this.verifyDOB}
          inputErrors={this.state.inputErrors}
        />
      </Deeplink>
    )
  }

  telehealthVerified() {
    return (
      <Deeplink
        loading={this.state.loading}
        provider={this.state.telehealth.provider}
      >
        <TelehealthVerificationGuest
          telehealth={this.state.telehealth}
          error={this.state.error}
          handleLogin={() =>
            history.push('/login', { from: history.location.pathname })
          }
          handleCreateAccount={() => {
            history.push('/deep-link/registration', {
              formData: {
                firstName: this.state.telehealth.patientFirstName,
                lastName: this.state.telehealth.patientLastName,
                dateOfBirth: this.state.telehealth.patientDateOfBirth
              },
              verifiedType: 'telehealth',
              verifiedToken: this.props.verifiedToken,
              from: history.location.pathname
            })
          }}
        />
      </Deeplink>
    )
  }

  telehealthConfirmation() {
    return (
      <AppLayout>
        <AddFamilyMember
          showAddFamilyMember={this.state.showAddFamilyMember}
          hideAddFamilyMember={() =>
            this.setState({ showAddFamilyMember: false })
          }
        />

        <TelehealthVerificationConfirm
          telehealth={this.state.telehealth}
          confirmed={this.state.confirmed}
          confirming={this.state.confirming}
          dependents={this.props.dependents}
          parentPatient={this.props.currentUser}
          handleConfirm={this.handleConfirmPatient}
          handleShowAddFamilyMember={() =>
            this.setState({ showAddFamilyMember: true })
          }
        />
      </AppLayout>
    )
  }

  render() {
    if (this.state.loading && !this.state.telehealth) {
      return this.telehealthLoading()
    }

    if (!this.state.authenticated && !this.props.verifiedToken) {
      return this.verifyTelehealth()
    }

    if (!this.state.authenticated && this.props.verifiedToken) {
      return this.telehealthVerified()
    }

    if (this.state.authenticated && !this.props.verifiedToken) {
      return this.verifyTelehealth()
    }

    if (this.state.authenticated && this.props.verifiedToken) {
      return this.telehealthConfirmation()
    }
  }
}

function mapStateToProps(state) {
  return {
    dependents: state.user.dependents || [],
    currentUser: state.user.currentUser,
    verifiedToken: state.authentication.verifiedToken
  }
}
export default connect(mapStateToProps)(withTranslation()(TelehealthDeepLink))
