export function isValidPassword(password) {
  const newPasswordRequirements = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/

  return newPasswordRequirements.test(password)
}

export function isValidEmail(email) {
  const emailRequirements = /\S+@\S+\.\S+/
  return emailRequirements.test(email)
}

export function isValidPhone(phone) {
  const phoneRequirements = /^\(?\d{3}\)?-?\d{3}-?\d{4}$/
  return phoneRequirements.test(phone)
}

export function isValidURL(url) {
  const pattern = new RegExp(
    '^https?:\\/\\/' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ) // fragment locator

  return !!pattern.test(url)
}
