import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faVideo, faVideoSlash } from '@fortawesome/free-solid-svg-icons'

class StopCameraButton extends Component {
  constructor(props) {
    super(props)

    this.state = {
      video: true
    }
  }

  toggleVideo() {
    const videoState = !this.state.video

    this.setState({
      video: videoState
    })

    this.props.handleStopVideo(videoState)
  }

  render() {
    const btnClass = this.state.video ? 'teleVideoBtn on' : 'teleVideoBtn'

    return (
      <button
        id='stopCamera'
        onClick={(e) => {
          e.preventDefault()
          this.toggleVideo()
        }}
        type='button'
        className={btnClass}
      >
        <FontAwesomeIcon icon={this.state.video ? faVideo : faVideoSlash} />
      </button>
    )
  }
}

export default StopCameraButton
