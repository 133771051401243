import React, { useEffect, useState } from 'react'
import moment from 'moment'
import UAParser from 'ua-parser-js'
import { useTranslation } from 'react-i18next'
import NumberFormat from 'react-number-format'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Col, Image, Modal, Row } from 'react-bootstrap'

import { authenticationActions } from '../../_actions/authentication.actions'
import { alertActions } from '../../_actions/alert.actions'

import Logo from '../../_assets/logo.png'
import { userService } from '../../_services/user.service'

import { data } from './data'

function getDeepLinks(module) {
  if (!module) {
    module = 'DOWNLOAD'
  }

  const environment =
    process.env.REACT_APP_ENVIRONMENT === 'production' ? 'production' : 'shared'

  const mobileDeepLink = data[environment][module].mobile
  const imageDeepLink = data[environment][module].image

  return { mobileDeepLink, imageDeepLink }
}

export default function PreferredPlatformModal() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const parser = new UAParser()

  const phoneNumberOnFile = useSelector(
    (state) => state.user.currentUser.phoneNumber
  )
  const module = useSelector(
    (state) => state.authentication.preferredPlatformModule
  )

  const lastTime = useSelector(
    (state) => state.authentication.preferredTimestamp
  )

  const [displayModal, setDisplay] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState('')
  const [invalid, setInvalid] = useState(false)
  const [serverError, setServerError] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setPhoneNumber(phoneNumberOnFile)
  }, [phoneNumberOnFile])

  useEffect(() => {
    setDisplay(
      module !== false &&
        (lastTime === false || moment().diff(moment(lastTime), 'days') >= 30)
    )
  })

  const handleSendLink = () => {
    setInvalid(false)
    setServerError(false)
    setLoading(true)

    if (phoneNumber.length < 10) {
      setInvalid(true)
    } else {
      userService
        .sendDownloadAppLink(phoneNumber, module)
        .then(() => {
          dispatch(authenticationActions.setPreferredPlatform('app'))
          dispatch(
            alertActions.success(
              t('You should receive the download link momentarily.')
            )
          )
          setDisplay(false)
        })
        .catch(() => {
          setServerError(true)
        })
        .then(() => {
          setLoading(false)
        })
    }
  }

  const { mobileDeepLink, imageDeepLink } = getDeepLinks(module)

  return (
    <Modal
      show={displayModal}
      onHide={() => {
        dispatch(authenticationActions.setPreferredPlatform('web'))
        setDisplay(false)
      }}
      keyboard={false}
      className='text-center'
      centered
    >
      <Modal.Body className='px-3 py-4'>
        <Row>
          <Col>
            <Image src={Logo} width={100} />
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <h5 className='font-weight-normal'>
              {t('Take advantage of app-only benefits')}
            </h5>
          </Col>
        </Row>
        <br />
        <Row className='text-left'>
          <Col>
            <ul>
              <li className='mb-3'>
                {t('Stay informed with app notifications')}
              </li>
              <li>{t('Message your healthcare team from your home screen')}</li>
            </ul>
          </Col>
        </Row>
        {(parser.getDevice().type === 'mobile' ||
          parser.getDevice().type === 'tablet') && (
          <Row className='mt-2'>
            <Col>
              <Button
                block
                variant='success'
                className='p-2'
                onClick={() => {
                  dispatch(authenticationActions.setPreferredPlatform('app'))
                  setDisplay(false)
                }}
                target='_blank'
                href={mobileDeepLink}
              >
                {t('Download the AxS Health app')}
              </Button>
            </Col>
          </Row>
        )}
        {parser.getDevice().type !== 'mobile' &&
          parser.getDevice().type !== 'tablet' && (
            <Row className='mt-3'>
              <Col>
                <Row>
                  <Col>
                    <p className='font-weight-light'>
                      {t('Receive the app download link by text message!')}
                    </p>
                    <form className='mt-4'>
                      <div className='input-group justify-content-center'>
                        <NumberFormat
                          mask='_'
                          name='phone'
                          format='###-###-####'
                          placeholder='___-___-____'
                          className={`form-control ${
                            invalid ? 'is-invalid' : ''
                          }`}
                          value={phoneNumber}
                          onValueChange={(e) => {
                            setServerError(false)
                            setInvalid(e.value.length < 10)
                            setPhoneNumber(e.value)
                          }}
                        />
                        <div className='input-group-append'>
                          <button
                            onClick={handleSendLink}
                            type='button'
                            className={`btn ${
                              invalid ? 'btn-danger' : 'btn-success'
                            }`}
                            disabled={invalid || loading}
                          >
                            {loading ? (
                              <i className='fa fa-spinner fa-spin fa-fw' />
                            ) : (
                              t('Send')
                            )}
                          </button>
                        </div>
                      </div>
                    </form>
                  </Col>
                  <Col>
                    <p className='font-weight-light'>
                      {t('Scan the QR Code using your mobile device!')}
                    </p>
                    <Image src={imageDeepLink} width={150} />
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
        {serverError && (
          <p className='text-danger mt-2'>
            {t('An error occurred. Please try again.')}
          </p>
        )}
        <Row className='text-muted my-3'>
          <Col className='pl-4'>
            <hr />
          </Col>
          <Col xs='auto' style={{ opacity: '.25' }}>
            {t('or')}
          </Col>
          <Col className='pr-4'>
            <hr />
          </Col>
        </Row>
        <Row>
          <Col>
            <Button
              block
              variant='default'
              className='p-2 text-secondary'
              onClick={() => {
                dispatch(authenticationActions.setPreferredPlatform('web'))
                setDisplay(false)
              }}
            >
              {t('Continue on web browser')}
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  )
}
