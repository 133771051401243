import { formConstants as constants } from '../_constants/form.constants'
import handleValueType from '../_helpers/handleValueType'

export const initialState = {
  answers: [],
  isSubmitting: false,
  current: {
    resourceType: 'QuestionnaireResponse',
    status: 'in-progress',
    extension: [],
    item: []
  }
}

export function form(state = initialState, action) {
  if (action.type === constants.FORM_COMPLETE_FAILURE) {
    return {
      ...state,
      isSubmitting: false
    }
  }

  if (action.type === constants.FORM_COMPLETE_SUCCESS) {
    return {
      ...state,
      answers: [],
      isSubmitting: false,
      current: {
        resourceType: 'QuestionnaireResponse',
        status: 'in-progress',
        extension: [],
        item: []
      }
    }
  }

  if (action.type === constants.FORM_COMPLETE_REQUEST) {
    return {
      ...state,
      isSubmitting: true
    }
  }

  if (action.type === constants.SET_ANSWERS) {
    return {
      ...state,
      answers: state.answers.map((answer) => {
        if (answer.linkId === action.linkId) {
          return {
            ...answer,
            valid: action.state
          }
        }

        return answer
      })
    }
  }

  if (action.type === constants.INIT_ANSWERS) {
    const answers = []
    const items = []

    action.questions.item.forEach((item) => {
      items.push({
        linkId: item.linkId,
        text: item.text,
        definition: item.definition || null,
        answer: [{ [handleValueType(item.type)]: null }]
      })

      if (item.required) {
        answers.push({ linkId: item.linkId, valid: false })
      }

      if (item.type === 'date') {
        answers.push({ linkId: item.linkId, valid: true })
      }
    })

    if (action.signatureRequired) {
      answers.push({ linkId: 'signature', valid: false })
    }

    return {
      ...state,
      answers,
      current: {
        resourceType: 'QuestionnaireResponse',
        status: 'in-progress',
        extension: [],
        item: items
      }
    }
  }

  if (action.type === constants.SET_ELECTRONIC_SIGNATURE) {
    return {
      ...state,
      current: {
        ...state.current,
        extension: [...state.current.extension, action.signature]
      }
    }
  }

  if (action.type === constants.REMOVE_ELECTRONIC_SIGNATURE) {
    return {
      ...state,
      current: {
        ...state.current,
        extension: state.current.extension.filter((extension) => {
          return (
            extension.url !==
            'http://hl7.org/fhir/StructureDefinition/questionnaireresponse-signature'
          )
        })
      }
    }
  }

  if (action.type === constants.INITIAL_STATE) {
    return initialState
  }

  if (action.type === constants.SET_ITEM) {
    const exists = state.current.item.find((item) => {
      return item.linkId === action.question.linkId
    })

    if (exists) {
      return {
        ...state,
        current: {
          ...state.current,
          item: state.current.item.map((item) => {
            if (item.linkId === action.question.linkId) {
              return {
                linkId: item.linkId,
                text: item.text,
                definition: item.definition || null,
                answer: action.answer
              }
            }

            return item
          })
        }
      }
    } else {
      const item = {
        linkId: action.question.linkId,
        text: action.question.text,
        definition: action.question.definition || null,
        answer: action.answer
      }

      return {
        ...state,
        current: {
          ...state.current,
          item: [...state.current.item, item]
        }
      }
    }
  }

  return state
}
