import { notificationConstants as constants } from '../_constants/notification.constants'

const initialState = {
  count: 0,
  notifications: []
}

export function notification(state = initialState, action) {
  if (action.type === constants.SET_ALL_NOTIFICATIONS) {
    return {
      ...state,
      count: action.notifications.count,
      notifications: action.notifications.notifications.data
    }
  }

  return state
}
