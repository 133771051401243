import React, { useState, useEffect } from 'react'
import { history } from '../../../_helpers/history'
import { useDispatch } from 'react-redux'
import { alertActions } from '../../../_actions/alert.actions'
import { formsService } from '../../../_services/forms.service'
import { useTranslation } from 'react-i18next'

import { Spinner, Breadcrumb, Row, Col } from 'react-bootstrap'

import {
  FormCardSubmitted,
  FormQuestionnaireView
} from '@axshealth/design-library'

export default function ViewForm() {
  const dispatch = useDispatch(null)
  const { t } = useTranslation()

  const [form, setForm] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const referrer = history.location.state && history.location.state.referrer

  useEffect(() => {
    if (history.location.state === undefined) {
      history.push('/forms')
      dispatch(
        alertActions.error(
          'An error occurred and we could not get your form at this time. Please try again.'
        )
      )
    } else {
      formsService
        .getAnswers(history.location.state.id)
        .then((form) => {
          setForm(form)
          setIsLoading(false)
        })
        .catch(() => {
          dispatch(
            alertActions.error(
              'Could not get your form at this time. Please try again.'
            )
          )
        })
    }
  }, [])

  const handleClick = (url) => {
    window.open(url, '_blank')
  }

  const routeTo = (route, state = false) => {
    state ? history.push(route, state) : history.push(route)
  }

  if (isLoading) {
    return (
      <Spinner animation='border' role='status'>
        <span className='sr-only'>{t('Loading')}...</span>
      </Spinner>
    )
  }

  return (
    <div className='row'>
      <div className='col-12'>
        <Row>
          <Col xs='12' lg='3'>
            <div className='d-none d-lg-block'>
              <FormCardSubmitted form={form} />
            </div>
          </Col>
          <Col xs='12' lg='9' className='mb-2 mb-sm-0'>
            <Breadcrumb>
              <Breadcrumb.Item onClick={() => routeTo('/home')}>
                {t('Home')}
              </Breadcrumb.Item>
              {!referrer && (
                <Breadcrumb.Item onClick={() => routeTo('/forms')}>
                  {t('Forms')}
                </Breadcrumb.Item>
              )}
              {referrer && (
                <Breadcrumb.Item
                  onClick={() => routeTo(referrer.path, { id: referrer.id })}
                >
                  {t(referrer.pathName)}
                </Breadcrumb.Item>
              )}
              <Breadcrumb.Item active>{t('Details')}</Breadcrumb.Item>
            </Breadcrumb>

            <div className='d-lg-none d-block my-3'>
              <FormCardSubmitted form={form} />
            </div>

            <FormQuestionnaireView form={form} handleClick={handleClick} />
          </Col>
        </Row>
      </div>
    </div>
  )
}
