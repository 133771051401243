import React, { Fragment, useState, useEffect } from 'react'
import MessageSidebar from '../../Messages/Stream/Components/MessageSidebar'
import AddFamilyMember from '../../FamilyMembers/Components/AddFamilyMember'
import useWindowDimensions from '../../../_helpers/dimensions'

import { history } from '../../../_helpers/history'
import { formsService } from '../../../_services/forms.service'
import { alertActions } from '../../../_actions/alert.actions'
import { doctorService } from '../../../_services/doctor.service'
import { useTranslation } from 'react-i18next'
import { formRequestCompleted } from '../../../_actions/form.actions'
import { Link, Prompt, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import {
  Row,
  Col,
  Card,
  Spinner,
  Breadcrumb,
  Alert as AlertBS
} from 'react-bootstrap'

import {
  PatientConfirmList,
  FormQuestionnaireComplete,
  AddFamilyMember as AddFamilyMemberBtn
} from '@axshealth/design-library'

export default function RequestPage() {
  const dispatch = useDispatch(null)
  const { t } = useTranslation()
  const { id, domain } = useParams()
  const { width, breakPointMD } = useWindowDimensions()

  const [form, setForm] = useState(false)
  const [doctor, setDoctor] = useState('')
  const [selected, setSelected] = useState(false)
  const [leavePrompt, setLeavePrompt] = useState(true)
  const [showAddFamilyMember, setShowAddFamilyMember] = useState(false)

  const dependents = useSelector((state) => state.user.dependents || [])
  const currentUser = useSelector((state) => state.user.currentUser)
  const isSubmitting = useSelector((state) => state.form.isSubmitting)

  useEffect(() => {
    if (!id) {
      history.push('/forms')
      dispatch(
        alertActions.error(
          'An error occurred and we could not get your form questions at this time. Please try again.'
        )
      )
    } else {
      getDoctor()
      getFormQuestions()
    }
  }, [])

  const getFormQuestions = () => {
    window.scroll(0, 0)
    formsService
      .getRequest(id)
      .then((response) => {
        setForm({ questionnaire: response })
      })
      .catch((error) => {
        setLeavePrompt(false)
        console.error(error)
        dispatch(
          alertActions.error(
            'We could not retrieve your form questions at this time. Please try again later'
          )
        )
        history.push('/forms')
      })
  }

  const getDoctor = () => {
    doctorService
      .getByCode(domain)
      .then((response) => {
        setDoctor(response)
      })
      .catch((error) => {
        setLeavePrompt(false)
        console.error(error)
        dispatch(
          alertActions.error(
            'We could not retrieve your form questions at this time. Please try again later'
          )
        )
        history.push('/forms')
      })
  }

  const handleSubmitForm = (formQuestions) => {
    setLeavePrompt(false)
    dispatch(
      formRequestCompleted(id, formQuestions, selected.userProfileId, doctor.id)
    )
  }

  const handleClickDisplay = (url) => {
    window.open(url, '_blank')
  }

  return (
    <Fragment>
      {!form ? (
        <Spinner animation='border' />
      ) : (
        <Fragment>
          <Row>
            <Col>
              <Breadcrumb>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/home' }}>
                  {t('Home')}
                </Breadcrumb.Item>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/forms' }}>
                  {t('Forms')}
                </Breadcrumb.Item>
                <Breadcrumb.Item active>{t('Request')}</Breadcrumb.Item>
              </Breadcrumb>
            </Col>
          </Row>
          <Row style={{ zIndex: 9, position: 'relative' }}>
            <Col xs='12' lg='3'>
              <div className='mb-3'>
                {!doctor ? (
                  <Spinner animation='border' />
                ) : (
                  <MessageSidebar doctor={doctor} />
                )}
              </div>
            </Col>
            <Col xs='12' lg='9' className='mb-2 mb-sm-0'>
              {!selected ? (
                <Fragment>
                  <AlertBS variant='primary'>
                    {t(
                      'Select the family member that would like to complete this form'
                    )}
                    .
                  </AlertBS>

                  <AddFamilyMember
                    showAddFamilyMember={showAddFamilyMember}
                    hideAddFamilyMember={() => setShowAddFamilyMember(false)}
                  />

                  <Card className='telehealth__card'>
                    <Card.Body>
                      <PatientConfirmList
                        btnText='Select'
                        confirmed=''
                        confirming={false}
                        dependents={dependents}
                        parentPatient={currentUser}
                        handleConfirm={(userProfileId) => {
                          setSelected(
                            [currentUser, ...dependents].find(
                              (familyMember) =>
                                familyMember.userProfileId === userProfileId
                            )
                          )
                        }}
                      />
                    </Card.Body>
                  </Card>

                  <Row>
                    <Col xs='12' md='8'>
                      <AddFamilyMemberBtn
                        handleShowAddFamilyMember={() =>
                          setShowAddFamilyMember(true)
                        }
                      />
                    </Col>
                  </Row>
                </Fragment>
              ) : (
                <Row>
                  <Prompt
                    when={leavePrompt}
                    message={t(
                      'Are you sure you want to leave? Your answers will not be saved.'
                    )}
                  />

                  <Col xs={12} className='d-lg-none d-block'>
                    <AlertBS variant='primary'>
                      {t('Please review and complete the following')}
                    </AlertBS>
                  </Col>

                  <Col>
                    <Row className='d-lg-block d-none'>
                      <Col>
                        <AlertBS variant='primary'>
                          {t('Please review and complete the following')}
                        </AlertBS>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormQuestionnaireComplete
                          form={form}
                          isSubmitting={isSubmitting}
                          showSubmitInForm={width < breakPointMD}
                          handleSubmitForm={handleSubmitForm}
                          handleClickDisplay={handleClickDisplay}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        </Fragment>
      )}
    </Fragment>
  )
}
