import React from 'react'
import TextInput from '../../../../_components/Inputs/TextInput'
import GenderInput from '../../../../_components/Inputs/GenderInput'
import ActionButton from '../../../../_components/Buttons/ActionButton'
import DateOfBirthInput from '../../../../_components/Inputs/DateOfBirthInput'
import useWindowDimensions from '../../../../_helpers/dimensions'
import RenderProfilePicture from '../../../../_helpers/profile-picture'

import { useTranslation } from 'react-i18next'
import { handleNextInput } from '../../../../_helpers/handleEnter'
import { Col, Row, Card, Form, Spinner } from 'react-bootstrap'

export default function ProfileInfo({
  errors,
  gender,
  lastName,
  firstName,
  isInvalid,
  profileUrl,
  dateOfBirth,
  loadingUser,
  updatingImage,
  updateRequest,
  profileUpdated,
  updatingProfile,
  handleSetState,
  handleUpdateProfile,
  handleUpdateProfilePicture
}) {
  const { t } = useTranslation()
  const { width, breakPointMD } = useWindowDimensions()

  return (
    <Row className='mt-lg-4 pt-lg-2'>
      <Col xs='12'>
        <Card className='pb-1 border-bottom-0' style={{ borderRadius: 0 }}>
          <Card.Body>
            <Row>
              <Col xs='12'>
                <h4>{t('Edit Profile')}</h4>
              </Col>
              <Col>
                <input
                  id='ChangeProfileInput'
                  type='file'
                  accept='.png, .jpg, .jpeg'
                  onClick={(e) => {
                    e.target.value = null
                  }}
                  onChange={handleUpdateProfilePicture}
                  style={{
                    position: 'absolute',
                    opacity: 0,
                    zIndex: 99
                  }}
                />
              </Col>
            </Row>
            <br />

            <Row>
              <Col xs='auto' lg='2' className='text-center mb-5 mb-lg-0'>
                {(updatingImage || loadingUser) && (
                  <Spinner
                    animation='border'
                    style={{
                      position: 'absolute',
                      top: width < breakPointMD ? '2.2em' : '2.8em',
                      left: width < breakPointMD ? '3.3em' : '3.7em',
                      zIndex: 90
                    }}
                  />
                )}
                {(updatingImage || loadingUser) && (
                  <div style={{ opacity: updatingImage ? 0.5 : 1 }}>
                    <RenderProfilePicture
                      image={profileUrl}
                      circle
                      cached={false}
                      width={width < breakPointMD ? 100 : 120}
                      height={width < breakPointMD ? 100 : 120}
                      className='m-auto'
                    />
                  </div>
                )}
                {!updatingImage && !loadingUser && (
                  <Row>
                    <Col xs='12'>
                      <a
                        href='/'
                        onClick={(e) => {
                          e.preventDefault()
                          document.getElementById('ChangeProfileInput').click()
                        }}
                      >
                        <RenderProfilePicture
                          image={profileUrl}
                          circle
                          cached={false}
                          width={width < breakPointMD ? 100 : 120}
                          height={width < breakPointMD ? 100 : 120}
                          className='m-auto'
                        />
                        <div className='d-none d-lg-block mt-3'>
                          {t('Change Photo')}
                        </div>
                      </a>
                    </Col>
                  </Row>
                )}
              </Col>

              <Col xs='7' className='d-block d-lg-none mt-4'>
                {!updatingImage && !loadingUser && (
                  <Row className='mt-3'>
                    <Col xs='12'>
                      <a
                        href='/'
                        onClick={(e) => {
                          e.preventDefault()
                          document.getElementById('ChangeProfileInput').click()
                        }}
                      >
                        {t('Change Photo')}
                      </a>
                    </Col>
                  </Row>
                )}
              </Col>

              <Col xs='12' lg className='ml-lg-4'>
                <Form className='ml-lg-2'>
                  <Form.Group>
                    <Row>
                      <Col xs='12' lg='6'>
                        <Row className='mb-2'>
                          <Col
                            xs='12'
                            lg='4'
                            className='text-lg-right mt-ms-2 px-lg-0'
                          >
                            <Form.Label className={isInvalid('firstName')}>
                              {t('First Name')}
                            </Form.Label>
                          </Col>
                          <Col xs='12' lg='8'>
                            <TextInput
                              errors={errors}
                              value={firstName}
                              label={false}
                              setValue={(value) =>
                                handleSetState({ firstName: value.firstName })
                              }
                              valueName='firstName'
                              handleEnter={handleNextInput}
                            />
                          </Col>
                        </Row>

                        <Row className='mb-2'>
                          <Col
                            xs='12'
                            lg='4'
                            className='text-lg-right mt-lg-2 px-lg-0'
                          >
                            <Form.Label className={isInvalid('dateOfBirth')}>
                              {t('Date of Birth')}
                            </Form.Label>
                          </Col>
                          <Col xs='12' lg='8'>
                            <DateOfBirthInput
                              errors={errors}
                              showLabel={false}
                              dateOfBirth={dateOfBirth}
                              setDOB={(value) => {
                                handleSetState({
                                  dateOfBirth: value.dateOfBirth
                                })
                              }}
                            />
                          </Col>
                        </Row>

                        <Row className='d-none d-lg-block'>
                          <Col xs={{ span: 8, offset: 4 }}>
                            <ActionButton
                              action={
                                profileUpdated
                                  ? 'Profile Updated!'
                                  : updatingProfile || updatingImage
                                  ? `${t('Updating')}...`
                                  : 'Update Profile'
                              }
                              variant='outline-success'
                              submit={handleUpdateProfile}
                              disabled={
                                loadingUser ||
                                updateRequest ||
                                profileUpdated ||
                                updatingProfile
                              }
                              loading={updatingProfile && !updatingImage}
                            />
                          </Col>
                        </Row>
                      </Col>

                      <Col xs='12' lg='6'>
                        <Row className='mb-2'>
                          <Col
                            xs='12'
                            lg='4'
                            className='text-lg-right mt-lg-2 px-lg-0'
                          >
                            <Form.Label className={isInvalid('lastName')}>
                              {t('Last Name')}
                            </Form.Label>
                          </Col>
                          <Col xs='12' lg='8'>
                            <TextInput
                              errors={errors}
                              value={lastName}
                              label={false}
                              setValue={(value) =>
                                handleSetState({ lastName: value.lastName })
                              }
                              valueName='lastName'
                              handleEnter={handleNextInput}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col
                            xs='12'
                            lg='4'
                            className='text-lg-right mt-lg-2 px-lg-0'
                          >
                            <Form.Label className={isInvalid('gender')}>
                              {t('Gender')}
                            </Form.Label>
                          </Col>
                          <Col xs='12' lg='8' className='text-left'>
                            <GenderInput
                              errors={errors}
                              value={gender}
                              setValue={(value) =>
                                handleSetState({ gender: value.gender })
                              }
                              showTitle={false}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <Row className='d-lg-none d-block mt-3'>
                      <Col xs='12'>
                        <ActionButton
                          action={
                            profileUpdated
                              ? 'Profile Updated!'
                              : updatingProfile || updatingImage
                              ? `${t('Updating')}...`
                              : 'Update Profile'
                          }
                          variant='outline-success'
                          submit={handleUpdateProfile}
                          disabled={
                            loadingUser ||
                            updateRequest ||
                            profileUpdated ||
                            updatingProfile
                          }
                          loading={updatingProfile && !updatingImage}
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                </Form>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  )
}
