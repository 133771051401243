import { telehealthConstants as constants } from '../_constants/telehealth.constants'

export const initialState = {
  details: null,
  messages: [],
  isSubmitting: false,
  isRetrieving: false,
  retrievingMessages: false
}

export function telehealth(state = initialState, action) {
  if (action.type === constants.TELEHEALTH_CLEAR_STATE) {
    return {
      details: null,
      messages: [],
      isSubmitting: false,
      isRetrieving: false,
      retrievingMessages: false
    }
  }

  if (action.type === constants.TELEHEALTH_GET_SUCCESS) {
    return {
      ...state,
      details: action.telehealth,
      isRetrieving: false
    }
  }

  if (action.type === constants.TELEHEALTH_MESSAGE_ADD) {
    return {
      ...state,
      messages: [action.message, ...state.messages]
    }
  }

  if (action.type === constants.TELEHEALTH_GET_REQUEST) {
    return {
      ...state,
      isRetrieving: true
    }
  }

  if (action.type === constants.TELEHEALTH_GET_FAILURE) {
    return {
      ...state,
      isRetrieving: false
    }
  }

  if (action.type === constants.TELEHEALTH_MESSAGES_GET_SUCCESS) {
    return {
      ...state,
      messages: action.messages,
      retrievingMessages: false
    }
  }

  if (action.type === constants.TELEHEALTH_MESSAGES_GET_REQUEST) {
    return {
      ...state,
      retrievingMessages: true
    }
  }

  if (action.type === constants.TELEHEALTH_MESSAGES_GET_FAILURE) {
    return {
      ...state,
      retrievingMessages: false
    }
  }

  if (action.type === constants.QUESTIONS_COMPLETE_FAILURE) {
    return {
      ...state,
      isSubmitting: false
    }
  }

  if (action.type === constants.QUESTIONS_COMPLETE_SUCCESS) {
    return {
      ...state,
      isSubmitting: false
    }
  }

  if (action.type === constants.QUESTIONS_COMPLETE_REQUEST) {
    return {
      ...state,
      isSubmitting: true
    }
  }

  return state
}
