import React, { useState } from 'react'

import Terms from '../../../_components/Inputs/TermsInput'
import TextInput from '../../../_components/Inputs/TextInput'
import GenderInput from '../../../_components/Inputs/GenderInput'
import ActionButton from '../../../_components/Buttons/ActionButton'
import PasswordInput from '../../../_components/Inputs/PasswordInput'
import DateOfBirthInput from '../../../_components/Inputs/DateOfBirthInput'
import PasswordChecklist from '../../../_components/Validation/PasswordChecklist'

import { handleNextInput } from '../../../_helpers/handleEnter'
import RenderValidateMessage from '../../../_components/Validation/RenderValidateMessage'
import { isValidPassword } from '../../../_helpers/regex'

export default function VerifiedForm({
  values,
  errors,
  loading,
  handleChange,
  handleSubmit,
  handleTermsClick,
  handleSetUsername
}) {
  const [showPassword, setShowPassword] = useState(true)

  return (
    <form className='mt-4'>
      <div className='form-row'>
        <div className='col-12 col-md-6'>
          <TextInput
            errors={errors}
            value={values.firstName || ''}
            valueName='firstName'
            label='First Name'
            setValue={handleChange}
            handleEnter={handleNextInput}
          />
        </div>
        <div className='col-12 col-md-6'>
          <TextInput
            errors={errors}
            value={values.lastName || ''}
            valueName='lastName'
            label='Last Name'
            setValue={handleChange}
            handleEnter={handleNextInput}
          />
        </div>
      </div>

      <div className='form-row'>
        <div className='col-12 col-md-6'>
          <DateOfBirthInput
            errors={errors}
            setDOB={handleChange}
            dateOfBirth={values.dateOfBirth || ''}
            handleEnter={handleNextInput}
          />
        </div>
        <div className='col-12 col-md-6'>
          <GenderInput
            errors={errors}
            value={values.gender || ''}
            setValue={handleChange}
            handleEnter={handleNextInput}
          />
        </div>
      </div>

      <div className='form-row'>
        <div className='col-12 col-md-6'>
          <TextInput
            errors={errors}
            value={values.username || ''}
            valueName='username'
            label='Username'
            setValue={handleSetUsername}
            handleEnter={handleNextInput}
          />
        </div>
        <div className='col-12 col-md-6'>
          <PasswordInput
            errors={errors}
            valueName='password'
            label='Password'
            password={values.password || ''}
            setPassword={handleChange}
            showPassword={showPassword}
            setShowPassword={() => setShowPassword(!showPassword)}
            handleEnter={handleNextInput}
          />
        </div>
      </div>

      <div className='row'>
        <RenderValidateMessage errors={errors} field='verifiedToken' />

        <RenderValidateMessage errors={errors} field='verifiedType' />

        {values.password &&
          values.password.length > 0 &&
          !isValidPassword(values.password) && (
            <div className='col-12'>
              <PasswordChecklist password={values.password} />
            </div>
          )}

        <div className='col-12 mt-3'>
          <Terms
            errors={errors}
            terms={values.terms || false}
            setValue={handleChange}
            handleClick={handleTermsClick}
          />
        </div>
        <div className='col mt-4'>
          <ActionButton
            action='Create Account'
            variant='success'
            submit={handleSubmit}
            loading={loading}
          />
        </div>
      </div>
    </form>
  )
}
