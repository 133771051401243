function sidebarClickListener() {
  const sidePanel = document.getElementById('mySidepanel')
  if (sidePanel && sidePanel.style.left === '0em') {
    sidePanel.style.left = '-20em'
  }
}

export function addSidebarClickListener() {
  const htmlElement = document.getElementsByTagName('html')[0]

  if (htmlElement.getAttribute('listener') !== 'true') {
    htmlElement.addEventListener('click', sidebarClickListener)
    htmlElement.setAttribute('listener', 'true')
  }
}

export function removeSidebarClickListener() {
  const htmlElement = document.getElementsByTagName('html')[0]
  htmlElement.removeEventListener('click', sidebarClickListener)
  htmlElement.setAttribute('listener', 'false')
}
