import React from 'react'
import { setCookie } from '../../_helpers/cookieJar'
import { useTranslation } from 'react-i18next'
import { Modal, ListGroup } from 'react-bootstrap'

import * as momentTZ from 'moment-timezone'
import 'moment/locale/es'

export default function SelectLanguageModal(props) {
  const { t, i18n } = useTranslation()
  const { show, hide } = props

  const changeLanguage = (language) => {
    setCookie('language', language)
    i18n.changeLanguage(language)
    momentTZ.locale(language)
    props.hide()
  }

  return (
    <>
      <Modal
        show={show}
        onHide={hide}
        keyboard={false}
        className='text-center'
        centered
      >
        <Modal.Header closeButton className='pl-5'>
          <Modal.Title className='ml-auto'>
            {t('Select A Language')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ListGroup horizontal className='p-2'>
            <ListGroup.Item action onClick={() => changeLanguage('en')}>
              English
            </ListGroup.Item>
            <ListGroup.Item action onClick={() => changeLanguage('es')}>
              Español
            </ListGroup.Item>
          </ListGroup>
        </Modal.Body>
      </Modal>
    </>
  )
}
