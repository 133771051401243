import React, { Component } from 'react'
import PusherContext from '../../../_contexts/PusherContext'

import { connect } from 'react-redux'
import { telehealthActions } from '../../../_actions/telehealth.actions'
import { telehealthService as service } from '../../../_services/telehealth.service'

const withDataService = (WrappedComponent) => {
  class DataService extends Component {
    static contextType = PusherContext

    constructor(props) {
      super(props)

      this.state = {
        online: []
      }

      this.messagesInterval = false
      this.telehealthInterval = false

      this.addMessage = this.addMessage.bind(this)
      this.handleTyping = this.handleTyping.bind(this)
      this.handleMessage = this.handleMessage.bind(this)
      this.handleAttachment = this.handleAttachment.bind(this)
    }

    componentDidMount() {
      if (this.props.match.params && this.props.match.params.id) {
        this.loadTelehealth(this.props.match.params.id)
        this.loadMessages(this.props.match.params.id)
      }
    }

    componentDidUpdate(prevProps) {
      if (!prevProps.telehealth && this.props.telehealth) {
        if (this.context.connection.state === 'unavailable') {
          this.initiatePolling()
        }

        this.initiateSocket()
      }
    }

    componentWillUnmount() {
      this.props.dispatch(telehealthActions.clearState())

      this.unSubcribeChannels()

      this.disablePolling()
    }

    initiateSocket() {
      this.messageChannel = this.context.subscribe(
        `presence-telehealth_msg.${this.props.telehealth.id}`
      )

      this.messageChannel
        .bind('pusher:subscription_succeeded', (data) => {
          this.setState({
            online: Object.keys(data.members)
              .filter((memberId) => {
                return (
                  parseInt(memberId) !== parseInt(this.props.currentUser.userId)
                )
              })
              .map((key) => {
                return {
                  ...data.members[key],
                  typing: false
                }
              })
          })
        })
        .bind('pusher:member_added', (data) => {
          this.setState({
            online: [...this.state.online, { ...data.info, typing: false }]
          })
        })
        .bind('pusher:member_removed', (data) => {
          this.setState({
            online: this.state.online.filter((online) => {
              return online.userId !== data.id
            })
          })
        })
        .bind('client-typing', (data) => {
          this.setState({
            online: this.state.online.map((online) => {
              return {
                ...online,
                typing:
                  online.userId === data.userId ? data.typing : online.typing
              }
            })
          })
        })
        .bind('messageCreated', (message) => {
          this.props.dispatch(telehealthActions.prependMessage(message))
        })

      this.roomChannel = this.context.subscribe(
        `presence-telehealth_room.${this.props.telehealth.id}`
      )

      this.roomChannel.bind('telehealthPatientUpdated', (telehealth) => {
        this.props.dispatch(telehealthActions.setTelehealth(telehealth))
      })

      this.context.connection.bind('state_change', this.connectionChange, this)
    }

    connectionChange(state) {
      if (state.current === 'connected') {
        this.disablePolling()
      } else if (
        state.current === 'unavailable' ||
        state.current === 'failed'
      ) {
        this.initiatePolling()
      }
    }

    handleTyping(currentUser, typing) {
      this.messageChannel.trigger('client-typing', {
        typing: typing,
        userId: currentUser.userId,
        firstName: currentUser.firstName,
        lastName: currentUser.lastName,
        profileUrl: currentUser.profileUrlSizes
          ? currentUser.profileUrlSizes.sml
          : currentUser.profileUrl
      })
    }

    initiatePolling() {
      if (this.telehealthInterval === false) {
        this.loadTelehealth(this.props.telehealth.id)

        this.telehealthInterval = setInterval(() => {
          this.loadTelehealth(this.props.telehealth.id)
        }, 5000)
      }

      if (this.messagesInterval === false) {
        this.loadMessages(this.props.telehealth.id)

        this.messagesInterval = setInterval(() => {
          this.loadMessages(this.props.telehealth.id)
        }, 5000)
      }
    }

    disablePolling() {
      clearInterval(this.messagesInterval)
      clearInterval(this.telehealthInterval)
    }

    loadMessages(telehealthId) {
      this.props.dispatch(telehealthActions.getTelehealthMessages(telehealthId))
    }

    loadTelehealth(telehealthId) {
      this.props.dispatch(telehealthActions.getTelehealthById(telehealthId))
    }

    unSubcribeChannels() {
      if (this.messageChannel) {
        this.messageChannel.unbind()
        this.context.unsubscribe(
          `presence-telehealth_msg.${this.props.telehealth.id}`
        )
      }

      if (this.roomChannel) {
        this.roomChannel.unbind()
        this.context.unsubscribe(
          `presence-telehealth_room.${this.props.telehealth.id}`
        )
      }

      this.context.connection.unbind(
        'state_change',
        this.connectionChange,
        this
      )
    }

    handleMessage(message) {
      return service
        .sendMessage(
          this.props.telehealth.id,
          message,
          this.context.connection.socket_id
        )
        .then(this.addMessage)
    }

    handleAttachment(attachment) {
      return service
        .sendAttachment(
          this.props.telehealth.id,
          attachment,
          this.context.connection.socket_id
        )
        .then(this.addMessage)
    }

    addMessage(data) {
      const message = {
        sentBy: data.from,
        sentAt: data.sent_at,
        ...data
      }
      this.props.dispatch(telehealthActions.addTelehealthMessage(message))
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          online={this.state.online}
          isTyping={this.handleTyping}
          sendMessage={this.handleMessage}
          sendAttachment={this.handleAttachment}
        />
      )
    }
  }

  const mapStateToProps = (state) => {
    return {
      messages: state.telehealth.messages || [],
      telehealth: state.telehealth.details,
      currentUser: state.user.currentUser,
      retrievingMessages: state.telehealth.retrievingMessages
    }
  }

  return connect(mapStateToProps)(DataService)
}

export default withDataService
