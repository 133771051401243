import React, { Component, Fragment } from 'react'
import moment from 'moment'
import status from 'http-status'
import LoginInfo from './Components/LoginInfo'
import ProfileInfo from './Components/ProfileInfo'
import ContactInfo from './Components/ContactInfo'

import { connect } from 'react-redux'
import { Col, Row } from 'react-bootstrap'
import { userActions } from '../../../_actions/user.actions'
import { userService } from '../../../_services/user.service'
import { alertActions } from '../../../_actions/alert.actions'
import { withTranslation } from 'react-i18next'

import {
  isValidEmail,
  isValidPhone,
  isValidPassword
} from '../../../_helpers/regex'

class EditProfile extends Component {
  constructor(props) {
    super(props)

    this.state = {
      errors: {},
      email: this.props.currentUser.email,
      gender:
        this.props.currentUser.sex &&
        this.props.currentUser.sex[0].toLowerCase(),
      lastName: this.props.currentUser.lastName,
      firstName: this.props.currentUser.firstName,
      profileUrl: this.props.currentUser.profileUrl,
      dateOfBirth: moment(this.props.currentUser.dateOfBirth).format(
        'MM/DD/YYYY'
      ),
      username: this.props.currentUser.username,
      phoneNumber: this.props.currentUser.phoneNumber,
      newPassword: '',
      currentStep: 'SEND_VERIFY_CODE',
      loadingUser: true,
      loginUpdated: false,
      updateRequest: false,
      updatingPhone: false,
      updatingEmail: false,
      updatingImage: false,
      updatingLogin: false,
      profileUpdated: false,
      contactUpdated: false,
      updatingContact: false,
      updatingProfile: false,
      sendingVerifyCode: false,
      verificationCode: '',
      currentPassword: '',
      confirmPassword: ''
    }

    this.setInitState = this.setInitState.bind(this)
    this.handleIsInvalid = this.handleIsInvalid.bind(this)
    this.handleUpdateProfile = this.handleUpdateProfile.bind(this)
    this.handleSendVerifyCode = this.handleSendVerifyCode.bind(this)
    this.handleUpdateLoginInfo = this.handleUpdateLoginInfo.bind(this)
    this.handleUpdateContactInfo = this.handleUpdateContactInfo.bind(this)
    this.handleUpdateProfilePicture = this.handleUpdateProfilePicture.bind(this)
  }

  componentDidMount() {
    if (Object.keys(this.props.currentUser).length === 0) {
      userActions.storeUser()
    } else {
      this.setState({ loadingUser: false })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (Object.keys(prevProps.currentUser).length === 0) {
      this.setInitState()
    }
    if (
      (prevState.updatingEmail || prevState.updatingPhone) &&
      !this.state.updatingEmail &&
      !this.state.updatingPhone
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ verificationCode: '', currentStep: 'SEND_VERIFY_CODE' })
    }
  }

  setInitState() {
    this.setState({
      errors: {},
      email: this.props.currentUser.email,
      gender:
        this.props.currentUser.sex &&
        this.props.currentUser.sex[0].toLowerCase(),
      lastName: this.props.currentUser.lastName,
      firstName: this.props.currentUser.firstName,
      profileUrl: this.props.currentUser.profileUrl,
      dateOfBirth: moment(this.props.currentUser.dateOfBirth).format(
        'MM/DD/YYYY'
      ),
      username: this.props.currentUser.username,
      phoneNumber: this.props.currentUser.phoneNumber,
      newPassword: '',
      currentStep: 'SEND_VERIFY_CODE',
      loadingUser: false,
      loginUpdated: false,
      updateRequest: false,
      updatingPhone: false,
      updatingEmail: false,
      updatingImage: false,
      updatingLogin: false,
      profileUpdated: false,
      contactUpdated: false,
      updatingContact: false,
      updatingProfile: false,
      sendingVerifyCode: false,
      verificationCode: '',
      currentPassword: '',
      confirmPassword: ''
    })
  }

  async handleUpdateProfilePicture(e) {
    this.setState({ updateRequest: true, updatingImage: true })

    const file = e.target.files[0]
    const base64 = await new Promise((resolve, reject) => {
      // eslint-disable-next-line no-undef
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result.split(',')[1])
      reader.onerror = (error) => reject(error)
    })
    userService
      .uploadProfilePicture(base64)
      .then(() => {
        this.setState({
          profileUpdated: true,
          updateRequest: false,
          updatingImage: false
        })
        this.props.dispatch(alertActions.success('Profile photo updated!'))
        setTimeout(() => {
          window.location.reload()
        }, 1200)
      })
      .catch((error) => {
        console.log(error)
        this.props.dispatch(
          alertActions.error(
            'An error occured and we could not update your profile photo at this time.'
          )
        )
        this.setState({ updateRequest: false, updatingImage: false })
      })
  }

  handleUpdateProfile() {
    this.setState({ updateRequest: true, updatingProfile: true })
    const { firstName, lastName, gender, dateOfBirth } = this.state

    const errors = {}

    if (firstName.length === 0) {
      errors.firstName = 'First name is required.'
    }
    if (lastName.length === 0) {
      errors.lastName = 'Last name is required.'
    }
    if (gender.length === 0) {
      errors.gender = 'Gender is required.'
    }
    if (dateOfBirth.length === 0) {
      errors.dateOfBirth = 'Date of Birth is required.'
    } else if (dateOfBirth.replace(/\D/g, '').length !== 8) {
      errors.dateOfBirth = 'Invalid format ex. 03/15/1980.'
    } else if (!moment(dateOfBirth, 'MM/DD/YYYY').isValid()) {
      errors.dateOfBirth = 'Date of Birth is not valid.'
    }
    this.setState({ errors: errors })

    if (
      !errors.firstName &&
      !errors.lastName &&
      !errors.gender &&
      !errors.dateOfBirth
    ) {
      const updated = {
        firstName: firstName,
        lastName: lastName,
        gender: gender,
        dateOfBirth: moment(dateOfBirth).format('YYYY-MM-DD')
      }
      this.setState({ updateRequest: true })
      userService
        .updateUserProfile(updated)
        .then(() => {
          this.props.dispatch(userActions.storeUser())
          this.setState({
            profileUpdated: true,
            updatingProfile: false,
            loadingUser: false
          })
          setTimeout(() => {
            this.setState({ profileUpdated: false, updateRequest: false })
          }, 2000)
        })
        .catch((error) => {
          this.setState({ updateRequest: false, updatingProfile: false })
          if (error.response.status === status.UNPROCESSABLE_ENTITY) {
            this.setState({ errors: error.response.data })
          } else {
            this.props.dispatch(
              alertActions.error(
                'An error occured and we could not update your profile at this time.'
              )
            )
          }
          console.log(error.response)
        })
    } else {
      this.setState({ updateRequest: false, updatingProfile: false })
    }
  }

  handleUpdateLoginInfo() {
    const errors = {}
    const { currentPassword, newPassword, confirmPassword, username } =
      this.state

    if (
      currentPassword.length !== 0 ||
      newPassword.length !== 0 ||
      confirmPassword.length !== 0
    ) {
      if (currentPassword.length === 0) {
        errors.currentPassword = 'Current password field is required.'
      }
      if (confirmPassword.length === 0) {
        errors.confirmPassword = 'Confirm password field is required.'
      }
      if (newPassword.length === 0) {
        errors.newPassword = 'New password field is required.'
      } else if (newPassword !== confirmPassword) {
        errors.newPassword = 'Passwords must match.'
        errors.confirmPassword = 'Passwords must match.'
      } else if (isValidPassword(newPassword) === false) {
        errors.newPassword = 'Password is invalid.'
      }
    }
    this.setState({ errors: errors })

    if (
      !errors.username &&
      !errors.newPassword &&
      !errors.currentPassword &&
      !errors.confirmPassword
    ) {
      const updated = {
        username: username,
        newPassword: newPassword,
        currentPassword: currentPassword,
        confirmPassword: confirmPassword
      }
      this.setState({ updateRequest: true, updatingLogin: true })
      userService
        .updateUserLoginInfo(updated)
        .then(() => {
          this.props.dispatch(userActions.storeUser())
          this.setState({
            updatingLogin: false,
            loginUpdated: true,
            newPassword: '',
            currentPassword: '',
            confirmPassword: ''
          })
          setTimeout(() => {
            this.setState({
              updateRequest: false,
              loginUpdated: false
            })
          }, 2000)
        })
        .catch((error) => {
          this.setState({ updateRequest: false, updatingLogin: false })
          if (error.response.status === status.UNPROCESSABLE_ENTITY) {
            this.setState({ errors: error.response.data })
          } else {
            this.props.dispatch(
              alertActions.error(
                'An error occured and we could not update your login info at this time.'
              )
            )
          }
          console.log(error.response)
        })
    } else this.setState({ updateRequest: false, updatingLogin: false })
  }

  handleSendVerifyCode() {
    const errors = {}
    const { email, phoneNumber, updatingPhone } = this.state

    if (email && !isValidEmail(email)) {
      errors.email = 'Email is invalid.'
    }

    if (phoneNumber && !isValidPhone(phoneNumber)) {
      errors.phoneNumber = 'Phone Number is invalid.'
    }
    this.setState({ errors: errors })

    if (!errors.email && !errors.phoneNumber) {
      const updated = {
        type: updatingPhone ? 'sms' : 'email',
        email: email,
        phoneNumber: phoneNumber
      }
      this.setState({ updateRequest: true, sendingVerifyCode: true })
      userService
        .getVerifyCodeContactInfo(updated)
        .then(() => {
          this.setState({
            updateRequest: false,
            sendingVerifyCode: false,
            currentStep: 'ENTER_VERIFY_CODE'
          })
        })
        .catch((error) => {
          this.setState({ updateRequest: false, sendingVerifyCode: false })
          if (error.response.status === status.UNPROCESSABLE_ENTITY) {
            this.setState({ errors: error.response.data })
          } else {
            this.props.dispatch(
              alertActions.error(
                'An error occured and we could not send your verification code at this time.'
              )
            )
          }
          console.log(error.response)
        })
    } else this.setState({ updateRequest: false, sendingVerifyCode: false })
  }

  handleUpdateContactInfo() {
    const errors = {}
    const { email, phoneNumber, updatingPhone, verificationCode } = this.state

    if (email && !isValidEmail(email)) {
      errors.email = 'Email is invalid.'
    }

    if (phoneNumber && !isValidPhone(phoneNumber)) {
      errors.phoneNumber = 'Phone Number is invalid.'
    }
    this.setState({ errors: errors })

    if (!errors.email && !errors.phoneNumber) {
      const verifyInfo = {
        via: updatingPhone ? phoneNumber : email,
        verificationCode: verificationCode.toString()
      }
      this.setState({ updateRequest: true, updatingContact: true })
      userService
        .updateUserContactInfo(verifyInfo)
        .then(() => {
          this.props.dispatch(userActions.storeUser())
          this.setState({
            updatingContact: false,
            contactUpdated: true
          })
          setTimeout(() => {
            this.setState(
              {
                updatingContact: false,
                contactUpdated: false,
                updatingPhone: false,
                updatingEmail: false
              },
              () => {
                this.setInitState()
              }
            )
          }, 2000)
        })
        .catch((error) => {
          this.setState({ updateRequest: false, updatingContact: false })
          if (error.response.status === status.UNPROCESSABLE_ENTITY) {
            this.setState({ errors: error.response.data })
          } else {
            this.props.dispatch(
              alertActions.error(
                'An error occured and we could not update your contact info at this time.'
              )
            )
          }
          console.log(error.response)
        })
    } else this.setState({ updateRequest: false, updatingContact: false })
  }

  handleIsInvalid(valueName) {
    return valueName in this.state.errors ? 'text-danger' : ''
  }

  render() {
    return (
      <Fragment>
        <Row>
          <Col xs='12'>
            <ProfileInfo
              errors={this.state.errors}
              gender={this.state.gender}
              lastName={this.state.lastName}
              firstName={this.state.firstName}
              profileUrl={this.state.profileUrl}
              dateOfBirth={this.state.dateOfBirth}
              loadingUser={this.state.loadingUser}
              updatingImage={this.state.updatingImage}
              updateRequest={this.state.updateRequest}
              profileUpdated={this.state.profileUpdated}
              updatingProfile={this.state.updatingProfile}
              isInvalid={this.handleIsInvalid}
              handleSetState={(value) => this.setState(value)}
              handleUpdateProfile={this.handleUpdateProfile}
              handleUpdateProfilePicture={this.handleUpdateProfilePicture}
            />
          </Col>
        </Row>
        <Row>
          <Col xs='12'>
            <LoginInfo
              errors={this.state.errors}
              username={this.state.username}
              newPassword={this.state.newPassword}
              loadingUser={this.state.loadingUser}
              loginUpdated={this.state.loginUpdated}
              updatingLogin={this.state.updatingLogin}
              updateRequest={this.state.updateRequest}
              currentPassword={this.state.currentPassword}
              confirmPassword={this.state.confirmPassword}
              isInvalid={this.handleIsInvalid}
              handleSetState={(value) => this.setState(value)}
              handleUpdateLoginInfo={this.handleUpdateLoginInfo}
            />
          </Col>
        </Row>
        <Row>
          <Col xs='12'>
            <ContactInfo
              email={this.state.email}
              errors={this.state.errors}
              initPhone={this.props.currentUser.phoneNumber}
              initEmail={this.props.currentUser.email}
              currentStep={this.state.currentStep}
              loadingUser={this.state.loadingUser}
              phoneNumber={this.state.phoneNumber}
              updatingPhone={this.state.updatingPhone}
              updatingEmail={this.state.updatingEmail}
              updateRequest={this.state.updateRequest}
              contactUpdated={this.state.contactUpdated}
              updatingContact={this.state.updatingContact}
              verificationCode={this.state.verificationCode}
              sendingVerifyCode={this.state.sendingVerifyCode}
              isInvalid={this.handleIsInvalid}
              handleSetState={(value) => this.setState(value)}
              handleSendVerifyCode={this.handleSendVerifyCode}
              handleUpdateContactInfo={this.handleUpdateContactInfo}
            />
          </Col>
        </Row>
      </Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.user.currentUser
  }
}

export default connect(mapStateToProps)(withTranslation()(EditProfile))
