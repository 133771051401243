import React, { Fragment, useState, useEffect } from 'react'
import Alert from '../../../_components/Alerts/Alert'
import useWindowDimensions from '../../../_helpers/dimensions'
import ChangeFamilyMemberModal from '../../FamilyMembers/Components/ChangeFamilyMember'

import { Prompt } from 'react-router-dom'
import { history } from '../../../_helpers/history'
import { formsService } from '../../../_services/forms.service'
import { alertActions } from '../../../_actions/alert.actions'
import { formCompleted } from '../../../_actions/form.actions'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Spinner, Alert as AlertBS, Row, Col } from 'react-bootstrap'

import {
  FormQuestionnaireComplete,
  FormButtonChangeFamilyMember
} from '@axshealth/design-library'

export default function QuestionsPage() {
  const dispatch = useDispatch(null)
  const { t } = useTranslation()
  const { width, breakPointMD } = useWindowDimensions()

  const [form, setForm] = useState(false)
  const [changing, setChanging] = useState(false)
  const [leavePrompt, setLeavePrompt] = useState(true)
  const [alertDetails, setAlertDetails] = useState({})
  const [showChangeModal, setShowChangeModal] = useState(false)

  const id = history.location.state && history.location.state.id
  const referrer = history.location.state && history.location.state.referrer

  const dependents = useSelector((state) => state.user.dependents || [])
  const isSubmitting = useSelector((state) => state.form.isSubmitting)
  const currentUser = useSelector((state) => state.user.currentUser)

  useEffect(() => {
    if (!id) {
      history.push('/forms')
      dispatch(
        alertActions.error(
          'An error occurred and we could not get your form questions at this time. Please try again.'
        )
      )
    } else {
      getFormQuestions()
    }
  }, [])

  const getFormQuestions = () => {
    window.scroll(0, 0)
    formsService
      .getQuestions(id)
      .then((response) => {
        setForm(response)
      })
      .catch((error) => {
        setLeavePrompt(false)
        console.error(error)
        dispatch(
          alertActions.error(
            'We could not retrieve your form questions at this time. Please try again later'
          )
        )
        history.push('/forms')
      })
  }

  const toggleChangeFamilyMemberModal = () => {
    setShowChangeModal(!showChangeModal)
  }

  const handleChangeFamilyMember = (userProfileId) => {
    setAlertDetails({})
    setChanging(true)

    formsService
      .updatePatient(id, userProfileId)
      .then(() => {
        getFormQuestions()
        setShowChangeModal(false)
        setChanging(false)
        setAlertDetails({ type: 'success', message: 'Family member changed.' })
      })
      .catch(() => {
        setChanging(false)
        setShowChangeModal(false)
        setAlertDetails({
          type: 'danger',
          message:
            'We could not confirm your selection at this time. Check your connection and try again.'
        })
      })
  }

  const handleSubmitForm = (formQuestions) => {
    setLeavePrompt(false)
    dispatch(formCompleted(id, formQuestions, referrer))
  }

  const handleClickDisplay = (url) => {
    window.open(url, '_blank')
  }

  const patients = [currentUser, ...dependents]

  return (
    <Fragment>
      {!form ? (
        <Spinner animation='border' />
      ) : (
        <Row>
          <Prompt
            when={leavePrompt}
            message={t(
              'Are you sure you want to leave? Your answers will not be saved.'
            )}
          />

          <ChangeFamilyMemberModal
            show={showChangeModal}
            changing={changing}
            patients={patients}
            selected={form.userProfile.id}
            handleClose={toggleChangeFamilyMemberModal}
            changePatient={handleChangeFamilyMember}
          />

          <Col lg='3' className='d-none d-lg-block'>
            <FormButtonChangeFamilyMember
              form={form}
              showChangeFamilyMember={form && !form.eventId}
              handleShowChangeFamilyMember={toggleChangeFamilyMemberModal}
            />
          </Col>

          <Col xs={12} className='d-lg-none d-block'>
            <Alert alert={alertDetails} dismissible />
            <AlertBS variant='primary'>
              {t('Please review and complete the following')}
            </AlertBS>
          </Col>

          <Col xs={12} className='d-lg-none d-block mb-3'>
            <FormButtonChangeFamilyMember
              form={form}
              showChangeFamilyMember={form && !form.eventId}
              handleShowChangeFamilyMember={toggleChangeFamilyMemberModal}
            />
          </Col>

          <Col>
            <Row className='d-lg-block d-none'>
              <Col>
                <Alert alert={alertDetails} dismissible />
                <AlertBS variant='primary'>
                  {t('Please review and complete the following')}
                </AlertBS>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormQuestionnaireComplete
                  form={form}
                  isSubmitting={isSubmitting}
                  showSubmitInForm={width < breakPointMD}
                  handleSubmitForm={handleSubmitForm}
                  handleClickDisplay={handleClickDisplay}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </Fragment>
  )
}
