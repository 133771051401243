import { loadingConstants as constants } from '../_constants/loading.constants'

const initialState = {
  loading: false,
  loadingKey: null
}

export function loading(state = initialState, action) {
  if (action.type === constants.TOGGLE_LOADING) {
    return {
      ...state,
      loading: !state.loading,
      loadingKey: action.key
    }
  }
  return state
}
