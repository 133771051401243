import { authenticationService } from '../_services/authentication.service'

export function authHeader() {
  const token = authenticationService.getToken()

  if (token) {
    return { Authorization: `Bearer ${token}` }
  } else {
    return {}
  }
}
