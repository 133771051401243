import React from 'react'
import Alert from '../Alerts/Alert'
import StepButton from '../Buttons/StepButton'
import ResendCode from '../Buttons/ResendCode'
import NumberInput from '../Inputs/NumberInput'
import { handleEnter } from '../../_helpers/handleEnter'

export default function VerifyCode(props) {
  const {
    code,
    alert = '',
    errors,
    loading,
    checkCode,
    resending,
    submitTitle,
    stepBackward,
    setParentState,
    titleComponent = null,
    mutedInfoComponent = null,
    resendVerifyCode
  } = props

  const resendCode = () => {
    setParentState({ resending: true })
    resendVerifyCode()
  }

  const stepBack = () => {
    setParentState({ code: '' })
    stepBackward()
  }

  return (
    <div className='text-center'>
      <p className='lead px-4 pt-3 pt-sm-0'>{titleComponent}</p>

      <Alert className='mx-4 mx-sm-5 pt-2 pb-0' alert={alert} />

      <div className='row'>
        <div className='col-10 offset-1'>
          <div className='row text-left'>
            <div className='col-sm-10 offset-sm-1 pt-sm-4'>
              <NumberInput
                label='Verification Code'
                errors={errors}
                value={code}
                handleEnter={(e) => (handleEnter(e) ? checkCode(e) : '')}
                valueName='code'
                setValue={setParentState}
              />
              <p className='text-muted text-center'> {mutedInfoComponent} </p>
            </div>
          </div>
          <div className='row pb-3'>
            <div className='col'>
              <ResendCode resending={resending} resendCode={resendCode} />
            </div>
          </div>

          {props.children}

          <div className='pt-4'>
            <StepButton
              loading={loading}
              nextTitle={submitTitle}
              previousTitle='Back'
              nextAction={checkCode}
              previousAction={stepBack}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
