import React, { Component } from 'react'
import moment from 'moment'
import Online from '../../Messages/Stream/Components/Online'
import NewMsgImg from '../../../_assets/new-message.png'
import classNames from 'classnames'
import withDataService from './withDataService'
import AttachmentModal from '../../../_components/Messaging/AttachmentModal'
import SendMessageChatBox from '../../Messages/Stream/Components/SendMessageChatBox'

import { history } from '../../../_helpers/history'
import { alertActions } from '../../../_actions/alert.actions'
import { withTranslation } from 'react-i18next'
import { carePlanService } from '../../../_services/care_plan.service'
import { ConsentFullName } from '../../../_helpers/licensed-professional-name'
import { Spinner, Breadcrumb, Row, Col, Image } from 'react-bootstrap'

import {
  Message,
  MessageModalCarePlan,
  TelehealthCardEncounter,
  TelehealthModalBeforeAppointment
} from '@axshealth/design-library'

import './style.css'

class Encounter extends Component {
  constructor(props) {
    super(props)

    this.state = {
      message: '',
      messages: null,
      charCount: 0,
      initCount: 0,
      showAlert: true,
      isLoading: true,
      isSending: false,
      attachment: '',
      newMessage: false,
      showCharCount: false,
      currentCarePlan: false,
      showAddAttachment: false,
      showCarePlanModal: false,
      invalidAttachment: false,
      showBeforeAppointment: false
    }

    this.referrer = history.location.state && history.location.state.referrer

    this.showCarePlan = this.showCarePlan.bind(this)
    this.publishTyping = this.publishTyping.bind(this)
    this.handleMessage = this.handleMessage.bind(this)
    this.handleSetMessage = this.handleSetMessage.bind(this)
    this.handleAttachment = this.handleAttachment.bind(this)
    this.observeLastMessage = this.observeLastMessage.bind(this)
    this.hideAttachmentModal = this.hideAttachmentModal.bind(this)
    this.startTelehealthVideo = this.startTelehealthVideo.bind(this)
    this.handleScrollToBottom = this.handleScrollToBottom.bind(this)
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState(
        {
          messages: this.props.messages
        },
        () => {
          this.showHasNewMessage(prevProps)
        }
      )
    }
  }

  publishTyping(typing) {
    this.props.isTyping(this.props.currentUser, typing)
  }

  showHasNewMessage(prevProps) {
    if (prevProps.messages && this.props.messages) {
      const messagesLen = prevProps.messages.length
      const messagesPropLen = this.state.messages.length

      if (
        messagesLen !== messagesPropLen &&
        messagesLen !== 0 &&
        messagesPropLen !== 0
      ) {
        const lastMessage = this.state.messages[0]
        if (lastMessage.sentBy.id !== this.props.currentUser.userId) {
          this.observeLastMessage()
        } else {
          setTimeout(() => {
            this.handleScrollToBottom()
          }, 200)
        }
      }
    }
  }

  observeLastMessage() {
    // eslint-disable-next-line no-undef
    const observer = new IntersectionObserver(
      (entries, observer) => {
        const lastMessageIsVisible = entries[0].isIntersecting
        this.setState({ newMessage: !lastMessageIsVisible })

        if (lastMessageIsVisible) {
          observer.unobserve(entries[0].target)
          this.handleScrollToBottom()
        }
      },
      {
        root: document.getElementById('message-container'),
        rootMargin: '0px 0px 200px 0px'
      }
    )
    observer.observe(document.querySelector('#message-container > div'))
  }

  errorAndRoute() {
    history.push('/telehealth')
    this.props.dispatch(
      alertActions.error(
        'We could not retrieve your telehealth at this time. Please try again later.'
      )
    )
  }

  handleMessage() {
    this.setState({ isSending: true })

    this.props.dispatch(alertActions.clear())

    this.props
      .sendMessage(this.state.message)
      .then(() => {
        this.setState({ message: '' })
      })
      .catch(() => {
        this.props.dispatch(
          alertActions.error(
            'Could not send message at this time. Please try again.'
          )
        )
      })
      .then(() => {
        this.setState({ isSending: false })
      })
  }

  handleAttachment() {
    if (!this.state.attachment) {
      this.setState({ invalidAttachment: true })
    } else {
      this.setState({ isSending: true, invalidAttachment: false })
      this.props.dispatch(alertActions.clear())

      this.props
        .sendAttachment(this.state.attachment)
        .catch(() => {
          this.props.dispatch(
            alertActions.error(
              'Could not send file at this time. Please try again.'
            )
          )
        })
        .then(() => {
          this.setState({
            attachment: '',
            isSending: false,
            showAddAttachment: false
          })
        })
    }
  }

  handleSetMessage(e) {
    const message = e.target.value
    if (message.length <= 500) {
      const count = 500 - message.length

      if (count <= 50) {
        this.setState({ charCount: count, showCharCount: true })
      } else {
        this.setState({ showCharCount: false })
      }
      this.setState({ message })
    }
  }

  handleScrollToBottom() {
    const scrollTarget = document.getElementById('message-container')
    if (scrollTarget) {
      scrollTarget.scrollTo({
        top: scrollTarget.scrollHeight,
        behavior: 'smooth'
      })
    }
  }

  showCarePlan(payload) {
    carePlanService.getCarePlanById(payload.appointmentId).then((carePlan) => {
      this.setState({ currentCarePlan: carePlan[0], showCarePlanModal: true })
    })
  }

  hideAttachmentModal() {
    this.setState({ attachment: '', showAddAttachment: false })
  }

  startTelehealthVideo() {
    if (this.props.telehealth.status === 'COMPLETED') {
      this.props.dispatch(
        alertActions.info(
          this.props.t('Your telehealth appointment has ended.')
        )
      )

      return
    }

    history.push(`/telehealth/video`, { id: this.props.telehealth.id })
  }

  handleClick(url) {
    window.open(url, '_blank')
  }

  routeTo(route, state = false) {
    state ? history.push(route, state) : history.push(route)
  }

  render() {
    const { currentUser, telehealth } = this.props

    const {
      message,
      messages,
      charCount,
      isSending,
      attachment,
      newMessage,
      showCharCount,
      currentCarePlan,
      showAddAttachment,
      showCarePlanModal,
      invalidAttachment,
      showBeforeAppointment
    } = this.state

    const sendBtnStatus = classNames({
      'btn-success': message !== '',
      'btn-secondary': message === '',
      'no-click-events': isSending || message === ''
    })

    const newMessageBtn = classNames(
      'new-message-btn axs-rounded shadow bg-primary text-light',
      { 'new-message-btn-show': newMessage }
    )

    const messageChatBox = classNames({
      'd-none':
        telehealth &&
        (telehealth.status === 'COMPLETED' || telehealth.status === 'HOLD')
    })

    const messageContainer = classNames(
      'd-flex flex-column-reverse overflow-y-auto',
      { closed: telehealth && telehealth.status === 'COMPLETED' }
    )

    return (
      <>
        {!telehealth || messages === null ? (
          <Spinner animation='border' className='mt-3' />
        ) : (
          <>
            <TelehealthModalBeforeAppointment
              show={showBeforeAppointment}
              hide={() => this.setState({ showBeforeAppointment: false })}
              telehealth={telehealth}
              returnHome={() => history.push('/home')}
            />

            <Row className='d-none d-lg-block mt-3'>
              <Col>
                <Breadcrumb>
                  <Breadcrumb.Item onClick={() => this.routeTo('/home')}>
                    {this.props.t('Home')}
                  </Breadcrumb.Item>
                  {!this.referrer && (
                    <Breadcrumb.Item
                      onClick={() => this.routeTo('/telehealth')}
                    >
                      {this.props.t('Telehealth')}
                    </Breadcrumb.Item>
                  )}
                  {this.referrer && (
                    <Breadcrumb.Item
                      onClick={() =>
                        this.routeTo(this.referrer.path, {
                          id: this.referrer.id
                        })
                      }
                    >
                      {this.props.t(this.referrer.pathName)}
                    </Breadcrumb.Item>
                  )}
                  <Breadcrumb.Item active>
                    {this.props.t('Encounter')}
                  </Breadcrumb.Item>
                </Breadcrumb>
              </Col>
            </Row>

            <Row>
              <Col lg='3' className='d-none d-lg-block'>
                <TelehealthCardEncounter
                  mobile={false}
                  telehealth={telehealth}
                />
              </Col>

              <Col xs='12' lg='9' className='px-0 px-lg-3 mb-2 mb-sm-0'>
                <div id='message-card' className='card border-0 shadow-sm'>
                  {telehealth.settings.showOnline &&
                    this.props.online.length > 0 && (
                      <div id='user___online'>
                        {this.props.online.map((user) => {
                          return <Online key={user.userId} user={user} />
                        })}
                      </div>
                    )}
                  <div id='message-container' className={messageContainer}>
                    {messages && messages.length === 0 ? (
                      <>
                        {telehealth.status !== 'COMPLETED' && (
                          <div
                            className='d-flex flex-column h-100'
                            style={{ justifyContent: 'space-evenly' }}
                          >
                            <div className='media text-center'>
                              <div className='media-body'>
                                <h5 className='text-muted font-weight-normal text-center m-0'>
                                  {this.props.t('Telehealth Scheduled', {
                                    type:
                                      telehealth.telemedicineType === 'video'
                                        ? this.props.t('video-based')
                                        : this.props.t('message-based')
                                  })}
                                </h5>
                                <br />

                                <h6 className='text-muted font-weight-light text-center m-0'>
                                  {moment
                                    .tz(
                                      telehealth.appointmentDate.date,
                                      telehealth.appointmentDate.timezone
                                    )
                                    .local()
                                    .format('MMMM D, YYYY [@] h:mm A')}
                                </h6>
                              </div>
                            </div>

                            <div className='media'>
                              <div className='media-body text-center'>
                                <Image width={400} fluid src={NewMsgImg} />
                              </div>
                            </div>

                            <div className='media text-center'>
                              <div className='media-body'>
                                <p className='font-weight-light text-muted text-center m-0'>
                                  {telehealth &&
                                  telehealth.status === 'HOLD' ? (
                                    <span>
                                      {this.props.t(
                                        "You'll receive a notification when your appointment is ready"
                                      )}
                                    </span>
                                  ) : telehealth.telemedicineType ===
                                    'video' ? (
                                    this.props.t(
                                      'Video Telehealth Start Notify',
                                      {
                                        doctor: telehealth.doctor
                                          ? `${ConsentFullName(
                                              telehealth.doctor
                                            )}, ${telehealth.doctor.type}`
                                          : ''
                                      }
                                    )
                                  ) : (
                                    this.props.t(
                                      'Message Telehealth Start Notify',
                                      {
                                        doctor: telehealth.doctor
                                          ? `${ConsentFullName(
                                              telehealth.doctor
                                            )}, ${telehealth.doctor.type}`
                                          : ''
                                      }
                                    )
                                  )}
                                </p>
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    ) : (
                      messages &&
                      messages.length > 0 &&
                      messages.map((message, index) => {
                        return (
                          <Message
                            id={null}
                            key={message.id}
                            index={index}
                            message={message}
                            // readReceipt={handleReadReceipt(message, currentUser)}
                            // showReadReceipts
                            currentUser={currentUser.userId}
                            showCarePlan={this.showCarePlan}
                            handleClickLink={this.handleClick}
                            startTelehealthVideo={this.startTelehealthVideo}
                            handleClickAttachment={this.handleClick}
                          />
                        )
                      })
                    )}
                  </div>

                  <div id='new-message-btn-container'>
                    <div
                      onClick={this.handleScrollToBottom}
                      className={newMessageBtn}
                    >
                      <i className='fas fa-comment' />
                      &nbsp;{this.props.t('New Message')}
                    </div>
                  </div>

                  {telehealth && telehealth.status === 'COMPLETED' && (
                    <div id='closed-container'>
                      {telehealth.closedBy === undefined && (
                        <div className=' text-center text-muted'>
                          {this.props.t(
                            'Your telehealth appointment has ended.'
                          )}
                        </div>
                      )}

                      {telehealth &&
                        telehealth.status === 'COMPLETED' &&
                        telehealth.closedBy && (
                          <div className=' text-center text-muted'>
                            {this.props.t(
                              'Your telehealth appointment was ended by'
                            )}{' '}
                            {telehealth.closedBy.firstName}{' '}
                            {telehealth.closedBy.lastName}
                            &nbsp;on{' '}
                            {moment
                              .tz(
                                telehealth.closedAt.date,
                                telehealth.closedAt.timezone
                              )
                              .local()
                              .format('LL [at] LT')}
                          </div>
                        )}
                    </div>
                  )}

                  <div className={messageChatBox} style={{ padding: '0 15px' }}>
                    <div id='show-char-count'>
                      {showCharCount && (
                        <div>
                          &nbsp;{charCount}&nbsp;
                          {this.props.t('Characters Remaining')}
                        </div>
                      )}
                    </div>

                    <SendMessageChatBox
                      isTyping={this.publishTyping}
                      message={message}
                      isSending={isSending}
                      sendBtnStatus={sendBtnStatus}
                      handleShowFile={() =>
                        this.setState({ showAddAttachment: true })
                      }
                      handleSetMessage={this.handleSetMessage}
                      handleSendMessage={this.handleMessage}
                    />

                    <AttachmentModal
                      hideModal={this.hideAttachmentModal}
                      isSending={isSending}
                      attachment={attachment}
                      setAttachment={(attachment) =>
                        this.setState({ attachment })
                      }
                      sendAttachment={this.handleAttachment}
                      invalidAttachment={invalidAttachment}
                      showAddAttachment={showAddAttachment}
                    />

                    {currentCarePlan && (
                      <MessageModalCarePlan
                        show={showCarePlanModal}
                        hide={() => this.setState({ showCarePlanModal: false })}
                        carePlan={currentCarePlan}
                        handleClickAttachment={this.handleClick}
                      />
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </>
        )}
      </>
    )
  }
}
export default withTranslation()(withDataService(Encounter))
