import axios from 'axios'
import status from 'http-status'

import { history } from './history'
import { authenticationService } from '../_services/authentication.service'

// import getApiVersion from './api-version'
// import { authHeader } from './auth-header'

const instance = axios.create({
  // baseURL: `${process.env.REACT_APP_API_URL}/${getApiVersion()}`,
  // headers: authHeader()
})

instance.interceptors.request.use(function (request) {
  request.headers['X-Axs-Version'] = process.env.REACT_APP_API_VERSION
  request.headers['X-Axs-Platform'] = process.env.REACT_APP_PLATFORM

  return request
})

instance.interceptors.response.use(
  function (response) {
    return response // 2xx Request
  },
  function (error) {
    if (
      error.response &&
      error.response.status === status.UNAUTHORIZED &&
      error.config.url !==
        `${process.env.REACT_APP_API_URL}/intake/v1/authentication`
    ) {
      authenticationService.logout()

      history.push('/login?expired=1', { from: history.location.pathname })
    } else if (
      error.response &&
      error.response.status === status.UPGRADE_REQUIRED
    ) {
      window.location.reload()
    } else {
      return Promise.reject(error)
    }
  }
)

export default instance
