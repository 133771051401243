import React, { useEffect, useState, Fragment } from 'react'
import MainMenu from '../../../_components/MainMenu'

import { history } from '../../../_helpers/history'
import { faFileAlt } from '@fortawesome/free-regular-svg-icons'
import { useDispatch } from 'react-redux'
import { alertActions } from '../../../_actions/alert.actions'
import { formsService } from '../../../_services/forms.service'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Spinner, Alert, Col, Row } from 'react-bootstrap'

import {
  FormCardBase,
  FormCardEmpty,
  FormModalExpired,
  FormModalCanceled
} from '@axshealth/design-library'

export default function Forms() {
  const dispatch = useDispatch(null)
  const { t } = useTranslation()
  const [forms, setForms] = useState(null)
  const [doctor, setDoctor] = useState(null)
  const [loading, setLoading] = useState(true)
  const [showExpired, setShowExpired] = useState(false)
  const [showCanceled, setShowCanceled] = useState(false)

  useEffect(() => {
    setLoading(true)
    formsService
      .getAll()
      .then((forms) => {
        setForms(forms)
        setLoading(false)
      })
      .catch(() => {
        dispatch(
          alertActions.error(
            'An error occurred and we could not retrieve your forms at this time. Please try again.'
          )
        )
        setLoading(false)
      })
  }, [])

  const messageProvider = () => {
    history.push(`/messages/stream`, { doctorId: doctor })
  }

  const onClick = (form) => {
    if (form.status === 'SCHEDULED') {
      window.alert(
        t(
          `Your healthcare provider would like the information to be completed at a specific time.`
        )
      )
    } else if (form.status === 'SENT' || form.status === 'READY') {
      history.push(`/forms/questions`, {
        id: form.id,
        type: 'form',
        referrer: form.referrer
      })
    } else if (form.status === 'COMPLETED') {
      history.push(`/forms/view`, { id: form.id, referrer: form.referrer })
    } else if (form.status === 'EXPIRED') {
      setDoctor(form.doctor.id)
      setShowExpired(true)
    } else if (form.status === 'CANCELED') {
      setDoctor(form.doctor.id)
      setShowCanceled(true)
    }
  }

  return (
    <>
      <Row>
        <Col lg={2} className='pt-1 px-0 d-none d-lg-block'>
          <MainMenu />
        </Col>
        <Col md={12} lg={10}>
          <Row>
            <Col className='mb-3 sub__text'>
              <FontAwesomeIcon icon={faFileAlt} className='mr-2' />
              <span style={{ fontSize: '6px', verticalAlign: 'middle' }}>
                •
              </span>
              <span className='ml-2'>{t('Forms')}</span>
            </Col>
          </Row>

          {history.location.state && history.location.state.formSubmitted && (
            <Row>
              <Col>
                <Alert variant='success'>
                  {t('Your form has been submitted successfully!')}
                </Alert>
              </Col>
            </Row>
          )}

          <Row>
            <Col>
              <div className='main__title font-weight-bold my-auto'>
                {t('Your Forms')}
              </div>
              {forms && forms.length > 0 && (
                <div className='my-2'>
                  {t("Below you'll find upcoming and prior forms.")}
                </div>
              )}
            </Col>
          </Row>

          {loading ? (
            <Spinner animation='border' className='mt-2' />
          ) : forms !== null && forms.length > 0 ? (
            <Row>
              <FormModalExpired
                show={showExpired}
                message={messageProvider}
                hide={() => setShowExpired(false)}
              />
              <FormModalCanceled
                show={showCanceled}
                message={messageProvider}
                hide={() => setShowCanceled(false)}
              />

              {forms.map((form) => {
                return (
                  <Fragment key={form.id}>
                    <Col xs={12}>
                      <FormCardBase
                        form={form}
                        handleClick={() => {
                          onClick(form)
                        }}
                      />
                    </Col>
                  </Fragment>
                )
              })}
            </Row>
          ) : (
            <div className='mt-3'>
              <FormCardEmpty />
            </div>
          )}
        </Col>
      </Row>
    </>
  )
}
