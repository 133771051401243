export const data = {
  production: {
    HOME: {
      image: process.env.PUBLIC_URL + '/images/qrcode/home-production.jpeg',
      mobile: 'https://link.axshealthapp.com/dYaj24O8Snb'
    },
    FORMS: {
      image: process.env.PUBLIC_URL + '/images/qrcode/forms-production.jpeg',
      mobile: 'https://link.axshealthapp.com/2Xaj24O8Snb'
    },
    TELEHEALTH: {
      image:
        process.env.PUBLIC_URL + '/images/qrcode/telehealth-production.jpeg',
      mobile: 'https://link.axshealthapp.com/5Xaj24O8Snb'
    },
    EVENTS: {
      image: process.env.PUBLIC_URL + '/images/qrcode/events-production.jpeg',
      mobile: 'https://link.axshealthapp.com/8Xaj24O8Snb'
    },
    CONVERSATIONS: {
      image: process.env.PUBLIC_URL + '/images/qrcode/messages-production.jpeg',
      mobile: 'https://link.axshealthapp.com/aYaj24O8Snb'
    },
    DOWNLOAD: {
      image: process.env.PUBLIC_URL + '/images/qrcode/download-production.jpeg',
      mobile: 'https://link.axshealthapp.com/jYaj24O8Snb'
    }
  },
  shared: {
    HOME: {
      image: process.env.PUBLIC_URL + '/images/qrcode/home-shared.jpeg',
      mobile: 'https://xhch.test-app.link/CYCFXLYWRnb'
    },
    FORMS: {
      image: process.env.PUBLIC_URL + '/images/qrcode/forms-shared.jpeg',
      mobile: 'https://xhch.test-app.link/RVPoVLYWRnb'
    },
    TELEHEALTH: {
      image: process.env.PUBLIC_URL + '/images/qrcode/telehealth-shared.jpeg',
      mobile: 'https://xhch.test-app.link/UVPoVLYWRnb'
    },
    EVENTS: {
      image: process.env.PUBLIC_URL + '/images/qrcode/events-shared.jpeg',
      mobile: 'https://xhch.test-app.link/33qGVLYWRnb'
    },
    CONVERSATIONS: {
      image: process.env.PUBLIC_URL + '/images/qrcode/messages-shared.jpeg',
      mobile: 'https://xhch.test-app.link/43qGVLYWRnb'
    },
    DOWNLOAD: {
      image: process.env.PUBLIC_URL + '/images/qrcode/download-shared.jpeg',
      mobile: 'https://xhch.test-app.link/73qGVLYWRnb'
    }
  }
}
