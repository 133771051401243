import React, { useState, useEffect } from 'react'
import AxSLogo from '../../images/logo-alt@3x.png'
import Sidebar from './Sidebar'
import LanguageLinkButton from '../../_components/Language/LanguageLinkButton'

import { history } from '../../_helpers/history'
import { useSelector } from 'react-redux'
import { Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { getCookie } from '../../_helpers/cookieJar'

import './styles.scss'

export default function Login(props) {
  const { t } = useTranslation()
  const [location, setLocation] = useState('')
  const [logo, setLogo] = useState()
  const [copyName, setCopyName] = useState()

  const step = useSelector((state) => state.register.currentStep)

  useEffect(() => {
    const clientLogo = getCookie('client-logo')
    const clientName = getCookie('client-name')

    setLogo(clientLogo || AxSLogo)
    setCopyName(clientName || 'Your healthcare provider')
  }, [])

  useEffect(() => {
    const unlisten = history.listen((location) => {
      setLocation(location.pathname)
    })
    setLocation(history.location.pathname)
    return () => {
      unlisten()
    }
  }, [])

  const routeTo = (route) => {
    history.push(route, {
      from: history.location.state ? history.location.state.from : ''
    })
  }

  const type = location.indexOf('/register') === 0 ? 'register' : 'login'

  return (
    <div className='container' style={{ height: '100vh' }}>
      <div className='row h-100 mt-5'>
        <div className='col my-auto'>
          <div className='text-center d-lg-none'>
            <img src={logo} alt='Logo' style={{ height: '5em' }} />
          </div>
          <div className='text-center d-lg-none py-3'>
            {location.indexOf('/login') === 0 && (
              <>
                <p>{t('Intro Client Copy', { clientName: t(copyName) })}</p>
                <p>{t('Please login to continue.')}</p>
              </>
            )}
            {location.indexOf('/register') === 0 && step === 1 ? (
              <>
                <p>{t('Intro Client Copy', { clientName: t(copyName) })}</p>
                <p>{t('Please create an account to continue.')}</p>

                {location.indexOf('/register') === 0 && (
                  <div className='text-center d-lg-none py-4 px-3'>
                    <p>{t('Already have an account?')}</p>
                    <Button
                      block
                      variant='primary'
                      onClick={() => routeTo('/login')}
                    >
                      {t('Login')}
                    </Button>
                  </div>
                )}
              </>
            ) : step === 4 ? (
              <>
                <h5 className='pb-2'>{t('Last Step!')}</h5>
              </>
            ) : (
              step > 1 && <h5 className='pb-2'>{t('Almost Done!')}</h5>
            )}
          </div>
          <div className='card shadow container'>
            <div className='row'>
              <div
                className='col-lg-5 d-lg-block d-none'
                style={{ backgroundColor: '#1AB2E9' }}
              >
                <Sidebar step={step} location={location}>
                  {type === 'register' ? (
                    <Button
                      block
                      variant='light'
                      className='text-muted'
                      onClick={() => routeTo('/login')}
                    >
                      {t('Login')}
                    </Button>
                  ) : (
                    <Button
                      block
                      variant='light'
                      className='mb-3 text-muted'
                      onClick={() => routeTo('/register')}
                    >
                      {t('Create Account')}
                    </Button>
                  )}
                </Sidebar>
              </div>
              <div
                className='col-lg-7 mt-4 mb-2'
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignContent: 'center',
                  flexDirection: 'column'
                }}
              >
                {props.children}
              </div>
            </div>
          </div>

          {location.indexOf('/login') === 0 && (
            <div className='text-center d-lg-none py-4 px-3'>
              <p className='pt-3'>{t("Don't have an account?")}</p>
              <Button
                block
                variant='primary'
                onClick={() => routeTo('/register')}
              >
                {t('Create Account')}
              </Button>
            </div>
          )}

          {(location.indexOf('/forgot-password') === 0 ||
            location.indexOf('/forgot-username') === 0) && (
            <div className='container-fluid text-center'>
              <div className='row w-100'>
                <div className='col offset-1 offset-lg-6 py-3 pl-sm-0'>
                  <Button
                    block
                    variant='link'
                    onClick={() => routeTo('/login')}
                  >
                    {t('Login')}
                  </Button>
                </div>
              </div>
            </div>
          )}

          <div className='my-4 my-sm-5'>
            <LanguageLinkButton />
          </div>
          <div className='text-center mt-4 mb-5'>
            <small>
              v{process.env.REACT_APP_API_VERSION} (Build{' '}
              {process.env.REACT_APP_BUILD})
            </small>
          </div>
        </div>
      </div>
    </div>
  )
}
