import status from 'http-status'

import { history } from '../_helpers/history'
import { alertActions } from './alert.actions'
import { userConstants } from '../_constants/user.constants'
import { loadingActions } from './loading.actions'
import { store as reduxStore } from '../_helpers/store'
import { authenticationService } from '../_services/authentication.service'
import { authenticationConstants } from '../_constants/authentication.constants'

export const userActions = {
  login,
  logout,
  setUser,
  loadUser,
  storeUser,
  loadUserAndRedirect
}

function setUser(user) {
  return { type: userConstants.USER_SET_CURRENT_USER, user }
}

function login(username, password, referrer = '/home') {
  return (dispatch) => {
    dispatch(request({ username }))

    authenticationService
      .login(username, password)
      .then((response) => {
        dispatch({
          type: authenticationConstants.AUTHENTICATION_SET_USERNAME,
          username: reduxStore.getState().authentication.rememberUsername
            ? username
            : ''
        })
        dispatch(success(response.patient.auth))
        dispatch(store(response.patient))
        dispatch(alertActions.clear())
        dispatch(alertActions.clearDelay())
        history.push(referrer)
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === status.UNPROCESSABLE_ENTITY
        ) {
          dispatch(alertActions.error('Invalid Login Credentials'))
        } else {
          dispatch(alertActions.error('An error occurred. Please try again.'))
        }
        dispatch(failure())
      })
  }

  function request(user) {
    return {
      type: authenticationConstants.AUTHENTICATION_LOGIN_REQUEST,
      user
    }
  }
  function success(user) {
    return {
      type: authenticationConstants.AUTHENTICATION_LOGIN_SUCCESS,
      user
    }
  }
  function store(user) {
    return { type: userConstants.USER_SET_CURRENT_USER, user }
  }
  function failure() {
    return { type: authenticationConstants.AUTHENTICATION_LOGIN_FAILURE }
  }
}

function loadUser() {
  return (dispatch) => {
    authenticationService.getUser().then((response) => {
      dispatch(setUser(response.data))
    })
  }
}

function loadUserAndRedirect() {
  return (dispatch) => {
    authenticationService
      .getUser()
      .then((response) => {
        dispatch(setUser(response.data))

        history.push(window.location.hash || '/home')
      })
      .catch(() => {
        logout()
      })
  }
}

function storeUser() {
  return (dispatch) => {
    dispatch(loadingActions.toggleLoading())

    authenticationService
      .getUser()
      .then((response) => {
        dispatch(loadingActions.toggleLoading())
        const user = response.data
        dispatch({
          type: userConstants.USER_SET_CURRENT_USER,
          user
        })
      })
      .catch(() => {
        dispatch(loadingActions.toggleLoading())
      })
  }
}

function logout() {
  return (dispatch) => {
    authenticationService.logout()
    dispatch({ type: authenticationConstants.AUTHENTICATION_LOGOUT })

    history.push('/login')
  }
}
