/* eslint-disable no-undef */
import apiVersion from '../_helpers/api-version'
import axiosInstance from '../_helpers/axiosConfig'
import { authHeader } from '../_helpers/auth-header'

export const userService = {
  updateUserProfile,
  updateUserLoginInfo,
  uploadProfilePicture,
  updateUserContactInfo,
  getVerifyCodeContactInfo,
  sendDownloadAppLink
}

function sendDownloadAppLink(phoneNumber, module) {
  if (!module) {
    module = 'DOWNLOAD'
  }

  return axiosInstance.request({
    method: 'POST',
    url: `${
      process.env.REACT_APP_API_URL
    }/${apiVersion()}/patient/download-app`,
    data: { phoneNumber, module }
  })
}

function updateUserProfile(updatedProfile) {
  return axiosInstance.request({
    method: 'PATCH',
    headers: authHeader(),
    url: `${process.env.REACT_APP_API_URL}/${apiVersion()}/patient/account`,
    data: updatedProfile
  })
}

function updateUserLoginInfo(updatedLoginInfo) {
  return axiosInstance.request({
    method: 'PATCH',
    headers: authHeader(),
    url: `${process.env.REACT_APP_API_URL}/${apiVersion()}/patient/credentials`,
    data: updatedLoginInfo
  })
}

function getVerifyCodeContactInfo(updatedContactInfo) {
  return axiosInstance.request({
    method: 'POST',
    headers: authHeader(),
    url: `${
      process.env.REACT_APP_API_URL
    }/${apiVersion()}/patient/contact/verification`,
    data: updatedContactInfo
  })
}

function updateUserContactInfo(verifyInfo) {
  return axiosInstance.request({
    method: 'PATCH',
    headers: authHeader(),
    url: `${process.env.REACT_APP_API_URL}/${apiVersion()}/patient/contact`,
    data: verifyInfo
  })
}

function uploadProfilePicture(image) {
  const formData = new FormData()
  formData.append('imageData', image)

  return axiosInstance
    .request({
      method: 'POST',
      data: formData,
      headers: authHeader(),
      url: `${process.env.REACT_APP_API_URL}/${apiVersion()}/patient/profile`
    })
    .then((response) => {
      return response.data
    })
}
