import React from 'react'
import EmailInput from '../../_components/Inputs/EmailInput'
import ActionButton from '../../_components/Buttons/ActionButton'
import PasswordInput from '../../_components/Inputs/PasswordInput'
import CheckBoxInput from '../../_components/Inputs/CheckBoxInput'

import { history } from '../../_helpers/history'
import { useTranslation } from 'react-i18next'
import { Alert, Button, Form } from 'react-bootstrap'
import { handleEnter, handleNextInput } from '../../_helpers/handleEnter'

export default function LoginForm({
  errors,
  expired,
  username,
  password,
  loggingIn,
  handleLogin,
  showPassword,
  handleSetState,
  alertNextRoute,
  setShowPassword,
  rememberUsername,
  alertDelayMessage,
  setRememberUsername
}) {
  const { t } = useTranslation()

  const routeTo = (route) => {
    history.push(route, {
      from: history.location.state ? history.location.state.from : ''
    })
  }

  return (
    <div>
      <h3 className='mt-3 text-center'>{t('Patient Login')}</h3>

      <Form className='p-4'>
        {expired && (
          <Alert variant='danger'>
            {' '}
            {t('Your session has expired. Please log in.')}{' '}
          </Alert>
        )}
        {Object.values(alertNextRoute).length > 0 && (
          <Alert variant={alertNextRoute.type}>
            {t(alertNextRoute.message)}
          </Alert>
        )}
        {Object.values(alertDelayMessage).length > 0 && (
          <Alert variant={alertDelayMessage.type}>
            {t(alertDelayMessage.message)}
          </Alert>
        )}

        <EmailInput
          errors={errors}
          value={username}
          handleEnter={handleNextInput}
          valueName='username'
          label='Username'
          setValue={handleSetState}
        />

        <CheckBoxInput
          text='Remember Username'
          valueName='rememberUsername'
          value={rememberUsername}
          defaultChecked
          toggleValue={setRememberUsername}
        />
        <br />

        <PasswordInput
          errors={errors}
          password={password}
          handleEnter={(e) => (handleEnter(e) ? handleLogin(e) : '')}
          valueName='password'
          label='Password'
          setPassword={handleSetState}
          showPassword={showPassword}
          setShowPassword={setShowPassword}
        />

        <div className='form-group pt-3'>
          <ActionButton
            action='Login'
            variant='success'
            submit={handleLogin}
            loading={loggingIn}
          />
        </div>
        <div className='row pt-3 px-3'>
          <div className='col text-left p-0'>
            <Button
              block
              variant='link'
              className='text-left p-0 m-0'
              onClick={() => routeTo('/forgot-username')}
            >
              {t('Forgot Username')}
            </Button>
          </div>
          <div className='col p-0'>
            <Button
              block
              variant='link'
              className='text-right p-0 m-0'
              onClick={() => routeTo('/forgot-password')}
            >
              {t('Forgot Password')}
            </Button>
          </div>
        </div>
      </Form>
    </div>
  )
}
