import { history } from '../_helpers/history'
import { userActions } from './user.actions'
import { alertActions } from './alert.actions'
import { formsService } from '../_services/forms.service'
import { formConstants as constants } from '../_constants/form.constants'

export function removeElectronicSignature() {
  return { type: constants.REMOVE_ELECTRONIC_SIGNATURE }
}

export function setElectronicSignature(signature) {
  return { type: constants.SET_ELECTRONIC_SIGNATURE, signature }
}

export function setAnswerValidState(linkId, state) {
  return { type: constants.SET_ANSWERS, linkId, state }
}

export function setInitialState() {
  return { type: constants.INITIAL_STATE }
}

export function setItem(question, answer) {
  return { type: constants.SET_ITEM, question, answer }
}

export function updateItem(question, answer) {
  return { type: constants.UPDATE_ITEM, question, answer }
}

export function addItem(question, answer) {
  return { type: constants.ADD_ITEM, question, answer }
}

export function initAnswers(questions, signatureRequired) {
  return { type: constants.INIT_ANSWERS, questions, signatureRequired }
}

export function formCompleted(id, questions, referrer = null) {
  return (dispatch) => {
    dispatch(request())

    formsService
      .submitQuestions(id, questions)
      .then(() => {
        dispatch(success())
        if (referrer) {
          history.push(referrer.path)
        } else {
          history.push(`/forms/${id}/details`, { prompt: 'preferred' })
          dispatch(
            alertActions.success('Your form has been submitted successfully!')
          )
        }
      })
      .catch(() => {
        window.scrollTo(0, 0)
        dispatch(failure())
        dispatch(
          alertActions.error(
            'An error occurred and your form could not be submitted at this time. Please try again.'
          )
        )
      })
  }
  function request() {
    return { type: constants.FORM_COMPLETE_REQUEST }
  }
  function success() {
    return { type: constants.FORM_COMPLETE_SUCCESS }
  }
  function failure() {
    return { type: constants.FORM_COMPLETE_FAILURE }
  }
}

export function formRequestCompleted(formId, resource, patientId, licensedId) {
  return (dispatch) => {
    dispatch(request())

    formsService
      .submitRequestQuestions(formId, resource, patientId, licensedId)
      .then((form) => {
        dispatch(success())
        dispatch(userActions.loadUser())

        history.push(`/forms/${form.id}/details`)
        dispatch(
          alertActions.success('Your form has been submitted successfully!')
        )
      })
      .catch(() => {
        window.scrollTo(0, 0)
        dispatch(failure())
        dispatch(
          alertActions.error(
            'An error occurred and your form could not be submitted at this time. Please try again.'
          )
        )
      })
  }
  function request() {
    return { type: constants.FORM_COMPLETE_REQUEST }
  }
  function success() {
    return { type: constants.FORM_COMPLETE_SUCCESS }
  }
  function failure() {
    return { type: constants.FORM_COMPLETE_FAILURE }
  }
}
