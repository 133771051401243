import React from 'react'
import NumberFormat from 'react-number-format'
import RenderValidateMessage from '../Validation/RenderValidateMessage'

import { Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

export default function PhoneNumberInput(props) {
  const {
    label,
    errors,
    setValue,
    phoneNumber,
    handleEnter,
    disabled = false
  } = props
  const { t } = useTranslation()

  return (
    <Form.Group>
      {label && (
        <Form.Label className={'phoneNumber' in errors ? 'text-danger' : ''}>
          {t(label)}
        </Form.Label>
      )}
      <NumberFormat
        mask='_'
        name='phone'
        value={phoneNumber}
        format='###-###-####'
        disabled={disabled}
        className={`form-control ${
          'phoneNumber' in errors ? 'is-invalid' : ''
        }`}
        onKeyDown={handleEnter}
        placeholder='___-___-____'
        onValueChange={(e) =>
          setValue({ phoneNumber: e.value, formattedPhone: e.formattedValue })
        }
      />
      <RenderValidateMessage errors={errors} field='phoneNumber' />
    </Form.Group>
  )
}
