import React from 'react'
import NumberFormat from 'react-number-format'
import RenderValidateMessage from '../Validation/RenderValidateMessage'

import { Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

export default function DateOfBirthInput(props) {
  const {
    errors,
    setDOB,
    dateOfBirth,
    handleEnter,
    showLabel = true,
    disabled = false
  } = props
  const { t } = useTranslation()

  return (
    <Form.Group>
      {showLabel && (
        <Form.Label className={'dateOfBirth' in errors ? 'text-danger' : ''}>
          {t('Date of Birth')}
        </Form.Label>
      )}
      <NumberFormat
        mask='_'
        name='dateOfBirth'
        value={dateOfBirth}
        format='##/##/####'
        disabled={disabled}
        className={`form-control ${
          'dateOfBirth' in errors ? 'is-invalid' : ''
        }`}
        onKeyDown={handleEnter}
        placeholder='__/__/____'
        onValueChange={(e) => setDOB({ dateOfBirth: e.formattedValue })}
      />
      <RenderValidateMessage errors={errors} field='dateOfBirth' />
    </Form.Group>
  )
}
