import { languageConstants as constants } from '../_constants/language.constants'

const initialState = {
  show: false
}

export function language(state = initialState, action) {
  if (action.type === constants.TOGGLE_LANGUAGE_MODAL) {
    return {
      ...state,
      show: !state.show
    }
  }
  return state
}
