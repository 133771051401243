import axiosInstance from '../_helpers/axiosConfig'
import apiVersion from '../_helpers/api-version'
import { authHeader } from '../_helpers/auth-header'

export const carePlanService = {
  getCarePlans,
  getCarePlanById
}

function getCarePlans() {
  return axiosInstance
    .request({
      method: 'GET',
      headers: authHeader(),
      url: `${process.env.REACT_APP_API_URL}/${apiVersion()}/patient/care-plan`
    })
    .then((response) => {
      return response.data.data
    })
}

function getCarePlanById(careId) {
  return axiosInstance
    .request({
      method: 'GET',
      headers: authHeader(),
      url: `${
        process.env.REACT_APP_API_URL
      }/${apiVersion()}/patient/care-plan/${careId}`
    })
    .then((response) => {
      return response.data.data
    })
}
