import React from 'react'
import RenderValidateMessage from '../Validation/RenderValidateMessage'

import { useTranslation } from 'react-i18next'

export default function Terms(props) {
  const { errors, setValue } = props
  const { t } = useTranslation()
  return (
    <div className='form-check'>
      <input
        id='terms'
        style={{ height: '1.5em', width: '1.5em' }}
        className='form-check-input'
        type='checkbox'
        onChange={(e) => setValue({ terms: e.target.checked })}
      />
      <label className='form-check-label pl-3 pt-1'>
        <small>
          <span
            onClick={(e) => {
              e.preventDefault()
              document.getElementById(`terms`).click()
            }}
          >
            {t('By selecting this checkbox, I have reviewed and agree to the')}
            &nbsp;
          </span>
          <a
            target='_blank'
            rel='noopener noreferrer'
            href='https://www.axshealthapp.com/terms-of-use/'
          >
            {t('Terms of Use')}
          </a>
        </small>
        <RenderValidateMessage errors={errors} field='terms' />
      </label>
    </div>
  )
}
