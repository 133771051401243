import React, { useEffect, useState } from 'react'
import moment from 'moment'
import MainMenu from '../../_components/MainMenu'

import { history } from '../../_helpers/history'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { alertActions } from '../../_actions/alert.actions'
import { eventService } from '../../_services/event.service'
import { useTranslation } from 'react-i18next'
import { authenticationActions } from '../../_actions/authentication.actions'
import { Breadcrumb, Spinner, Alert, Row, Col } from 'react-bootstrap'

import {
  EventCardDetails,
  FormModalExpired,
  FormModalCanceled,
  TelehealthModalExpired,
  TelehealthModalCanceled
} from '@axshealth/design-library'

export default function EventPage() {
  const dispatch = useDispatch(null)
  const { t } = useTranslation()
  const { id } = useParams()

  const [event, setEvent] = useState(null)
  const [doctor, setDoctor] = useState('')
  const [loading, setLoading] = useState(true)
  const [showFormExpired, setShowFormExpired] = useState(false)
  const [showFormCanceled, setShowFormCanceled] = useState(false)
  const [showTelehealthExpired, setShowTelehealthExpired] = useState(false)
  const [showTelehealthCanceled, setShowTelehealthCanceled] = useState(false)

  useEffect(() => {
    if (id) {
      setLoading(true)
      eventService
        .getEvent(id)
        .then((event) => {
          setLoading(false)
          showPreferredModal()
          setEvent(event)
        })
        .catch(() => {
          setLoading(false)
          history.push('/home')
          dispatch(
            alertActions.error(
              'An error occurred and we could not display your requested item at this time. Please try again.'
            )
          )
        })
    } else {
      history.push('/home')
      dispatch(
        alertActions.error(
          'An error occurred and we could not display your requested item at this time. Please try again.'
        )
      )
    }
  }, [])

  const showPreferredModal = () => {
    if (
      history.location.state &&
      history.location.state.prompt === 'preferred'
    ) {
      setTimeout(() => {
        dispatch(authenticationActions.displayPreferredPlatform('EVENTS'))
      }, 1000)
    }
  }

  const messageProvider = () => {
    history.push(`/messages/stream`, { doctorId: doctor })
  }

  const handleClick = (type, item) => {
    if (item.status === 'SCHEDULED') {
      window.alert(
        t(
          `Your healthcare provider would like the information to be completed at a specific time.`
        )
      )
    } else if (type === 'form') {
      if (item.status === 'SENT' || item.status === 'READY') {
        history.push(`/forms/questions`, {
          id: item.id,
          type: 'form',
          referrer: item.referrer
        })
      } else if (item.status === 'COMPLETED') {
        history.push(`/forms/view`, { id: item.id, referrer: item.referrer })
      } else if (item.status === 'EXPIRED') {
        setDoctor(item.doctor.id)
        setShowFormExpired(true)
      } else if (item.status === 'CANCELED') {
        setDoctor(item.doctor.id)
        setShowFormCanceled(true)
      }
    } else if (type === 'telehealth') {
      if (item.status === 'SENT') {
        history.push(`/telehealth/consent`, {
          id: item.id,
          type: 'telehealth',
          referrer: item.referrer,
          telehealth: item
        })
      } else if (
        item.status === 'HOLD' ||
        item.status === 'READY' ||
        item.status === 'COMPLETED'
      ) {
        history.push(`/telehealth/${item.id}/encounter`, {
          referrer: item.referrer,
          telehealth: item
        })
      } else if (item.status === 'EXPIRED') {
        setDoctor(item.doctor.id)
        setShowTelehealthExpired(true)
      } else if (item.status === 'CANCELED') {
        setDoctor(item.doctor.id)
        setShowTelehealthCanceled(true)
      }
    }
  }

  return (
    <Row>
      <Col lg={2} className='pt-1 px-0 d-none d-lg-block'>
        <MainMenu />
      </Col>
      {loading ? (
        <Col>
          <Spinner animation='border' className='mt-2' />
        </Col>
      ) : (
        event !== null && (
          <Col xs={12} lg={10}>
            {history.location.state && history.location.state.formSubmitted && (
              <Row>
                <Col>
                  <Alert variant='success'>
                    {t('Your form has been submitted successfully!')}
                  </Alert>
                </Col>
              </Row>
            )}
            {history.location.state &&
              history.location.state.preQuestionsSubmitted && (
                <Row>
                  <Col>
                    <Alert variant='success'>
                      {t(
                        'You are now ready for your telehealth appointment on'
                      )}
                      &nbsp;
                      <b className='main__text'>
                        {moment
                          .tz(
                            history.location.state.date,
                            history.location.state.timezone
                          )
                          .local()
                          .format('MMMM D, YYYY [@] h:mm A')}
                      </b>
                    </Alert>
                  </Col>
                </Row>
              )}
            <Row>
              <Col xs={12}>
                <Breadcrumb>
                  <Breadcrumb.Item onClick={() => history.push('/home')}>
                    {t('Home')}
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>{t(event.type)}</Breadcrumb.Item>
                </Breadcrumb>
              </Col>
              <Col xs={12}>
                <FormModalExpired
                  show={showFormExpired}
                  message={messageProvider}
                  hide={() => setShowFormExpired(false)}
                />

                <FormModalCanceled
                  show={showFormCanceled}
                  message={messageProvider}
                  hide={() => setShowFormCanceled(false)}
                />

                <TelehealthModalExpired
                  show={showTelehealthExpired}
                  message={messageProvider}
                  hide={() => setShowTelehealthExpired(false)}
                />

                <TelehealthModalCanceled
                  show={showTelehealthCanceled}
                  message={messageProvider}
                  hide={() => setShowTelehealthCanceled(false)}
                />

                <EventCardDetails
                  event={event}
                  date={event.date}
                  time={event.time}
                  timezone={event.providerGroup.timezone}
                  handleClick={handleClick}
                />
              </Col>
            </Row>
          </Col>
        )
      )}
    </Row>
  )
}
