import React, { useState, useEffect } from 'react'
import useWindowDimensions from '../../../../_helpers/dimensions'

import { ListGroup, Button, Modal } from 'react-bootstrap'

export default function MediaSettingsModal({
  show,
  hide,
  videoDevices,
  audioInputDevices,
  audioOutputDevices,
  selectVideoDevice,
  selectAudioInputDevice,
  selectAudioOutputDevice
}) {
  const { width, breakPointMD } = useWindowDimensions()
  const [selectedVideoDevice, setSelectedVideoDevice] = useState('default')
  const [selectedAudioInputDevice, setSelectedAudioInputDevice] = useState(
    'default'
  )
  const [selectedAudioOutputDevice, setSelectedAudioOutputDevice] = useState(
    'default'
  )

  useEffect(() => {
    let tracks = null

    // Set init values for audio & video input
    navigator.mediaDevices
      .getUserMedia({ video: true, audio: true })
      .then(function (stream) {
        tracks = stream.getTracks()
        for (const track of tracks) {
          if (track.kind === 'video') {
            setSelectedVideoDevice(track.getSettings().deviceId)
          } else if (track.kind === 'audio') {
            setSelectedAudioInputDevice(track.getSettings().deviceId)
          }
        }
      })
      .catch(function (err) {
        console.error(err)
      })

    return () => {
      if (tracks !== null) {
        for (const track of tracks) {
          track.stop()
        }
      }
    }
  }, [])

  const handleSelectVideoDevice = async (id) => {
    if (await selectVideoDevice(id)) {
      setSelectedVideoDevice(id)
    }
  }

  const handleSelectAudioInputDevice = async (id) => {
    if (await selectAudioInputDevice(id)) {
      setSelectedAudioInputDevice(id)
    }
  }

  const handleSelectAudioOutputDevice = async (id) => {
    if (await selectAudioOutputDevice(id)) {
      setSelectedAudioOutputDevice(id)
    }
  }

  return (
    <Modal show={show} onHide={hide} centered>
      <Modal.Header>
        <Modal.Title>Media Settings</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {Object.values(videoDevices).length > 0 && (
          <>
            <h5>Video:</h5>
            <ListGroup>
              {videoDevices.map((device, i) => {
                return (
                  <ListGroup.Item
                    key={i}
                    action
                    active={selectedVideoDevice === device.id}
                    onClick={() => handleSelectVideoDevice(device.id)}
                  >
                    {' '}
                    {device.name}
                  </ListGroup.Item>
                )
              })}
            </ListGroup>
          </>
        )}
        {Object.values(audioOutputDevices).length > 0 && (
          <>
            <br />
            <h5>Audio:</h5>
            <ListGroup>
              {audioOutputDevices.map((device, i) => {
                return (
                  <ListGroup.Item
                    key={i}
                    action
                    active={selectedAudioOutputDevice === device.id}
                    onClick={() => handleSelectAudioOutputDevice(device.id)}
                  >
                    {' '}
                    {device.name}
                  </ListGroup.Item>
                )
              })}
            </ListGroup>
          </>
        )}
        {Object.values(audioInputDevices).length > 0 && (
          <>
            <br />
            <h5>Mic:</h5>
            <ListGroup>
              {audioInputDevices.map((device, i) => {
                return (
                  <ListGroup.Item
                    key={i}
                    action
                    active={selectedAudioInputDevice === device.id}
                    onClick={() => handleSelectAudioInputDevice(device.id)}
                  >
                    {' '}
                    {device.name}
                  </ListGroup.Item>
                )
              })}
            </ListGroup>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button block={width < breakPointMD} variant='secondary' onClick={hide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
