import React from 'react'
import TextInput from '../../../../_components/Inputs/TextInput'
import ActionButton from '../../../../_components/Buttons/ActionButton'
import PasswordInput from '../../../../_components/Inputs/PasswordInput'
import PasswordChecklist from '../../../../_components/Validation/PasswordChecklist'

import { useTranslation } from 'react-i18next'
import { handleNextInput } from '../../../../_helpers/handleEnter'
import { Col, Row, Card, Form } from 'react-bootstrap'

export default function LoginInfo({
  errors,
  username,
  isInvalid,
  newPassword,
  loadingUser,
  loginUpdated,
  updatingLogin,
  updateRequest,
  currentPassword,
  confirmPassword,
  handleSetState,
  handleUpdateLoginInfo
}) {
  const { t } = useTranslation()

  return (
    <Row>
      <Col xs='12'>
        <Card className='pb-1 border-bottom-0' style={{ borderRadius: 0 }}>
          <Card.Body>
            <Row>
              <Col xs='12' className='my-3 my-lg-0'>
                <h4>{t('Login Info')}</h4>
              </Col>
            </Row>
            <Row className='mt-lg-3'>
              <Col xs='12'>
                <Form>
                  <Form.Group>
                    <Row className='mb-2'>
                      <Col
                        xs='12'
                        lg='4'
                        className='mt-2 px-lg-0 text-lg-right'
                      >
                        <Form.Label className={isInvalid('username')}>
                          {t('Username')}
                        </Form.Label>
                      </Col>
                      <Col xs='12' lg='8'>
                        <TextInput
                          errors={errors}
                          value={username}
                          valueName='username'
                          label={false}
                          setValue={(value) =>
                            handleSetState({ username: value.username })
                          }
                          handleEnter={handleNextInput}
                        />
                      </Col>
                    </Row>

                    <Row className='mb-2'>
                      <Col
                        xs='12'
                        lg='4'
                        className='mt-2 px-lg-0 text-lg-right'
                      >
                        <Form.Label className={isInvalid('currentPassword')}>
                          {t('Current Password')}
                        </Form.Label>
                      </Col>
                      <Col xs='12' lg='8'>
                        <PasswordInput
                          errors={errors}
                          label={false}
                          password={currentPassword}
                          valueName='currentPassword'
                          setPassword={(value) =>
                            handleSetState({
                              currentPassword: value.password
                            })
                          }
                          handleEnter={handleNextInput}
                          hideShowPassword
                        />
                      </Col>
                    </Row>

                    <Row className='mb-2'>
                      <Col
                        xs='12'
                        lg='4'
                        className='mt-2 px-lg-0 text-lg-right'
                      >
                        <Form.Label className={isInvalid('newPassword')}>
                          {t('New Password')}
                        </Form.Label>
                      </Col>
                      <Col xs='12' lg='8'>
                        <PasswordInput
                          errors={errors}
                          label={false}
                          password={newPassword}
                          valueName='newPassword'
                          setPassword={(value) =>
                            handleSetState({
                              newPassword: value.password
                            })
                          }
                          handleEnter={handleNextInput}
                          hideShowPassword
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col
                        xs='12'
                        lg='4'
                        className='mt-2 px-lg-0 text-lg-right'
                      >
                        <Form.Label className={isInvalid('confirmPassword')}>
                          {t('Confirm Password')}
                        </Form.Label>
                      </Col>
                      <Col xs='12' lg='8'>
                        <PasswordInput
                          errors={errors}
                          label={false}
                          password={confirmPassword}
                          valueName='confirmPassword'
                          setPassword={(value) =>
                            handleSetState({
                              confirmPassword: value.password
                            })
                          }
                          handleEnter={handleNextInput}
                          hideShowPassword
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col xs='12' lg={{ span: 7, offset: 4 }}>
                        <PasswordChecklist password={newPassword} />
                      </Col>
                    </Row>

                    <Row className='text-left'>
                      <Col xs='12' lg={{ span: 4, offset: 4 }}>
                        <ActionButton
                          action={
                            loginUpdated
                              ? 'Login Info Updated!'
                              : updatingLogin
                              ? 'Updating...'
                              : 'Update Login Info'
                          }
                          variant='outline-success'
                          submit={handleUpdateLoginInfo}
                          className='mt-2'
                          disabled={
                            loadingUser ||
                            loginUpdated ||
                            updatingLogin ||
                            updateRequest
                          }
                          loading={updatingLogin}
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                </Form>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  )
}
