import React, { Component } from 'react'

class MuteButton extends Component {
  constructor(props) {
    super(props)

    this.state = {
      audio: true
    }
  }

  toggleMute() {
    const audioState = !this.state.audio

    this.setState({
      audio: audioState
    })

    this.props.handleMute(audioState)
  }

  render() {
    const micType = this.state.audio
      ? 'fa fa-microphone'
      : 'fa fa-microphone-slash'

    const btnClass = this.state.audio ? 'teleVideoBtn on' : 'teleVideoBtn'

    return (
      <button
        id='muteBtn'
        onClick={(e) => {
          e.preventDefault()
          this.toggleMute()
        }}
        type='button'
        className={btnClass}
      >
        <i className={`fa-flip-horizontal ${micType}`} />
      </button>
    )
  }
}

export default MuteButton
