import React, { Component } from 'react'
import Deeplink from '../../_layouts/Deeplink'

import { connect } from 'react-redux'
import { Spinner } from 'react-bootstrap'
import { formsService } from '../../_services/forms.service'
import { telehealthService } from '../../_services/telehealth.service'
import { eventService } from '../../_services/event.service'

class DeepLink extends Component {
  constructor(props) {
    super(props)

    this.timer = null

    this.state = {
      loading: true
    }
  }

  componentDidMount() {
    this.initRouter()

    this.timer = setTimeout(() => {
      if (this.state.loading) {
        this.setState({ loading: false })
      }
    }, 5000)
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(prevProps.deepLink) !== JSON.stringify(this.props.deepLink)
    ) {
      this.initRouter()
    }
  }

  initRouter() {
    if (this.props.deepLink.type === 'FORM_DEEP_LINK') {
      this.formRouter(this.props.deepLink.token)
    } else if (this.props.deepLink.type === 'TELEHEALTH_DEEP_LINK') {
      this.telehealthRouter(this.props.deepLink.token)
    } else if (this.props.deepLink.type === 'AXS_EVENT_DEEP_LINK') {
      this.eventRouter(this.props.deepLink.token)
    } else if (this.props.deepLink.type === 'CONVERSATION_DEEP_LINK') {
      this.messageRouter(this.props.deepLink.var1)
    } else if (this.props.deepLink.type === 'FORM_LIST') {
      this.props.history.push('/forms')
    } else if (this.props.deepLink.type === 'TELEHEALTH_LIST') {
      this.props.history.push('/telehealth')
    } else if (this.props.deepLink.type === 'EVENT_LIST') {
      this.props.history.push('/home')
    } else if (this.props.deepLink.type === 'CONVERSATION_LIST') {
      this.props.history.push('/messages')
    } else if (this.props.deepLink.type === 'CAREPLAN_LIST') {
      this.props.history.push(`/care-plans`)
    } else this.props.history.push('/home')
  }

  eventRouter(token) {
    eventService.getByToken(token).then((response) => {
      const event = response.data
      let redirect = '/home'

      if (event.assigned) {
        redirect = `/event/${event.id}/details`
      } else if (!event.assigned) {
        redirect = `/event/${token}/deep-link`
      }
      this.props.history.push(redirect)
    })
  }

  telehealthRouter(token) {
    telehealthService.getByToken(token).then((response) => {
      const telehealth = response.data
      let redirect = '/telehealth'
      let state

      if (telehealth.status === 'SCHEDULED' || telehealth.status === 'SENT') {
        if (telehealth.assigned) {
          redirect = `/telehealth/${telehealth.id}/details`
        } else {
          redirect = `/telehealth/${token}/deep-link`
        }
      } else if (telehealth.status === 'READY') {
        redirect = `/telehealth/${telehealth.id}/details`
      } else if (telehealth.status === 'COMPLETED') {
        redirect = `/telehealth/${telehealth.id}/details`
      } else if (
        telehealth.status === 'EXPIRED' ||
        telehealth.status === 'CANCELED'
      ) {
        if (telehealth.assigned) {
          redirect = `/telehealth/${telehealth.id}/details`
        }
      }

      this.props.history.push(redirect, state)
    })
  }

  formRouter(token) {
    formsService.getByToken(token).then((response) => {
      const form = response.data
      let redirect = '/forms'
      let state

      if (form.status === 'SCHEDULED') {
        if (form.assigned) {
          redirect = `/forms/${form.id}/details`
        } else {
          redirect = `/forms/${token}/deep-link`
        }
      } else if (form.status === 'SENT') {
        if (form.assigned) {
          redirect = `/forms/questions`
          state = { id: form.id, type: 'form', referrer: '' }
        } else {
          redirect = `/forms/${token}/deep-link`
        }
      } else if (form.status === 'COMPLETED') {
        redirect = `/forms/${form.id}/details`
      } else if (form.status === 'EXPIRED' || form.status === 'CANCELED') {
        if (form.assigned) {
          redirect = `/forms/${form.id}/details`
        }
      }

      this.props.history.push(redirect, state)
    })
  }

  messageRouter(id) {
    this.props.history.push(`/messages/${id}/stream`)
  }

  render() {
    return (
      <Deeplink>
        {this.state.loading ? (
          <Spinner animation='border' role='status'>
            <span className='sr-only'>Loading Link...</span>
          </Spinner>
        ) : (
          <strong>
            We could not retrieve the link information. Please click on the
            email or text message link again.
          </strong>
        )}
      </Deeplink>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    deepLink: state.deepLink.data
  }
}

export default connect(mapStateToProps)(DeepLink)
