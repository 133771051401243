import { authenticationConstants as constant } from '../_constants/authentication.constants'

export const authenticationActions = {
  clickDisplayPlatform,
  displayPreferredPlatform,
  setPreferredPlatform
}

function clickDisplayPlatform(module) {
  return { type: constant.AUTHENTICATION_CLICK_PREFERRED_PLATFORM, module }
}

function displayPreferredPlatform(module) {
  return { type: constant.AUTHENTICATION_DISPLAY_PREFERRED_PLATFORM, module }
}

function setPreferredPlatform(platform) {
  return {
    type: constant.AUTHENTICATION_SET_PREFERRED_PLATFORM,
    platform: platform,
    timestamp: +new Date()
  }
}
