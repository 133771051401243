import React, { Component } from 'react'
import Pusher from 'pusher-js/with-encryption'
import UAParser from 'ua-parser-js'
import * as Sentry from '@sentry/react'

import Alert from '../../_components/Alerts/Alert'
import NavBar from '../../_components/NavBar/NavBar'
import PreferredPlatform from '../../_components/PreferredPlatform/'

import { connect } from 'react-redux'
import { PusherProvider } from '../../_contexts/PusherContext'
import { withTranslation } from 'react-i18next'
import { authenticationActions } from '../../_actions/authentication.actions'

import {
  addSidebarClickListener,
  removeSidebarClickListener
} from '../../_helpers/setup-sidebar'

import Logo from '../../images/logo.png'
import AppLogo from '../../_assets/logo.png'
import SideMenu from '../../_components/SideMenu'

import './styles.scss'

const pusherClient = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
  authEndpoint: process.env.REACT_APP_PUSHER_AUTH,
  wsHost: process.env.REACT_APP_PUSHER_HOST,
  wsPort: process.env.REACT_APP_PUSHER_PORT,
  wssPort: process.env.REACT_APP_PUSHER_PORT,
  encryptionMasterKeyBase64: process.env.REACT_APP_PUSHER_ENCRYPT_KEY,
  forceTLS: true,
  encrypted: true,
  disableStats: true,
  enabledTransports: ['ws', 'wss'],
  auth: { headers: { Authorization: null } },
  disabledTransports: ['sockjs', 'xhr_polling', 'xhr_streaming']
})

class App extends Component {
  componentDidMount() {
    addSidebarClickListener()
    Sentry.setUser({ id: this.props.userId })
  }

  componentWillUnmount() {
    removeSidebarClickListener()
  }

  render() {
    const parser = new UAParser()
    const { t, token } = this.props

    pusherClient.config.auth.headers.Authorization = `Bearer ${token}`

    return (
      <PusherProvider value={pusherClient}>
        <NavBar />
        <SideMenu />

        <div id='app-container' className='container-lg h-100'>
          <Alert className='mt-4' alert={this.props.alertNextRoute} />
          <Alert className='mt-4' alert={this.props.alertDelay} />

          <PreferredPlatform />

          <div className='mt-3'>{this.props.children}</div>
        </div>

        <footer className='footer text-white'>
          <div className='container'>
            <div className='row'>
              <div className='col-12 col-lg-6 text-center'>
                <img
                  src={Logo}
                  alt='AxS Health Logo'
                  width='100'
                  className='d-none d-lg-inline-block'
                />
                <p>
                  AxS Health&nbsp;
                  <small>
                    v{process.env.REACT_APP_API_VERSION} (Build{' '}
                    {process.env.REACT_APP_BUILD})
                  </small>
                </p>
              </div>

              <div className='col-6 d-none d-lg-block'>
                {parser.getDevice().type !== 'mobile' &&
                  parser.getDevice().type !== 'tablet' && (
                    <div
                      className='text-center'
                      onClick={() => {
                        this.props.dispatch(
                          authenticationActions.clickDisplayPlatform('DOWNLOAD')
                        )
                      }}
                    >
                      <p>{t('Download the AxS Health app!')}</p>
                      <img
                        src={AppLogo}
                        alt='AxS Health Logo'
                        height='75'
                        className='rounded'
                        style={{ cursor: 'pointer' }}
                      />
                    </div>
                  )}
              </div>
            </div>
          </div>
        </footer>
      </PusherProvider>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    userId: state.user.currentUser.userId,
    token: state.authentication.jwt,
    alertDelay: state.alert.delayMessage,
    alertNextRoute: state.alert.nextRouteMessage
  }
}
export default connect(mapStateToProps)(withTranslation()(App))
