import React, { useEffect, useState } from 'react'
import UAParser from 'ua-parser-js'
import { Link } from 'react-router-dom'
import { Col, Row } from 'react-bootstrap'
import { useDispatch } from 'react-redux'

import Logo from '../../images/logo.png'
import appleStore from '../../_assets/apple@3x.png'
import googleStore from '../../_assets/google@3x.png'

import { authenticationActions } from '../../_actions/authentication.actions'

import { history } from '../../_helpers/history'
import { userActions } from '../../_actions/user.actions'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileAlt, faComments } from '@fortawesome/free-regular-svg-icons'
import {
  faHome,
  faMobileAlt,
  faBriefcaseMedical,
  faSignOutAlt
} from '@fortawesome/free-solid-svg-icons'

import { data } from '../PreferredPlatform/data'

import './style.css'

function getDeepLink() {
  const environment =
    process.env.REACT_APP_ENVIRONMENT === 'production' ? 'production' : 'shared'

  return data[environment].DOWNLOAD.mobile
}

export default function SideMenu() {
  const parser = new UAParser()
  const dispatch = useDispatch()

  const { t } = useTranslation()
  const [location, setLocation] = useState('')

  const getStoreImg = (type) => {
    switch (type) {
      case 'Android':
      case 'Windows':
      case 'Windows Phone':
        return googleStore
      default:
        return appleStore
    }
  }

  function closeNav() {
    document.getElementById('mySidepanel').style.left = '-20em'
  }

  function logout() {
    dispatch(userActions.logout())
  }

  useEffect(() => {
    const unlisten = history.listen((location) => {
      setLocation(location.pathname)
    })

    return () => {
      unlisten()
    }
  }, [])

  useEffect(() => {
    setLocation(history.location.pathname)
  }, [history.location.pathname])

  return (
    <div id='mySidepanel' className='sidepanel' onClick={() => closeNav()}>
      <div className='text-center mb-4'>
        <img src={Logo} alt='AxS Health Logo' height='120' />
      </div>

      <div className='sidepanel__container'>
        <div>
          <Row className='font-weight-light ml-3 mb-2'>
            <Col className='sub__text font__size--sub'>{t('MENU')}</Col>
          </Row>

          <Link
            to='/home'
            onClick={() => closeNav()}
            className={`mt-1 font-weight-light ${
              location.indexOf('/home') === 0 ? 'sidepanel-active' : ''
            }`}
          >
            <Row>
              <Col xs='2'>
                <FontAwesomeIcon className='fa-fw' icon={faHome} />
              </Col>
              <Col className='text-white '>{t('Home')}</Col>
            </Row>
          </Link>
          <Link
            to='/forms'
            onClick={() => closeNav()}
            className={`mt-2 font-weight-light ${
              location.indexOf('/forms') === 0 ? 'sidepanel-active' : ''
            }`}
          >
            <Row>
              <Col xs='2'>
                <FontAwesomeIcon className='fa-fw' icon={faFileAlt} />
              </Col>
              <Col className='text-white '>{t('Forms')}</Col>
            </Row>
          </Link>
          <Link
            to='/telehealth'
            onClick={() => closeNav()}
            className={`mt-2 font-weight-light ${
              location.indexOf('/telehealth') === 0 ? 'sidepanel-active' : ''
            }`}
          >
            <Row>
              <Col xs='2'>
                <FontAwesomeIcon className='fa-fw' icon={faMobileAlt} />
              </Col>
              <Col className='text-white '>{t('Telehealth')}</Col>
            </Row>
          </Link>
          <Link
            to='/messages'
            onClick={() => closeNav()}
            className={`mt-2 font-weight-light ${
              location.indexOf('/messages') === 0 ? 'sidepanel-active' : ''
            }`}
          >
            <Row>
              <Col xs='2'>
                <FontAwesomeIcon className='fa-fw' icon={faComments} />
              </Col>
              <Col className='text-white '>{t('Messages')}</Col>
            </Row>
          </Link>
          <Link
            to='/care-plans'
            onClick={() => closeNav()}
            className={`mt-2 font-weight-light ${
              location.indexOf('/care-plans') === 0 ? 'sidepanel-active' : ''
            }`}
          >
            <Row>
              <Col xs='2'>
                <FontAwesomeIcon className='fa-fw' icon={faBriefcaseMedical} />
              </Col>
              <Col className='text-white '>{t('Care Plans')}</Col>
            </Row>
          </Link>
          <Link
            to='/'
            onClick={(e) => {
              e.preventDefault()
              logout()
            }}
            className='mt-2 font-weight-light'
          >
            <Row>
              <Col xs='2'>
                <FontAwesomeIcon className='fa-fw' icon={faSignOutAlt} />
              </Col>
              <Col className='text-white ml-2'>{t('Logout')}</Col>
            </Row>
          </Link>
        </div>

        <div className='app-store-btn ml-1 mb-3'>
          {(parser.getDevice().type === 'mobile' ||
            parser.getDevice().type === 'tablet') && (
            <>
              <h6 className='font-weight-normal text-light'>
                {t('Download the App')}!
              </h6>
              <a
                rel='noreferrer'
                target='_blank'
                className='px-4'
                href={getDeepLink()}
                onClick={() =>
                  dispatch(authenticationActions.setPreferredPlatform('app'))
                }
              >
                <img
                  src={getStoreImg(parser.getOS().name)}
                  alt='Apple Store'
                  style={{ height: '50px' }}
                />
              </a>
            </>
          )}
        </div>
      </div>
    </div>
  )
}
