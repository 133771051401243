import React, { useState, useEffect } from 'react'
import { Alert as Alrt } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

export default function Alert(props) {
  const { t } = useTranslation()
  const {
    alert,
    className,
    timeout = 0,
    dismissible = false,
    shouldFadeOut = false
  } = props
  const [show, setShow] = useState(false)

  const shouldShow = () => {
    return Object.keys(alert).length > 0
  }
  useEffect(() => {
    setShow(shouldShow())
  }, [alert])

  useEffect(() => {
    if (shouldFadeOut) {
      setTimeout(() => {
        setShow(false)
      }, timeout)
    }
  }, [alert])

  return (
    <Alrt
      show={show}
      variant={alert.type}
      className={`${className} text-left pt-2 pb-0`}
      onClose={() => setShow(false)}
      transition={shouldShow()}
      dismissible={dismissible}
    >
      <Alrt.Heading>{t(alert.heading)}</Alrt.Heading>
      <p>{t(alert.message)}</p>
    </Alrt>
  )
}
