import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export default function LinkButton(props) {
  const { t } = useTranslation()
  const {
    link,
    btn,
    color,
    title,
    handleClick = null,
    noUnderline = false
  } = props
  return (
    <Link
      to={link}
      style={!noUnderline ? { textDecoration: 'underline' } : {}}
      onClick={handleClick}
      className={`${btn} ${color}`}
    >
      {t(title)}
      {props.children}
    </Link>
  )
}
