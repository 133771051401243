import React, { useState, useEffect } from 'react'
import moment from 'moment'
import MainMenu from '../../../_components/MainMenu'

import { history } from '../../../_helpers/history'
import { useParams } from 'react-router'
import { useDispatch } from 'react-redux'
import { alertActions } from '../../../_actions/alert.actions'
import { useTranslation } from 'react-i18next'
import { telehealthService } from '../../../_services/telehealth.service'
import { authenticationActions } from '../../../_actions/authentication.actions'
import { Breadcrumb, Spinner, Alert, Row, Col } from 'react-bootstrap'

import {
  TelehealthCardDetails,
  TelehealthModalExpired,
  TelehealthModalCanceled
} from '@axshealth/design-library'

export default function TelehealthDetails() {
  const dispatch = useDispatch(null)
  const { t } = useTranslation()
  const { id } = useParams()

  const [doctor, setDoctor] = useState(null)
  const [loading, setLoading] = useState(false)
  const [telehealth, setTelehealth] = useState(null)
  const [showExpired, setShowExpired] = useState(false)
  const [showCanceled, setShowCanceled] = useState(false)

  useEffect(() => {
    if (id) {
      setLoading(true)
      telehealthService
        .getById(id)
        .then((telehealth) => {
          setLoading(false)
          showPreferredModal()
          telehealth.referrer = {
            id: id,
            path: `/telehealth/${id}/details`,
            pathName: 'Telehealth'
          }
          setTelehealth(telehealth)
        })
        .catch(() => {
          setLoading(false)
          history.push('/telehealth')
          dispatch(
            alertActions.error(
              'An error occurred and we could not display your telehealth appointment details at this time. Please try again.'
            )
          )
        })
    } else {
      setLoading(false)
      dispatch(
        alertActions.error(
          'An error occurred and we could not display your telehealth appointment details at this time. Please try again.'
        )
      )
      history.push('/telehealth')
    }
  }, [])

  const showPreferredModal = () => {
    if (
      history.location.state &&
      history.location.state.prompt === 'preferred'
    ) {
      setTimeout(() => {
        dispatch(authenticationActions.displayPreferredPlatform('TELEHEALTH'))
      }, 1500)
    }
  }

  const messageProvider = () => {
    history.push(`/messages/stream`, { doctorId: doctor })
  }

  const onClick = (telehealth) => {
    if (telehealth.status === 'SCHEDULED') {
      window.alert(
        t(
          `Your healthcare provider would like the information to be completed at a specific time.`
        )
      )
    } else if (telehealth.status === 'SENT') {
      history.push(`/telehealth/consent`, {
        id: telehealth.id,
        type: 'telehealth',
        referrer: telehealth.referrer,
        telehealth: telehealth
      })
    } else if (
      telehealth.status === 'HOLD' ||
      telehealth.status === 'READY' ||
      telehealth.status === 'COMPLETED'
    ) {
      history.push(`/telehealth/${telehealth.id}/encounter`, {
        referrer: telehealth.referrer,
        telehealth: telehealth
      })
    } else if (telehealth.status === 'EXPIRED') {
      setDoctor(telehealth.doctor.id)
      setShowExpired(true)
    } else if (telehealth.status === 'CANCELED') {
      setDoctor(telehealth.doctor.id)
      setShowCanceled(true)
    }
  }

  return (
    <Row>
      <Col lg={2} className='pt-1 px-0 d-none d-lg-block'>
        <MainMenu />
      </Col>
      {loading ? (
        <Col>
          <Spinner animation='border' className='mt-2' />
        </Col>
      ) : (
        telehealth !== null && (
          <Col xs={12} lg={10}>
            {history.location.state &&
              history.location.state.preQuestionsSubmitted && (
                <Row>
                  <Col>
                    <Alert variant='success'>
                      {t(
                        'You are now ready for your telehealth appointment on'
                      )}
                      &nbsp;
                      <b className='main__text'>
                        {moment
                          .tz(
                            history.location.state.date,
                            history.location.state.timezone
                          )
                          .local()
                          .format('MMMM D, YYYY [@] h:mm A')}
                      </b>
                    </Alert>
                  </Col>
                </Row>
              )}
            <Row>
              <Col xs={12}>
                <Breadcrumb>
                  <Breadcrumb.Item onClick={() => history.push('/home')}>
                    {t('Home')}
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>{t('Details')}</Breadcrumb.Item>
                </Breadcrumb>
              </Col>
              <Col xs={12}>
                <TelehealthModalExpired
                  show={showExpired}
                  message={messageProvider}
                  hide={() => setShowExpired(false)}
                />

                <TelehealthModalCanceled
                  show={showCanceled}
                  message={messageProvider}
                  hide={() => setShowCanceled(false)}
                />

                <TelehealthCardDetails
                  telehealth={telehealth}
                  handleClick={() => onClick(telehealth)}
                />
              </Col>
            </Row>
          </Col>
        )
      )}
    </Row>
  )
}
