import Logo from '../../images/logo-alt@3x.png'
import React, { useEffect } from 'react'
import Alert from '../../_components/Alerts/Alert'
import Sidebar from './Sidebar'
import useWindowDimensions from '../../_helpers/dimensions'
import LanguageLinkButton from '../../_components/Language/LanguageLinkButton'

import { Image } from 'react-bootstrap'
import { useSelector } from 'react-redux'

import { setCookie } from '../../_helpers/cookieJar'

import './styles.scss'

const Deeplink = (props) => {
  const { width, breakPointMD } = useWindowDimensions()
  const alert = useSelector((state) => state.alert.nextRouteMessage)

  useEffect(() => {
    if (props.provider && props.provider.logo) {
      setCookie('client-logo', props.provider.logo, { maxAge: 600 })
      setCookie('client-name', props.provider.name, { maxAge: 600 })
    }
  }, [props.provider])

  return (
    <div className='container' style={{ height: '100vh' }}>
      <div className='row h-100'>
        <div className='col my-auto'>
          <div className='text-center d-lg-none'>
            {!props.loading &&
              props.loading !== undefined &&
              (props.provider ? (
                <Image
                  className='my-3'
                  fluid
                  src={props.provider && props.provider.logo}
                />
              ) : (
                <Image
                  className='my-3'
                  width='80'
                  height='80'
                  fluid
                  src={Logo}
                />
              ))}
          </div>
          <div className={`card ${width > breakPointMD && 'shadow'}`}>
            <div className='row'>
              <div
                className='col-lg-4 d-lg-flex d-none py-4'
                style={{ backgroundColor: '#1AB2E9' }}
              >
                <Sidebar loading={props.loading} provider={props.provider} />
              </div>
              <div
                className='col-lg-8 m-4 m-lg-0 py-lg-4 px-lg-5'
                style={{
                  display: 'flex',
                  alignContent: 'center',
                  flexDirection: 'column'
                }}
              >
                <Alert alert={alert} />

                {props.children}
              </div>
            </div>
          </div>

          <div className='my-4 my-sm-5'>
            <LanguageLinkButton />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Deeplink
