export const messageConstants = {
  CONVERSATIONS_GET_REQUEST: 'CONVERSATIONS_GET_REQUEST',
  CONVERSATIONS_GET_SUCCESS: 'CONVERSATIONS_GET_SUCCESS',
  CONVERSATIONS_GET_FAILURE: 'CONVERSATIONS_GET_FAILURE',

  CONVERSATION_GET_REQUEST: 'CONVERSATION_GET_REQUEST',
  CONVERSATION_GET_FAILURE: 'CONVERSATION_GET_FAILURE',
  CONVERSATION_GET_SUCCESS: 'CONVERSATION_GET_SUCCESS',

  MESSAGES_GET_REQUEST: 'MESSAGES_GET_REQUEST',
  MESSAGES_GET_SUCCESS: 'MESSAGES_GET_SUCCESS',
  MESSAGES_GET_FAILURE: 'MESSAGES_GET_FAILURE',

  MESSAGES_INIT_STATE: 'MESSAGES_INIT_STATE',
  MESSAGES_ADD: 'MESSAGES_ADD'
}
