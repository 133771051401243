import apiVersion from '../_helpers/api-version'
import axiosInstance from '../_helpers/axiosConfig'

export const registrationService = {
  register,
  usernameExists,
  sendVerifyCode,
  deepLinkRegister
}

function usernameExists(username) {
  return axiosInstance
    .request({
      method: 'POST',
      url: `${
        process.env.REACT_APP_API_URL
      }/${apiVersion()}/patient/registration/username`,
      data: { username: username }
    })
    .then((response) => {
      return response.data.exists
    })
}

function sendVerifyCode(type, phoneNumber = '', email = '') {
  return axiosInstance
    .request({
      method: 'POST',
      url: `${
        process.env.REACT_APP_API_URL
      }/${apiVersion()}/patient/registration/verification`,
      data: { type: type, phoneNumber: phoneNumber, email: email }
    })
    .then((response) => {
      return response.data
    })
}

function register(
  fname,
  lname,
  email,
  terms,
  gender,
  username,
  password,
  phoneNumber,
  dateOfBirth,
  verifyType,
  verifyCode,
  licensedUser
) {
  return axiosInstance
    .request({
      method: 'POST',
      url: `${
        process.env.REACT_APP_API_URL
      }/${apiVersion()}/patient/registration`,
      data: {
        firstName: fname,
        lastName: lname,
        email: email,
        terms: terms,
        gender: gender,
        username: username,
        password: password,
        dateOfBirth: dateOfBirth,
        phoneNumber: phoneNumber,
        type: verifyType,
        code: verifyCode,
        licensedUser
      }
    })
    .then((response) => {
      return response.data
    })
}

function deepLinkRegister(
  firstName,
  lastName,
  dateOfBirth,
  gender,
  username,
  password,
  terms,
  verifiedType,
  verifiedToken
) {
  return axiosInstance
    .request({
      method: 'POST',
      url: `${
        process.env.REACT_APP_API_URL
      }/${apiVersion()}/patient/registration/deep-link`,
      data: {
        firstName,
        lastName,
        dateOfBirth,
        gender,
        terms,
        username,
        password,
        verifiedType,
        verifiedToken
      }
    })
    .then((response) => {
      return response.data
    })
}
