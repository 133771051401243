import React from 'react'
import RenderValidateMessage from '../Validation/RenderValidateMessage'

import { Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

export default function EmailInput(props) {
  const {
    errors,
    value,
    label,
    setValue,
    valueName,
    handleEnter,
    disabled = false
  } = props
  const { t } = useTranslation()

  return (
    <Form.Group>
      {label && (
        <Form.Label className={valueName in errors ? 'text-danger' : ''}>
          {t(label)}
        </Form.Label>
      )}
      <Form.Control
        type='email'
        value={value}
        disabled={disabled}
        onKeyDown={handleEnter}
        isInvalid={valueName in errors}
        onChange={(e) => setValue({ [valueName]: e.target.value })}
      />
      <RenderValidateMessage errors={errors} field={valueName} />
    </Form.Group>
  )
}
