import { useState, useEffect } from 'react'

export function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height
  }
}

export function getCurrentBreakpoint() {
  const { innerWidth: width } = window
  if (width >= 1200) return 'xl'
  if (width < 1200 && width >= 992) return 'lg'
  if (width < 992 && width >= 768) return 'md'
  if (width < 768 && width >= 576) return 'sm'
  if (width < 576) return 'xs'
}

export default function useWindowDimensions() {
  const [breakPoint, setBreakPoint] = useState(getCurrentBreakpoint())
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  )

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions())
      setBreakPoint(getCurrentBreakpoint())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return {
    breakPointLG: 1200,
    breakPointMD: 992,
    breakPointSM: 768,
    breakPointXS: 576,
    breakPointCurrent: breakPoint,
    ...windowDimensions
  }
}
