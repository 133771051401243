import apiVersion from '../_helpers/api-version'
import axiosInstance from '../_helpers/axiosConfig'
import { authHeader } from '../_helpers/auth-header'

export const notificationService = {
  getAll
}

function getAll() {
  return axiosInstance
    .request({
      method: 'GET',
      headers: authHeader(),
      url: `${
        process.env.REACT_APP_API_URL
      }/${apiVersion()}/patient/notifications`
    })
    .then((response) => {
      return response.data
    })
}
