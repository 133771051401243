import { messageConstants as constants } from '../_constants/message.constants'

const initialState = {
  messages: [],
  conversation: null,
  conversations: null,
  messagesLoading: false,
  messagesLoadFailed: false,
  conversationsLoadFailed: false
}

export function message(state = initialState, action) {
  if (action.type === constants.MESSAGES_INIT_STATE) {
    return {
      messages: [],
      conversation: null,
      conversations: null,
      messagesLoading: false,
      messagesLoadFailed: false,
      conversationsLoadFailed: false
    }
  }

  if (action.type === constants.MESSAGES_ADD) {
    return {
      ...state,
      messages: [action.message, ...state.messages]
    }
  }

  if (action.type === constants.MESSAGES_GET_SUCCESS) {
    return {
      ...state,
      messages: action.messages,
      messagesLoading: false
    }
  }

  if (action.type === constants.MESSAGES_GET_REQUEST) {
    return {
      ...state,
      messagesLoading: true
    }
  }

  if (action.type === constants.MESSAGES_GET_FAILURE) {
    return {
      ...state,
      messagesLoadFailed: true,
      messagesLoading: false
    }
  }

  if (action.type === constants.CONVERSATION_GET_SUCCESS) {
    return {
      ...state,
      conversation: action.conversation
    }
  }

  if (action.type === constants.CONVERSATIONS_GET_SUCCESS) {
    return {
      ...state,
      conversations: action.conversations
    }
  }

  if (action.type === constants.CONVERSATIONS_GET_FAILURE) {
    return {
      ...state,
      conversationsLoadFailed: true
    }
  }

  return state
}
