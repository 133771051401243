import { user } from './user.reducer'
import { form } from './form.reducer'
import { alert } from './alert.reducer'
import { doctor } from './doctor.reducer'
import { message } from './message.reducer'
import { loading } from './loading.reducer'
import { deepLink } from './deeplink.reducer'
import { language } from './language.reducer'
import { register } from './register.reducer'
import { carePlan } from './care_plan.reducer'
import { telehealth } from './telehealth.reducer'
import { notification } from './notification.reducer'
import { authentication } from './authentication.reducer'
import { combineReducers } from 'redux'

const appReducer = combineReducers({
  user,
  form,
  alert,
  doctor,
  message,
  loading,
  register,
  language,
  carePlan,
  deepLink,
  telehealth,
  notification,
  authentication
})

const rootReducer = (state, action) => {
  if (action.type === 'AUTHENTICATION_LOGOUT') {
    state = {
      authentication: {
        hasAccount: true,
        username: state.authentication.username,
        showPassword: state.authentication.showPassword,
        rememberUsername: state.authentication.rememberUsername,
        preferredPlatform: state.authentication.preferredPlatform,
        preferredTimestamp: state.authentication.preferredTimestamp
      }
    }
  }
  return appReducer(state, action)
}

export default rootReducer
