import React, { useState, useEffect } from 'react'
import MainMenu from '../../../_components/MainMenu'

import { history } from '../../../_helpers/history'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { alertActions } from '../../../_actions/alert.actions'
import { formsService } from '../../../_services/forms.service'
import { useTranslation } from 'react-i18next'
import { Spinner, Row, Col } from 'react-bootstrap'
import { authenticationActions } from '../../../_actions/authentication.actions'

import {
  FormCardBase,
  FormModalExpired,
  FormModalCanceled
} from '@axshealth/design-library'

export default function FormDetails() {
  const dispatch = useDispatch(null)
  const { t } = useTranslation()
  const { id } = useParams()

  const [form, setForm] = useState(null)
  const [doctor, setDoctor] = useState(null)
  const [loading, setLoading] = useState(false)
  const [showExpired, setShowExpired] = useState(false)
  const [showCanceled, setShowCanceled] = useState(false)

  useEffect(() => {
    if (id) {
      setLoading(true)
      formsService
        .getById(id)
        .then((form) => {
          setLoading(false)
          showPreferredModal()
          form.referrer = {
            id: id,
            path: `/forms/${id}/details`,
            pathName: 'Form'
          }
          form.questionnaire = {
            title: form.questionnaire.resource.title,
            ...form.questionnaire
          }
          setForm(form)
        })
        .catch(() => {
          setLoading(false)
          history.push('/forms')
          dispatch(
            alertActions.error(
              'An error occurred and we could not display your form details at this time. Please try again.'
            )
          )
        })
    } else {
      history.push('/forms')
      dispatch(
        alertActions.error(
          'An error occurred and we could not display your form details at this time. Please try again.'
        )
      )
    }
  }, [])

  const showPreferredModal = () => {
    if (
      history.location.state &&
      history.location.state.prompt === 'preferred'
    ) {
      setTimeout(() => {
        dispatch(authenticationActions.displayPreferredPlatform('FORMS'))
      }, 2000)
    }
  }

  const messageProvider = () => {
    history.push(`/messages/stream`, { doctorId: doctor })
  }

  const onClick = (form) => {
    if (form.status === 'SCHEDULED') {
      window.alert(
        t(
          `Your healthcare provider would like the information to be completed at a specific time.`
        )
      )
    } else if (form.status === 'SENT' || form.status === 'READY') {
      history.push(`/forms/questions`, {
        id: form.id,
        type: 'form',
        referrer: form.referrer
      })
    } else if (form.status === 'COMPLETED') {
      history.push(`/forms/view`, { id: form.id, referrer: form.referrer })
    } else if (form.status === 'EXPIRED') {
      setDoctor(form.doctor.id)
      setShowExpired(true)
    } else if (form.status === 'CANCELED') {
      setDoctor(form.doctor.id)
      setShowCanceled(true)
    }
  }

  return (
    <Row>
      <Col lg={2} className='pt-1 px-0 d-none d-lg-block'>
        <MainMenu />
      </Col>
      {loading ? (
        <Col>
          <Spinner animation='border' className='mt-2' />
        </Col>
      ) : (
        form !== null && (
          <Col xs={12} lg={10}>
            <FormModalExpired
              show={showExpired}
              message={messageProvider}
              hide={() => setShowExpired(false)}
            />
            <FormModalCanceled
              show={showCanceled}
              message={messageProvider}
              hide={() => setShowCanceled(false)}
            />

            <Row>
              <Col xs={12}>
                <FormCardBase
                  form={form}
                  handleClick={() => {
                    onClick(form)
                  }}
                />
              </Col>
            </Row>
          </Col>
        )
      )}
    </Row>
  )
}
