import React, { useState, useEffect } from 'react'
import Alert from '../../../_components/Alerts/Alert'
import useWindowDimensions from '../../../_helpers/dimensions'
import ChangeFamilyMemberModal from '../../FamilyMembers/Components/ChangeFamilyMember'

import { Prompt } from 'react-router-dom'
import { history } from '../../../_helpers/history'
import { alertActions } from '../../../_actions/alert.actions'
import { useTranslation } from 'react-i18next'
import { telehealthActions } from '../../../_actions/telehealth.actions'
import { telehealthService } from '../../../_services/telehealth.service'
import { useDispatch, useSelector } from 'react-redux'
import { Spinner, Alert as AlertBS, Row, Col } from 'react-bootstrap'

import {
  FormQuestionnaireComplete,
  TelehealthButtonChangeFamilyMember
} from '@axshealth/design-library'

export default function PreVisitQuestions() {
  const dispatch = useDispatch(null)
  const { t } = useTranslation()
  const { width, breakPointMD } = useWindowDimensions()

  const [changing, setChanging] = useState(false)
  const [questions, setQuestions] = useState(false)
  const [telehealth, setTelehealth] = useState(null)
  const [leavePrompt, setLeavePrompt] = useState(true)
  const [alertDetails, setAlertDetails] = useState({})
  const [showChangeModal, setShowChangeModal] = useState(false)

  const id = history.location.state && history.location.state.id
  const referrer = history.location.state && history.location.state.referrer

  const dependents = useSelector((state) => state.user.dependents || [])
  const isSubmitting = useSelector((state) => state.telehealth.isSubmitting)
  const currentUser = useSelector((state) => state.user.currentUser)

  useEffect(() => {
    if (!id) {
      history.push('/telehealth')
      dispatch(
        alertActions.error(
          'An error occurred and we could not get your telehealth pre-visit questions at this time. Please try again.'
        )
      )
    } else {
      getTelehealth()
    }
  }, [])

  const getTelehealth = () => {
    telehealthService
      .getById(id)
      .then((response) => {
        setTelehealth(response)
        getPreVisitQuestions()
      })
      .catch(() => {
        window.alert(
          t(
            'We could not retrieve your telehealth pre-visit questions at this time. Please try again later'
          )
        )
        history.push('/telehealth')
      })
  }

  const getPreVisitQuestions = () => {
    window.scroll(0, 0)
    telehealthService
      .getQuestions(id)
      .then((response) => {
        const form = { questionnaire: response }
        setQuestions(form)
      })
      .catch(() => {
        window.alert(
          t(
            'We could not retrieve your telehealth pre-visit questions at this time. Please try again later'
          )
        )
        history.push('/telehealth')
      })
  }

  const toggleChangeFamilyMemberModal = () => {
    setShowChangeModal(!showChangeModal)
  }

  const handleChangeFamilyMember = (userProfileId) => {
    setAlertDetails({})
    setChanging(true)

    telehealthService
      .updatePatient(id, userProfileId)
      .then(() => {
        getTelehealth()
        setShowChangeModal(false)
        setChanging(false)
        setAlertDetails({ type: 'success', message: 'Family member changed.' })
      })
      .catch(() => {
        setChanging(false)
        setShowChangeModal(false)
        setAlertDetails({
          type: 'danger',
          message:
            'We could not confirm your selection at this time. Check your connection and try again.'
        })
      })
  }

  const handleSubmitPreVisitQuestions = (preVisitQuestions) => {
    setLeavePrompt(false)
    dispatch(
      telehealthActions.preVisitQuestionsCompleted(
        id,
        telehealth,
        preVisitQuestions,
        referrer
      )
    )
  }

  const handleClickDisplay = (url) => {
    window.open(url, '_blank')
  }

  const patients = [currentUser, ...dependents]

  return (
    <>
      {!questions || !telehealth ? (
        <Spinner animation='border' />
      ) : (
        <Row>
          <Prompt
            when={leavePrompt}
            message={t(
              'Are you sure you want to leave? Your answers will not be saved.'
            )}
          />

          <ChangeFamilyMemberModal
            show={showChangeModal}
            changing={changing}
            patients={patients}
            selected={telehealth.userProfile.id}
            handleClose={toggleChangeFamilyMemberModal}
            changePatient={handleChangeFamilyMember}
          />

          <Col lg='3' className='d-none d-lg-block'>
            <TelehealthButtonChangeFamilyMember
              telehealth={telehealth}
              showChangeFamilyMember={telehealth && !telehealth.eventId}
              handleShowChangeFamilyMember={toggleChangeFamilyMemberModal}
            />
          </Col>

          <Col>
            <Row>
              <Col xs='12'>
                <Alert alert={alertDetails} dismissible />
                <AlertBS variant='primary'>
                  {t(
                    'Your healthcare provider has requested the following questions to be completed prior to your appointment.'
                  )}
                </AlertBS>
              </Col>
              <Col xs='12' className='d-block d-lg-none mb-3'>
                <TelehealthButtonChangeFamilyMember
                  telehealth={telehealth}
                  showChangeFamilyMember={telehealth && !telehealth.eventId}
                  handleShowChangeFamilyMember={toggleChangeFamilyMemberModal}
                />
              </Col>
              <Col>
                <FormQuestionnaireComplete
                  form={Object.assign(telehealth, questions)}
                  isSubmitting={isSubmitting}
                  showSubmitInForm={width < breakPointMD}
                  handleSubmitForm={handleSubmitPreVisitQuestions}
                  handleClickDisplay={handleClickDisplay}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </>
  )
}
