import React from 'react'
import ActionButton from './ActionButton'

export default function StepButton(props) {
  const {
    loading,
    nextAction,
    previousAction,
    nextTitle,
    previousTitle
  } = props

  return (
    <div className='row' style={{ flexWrap: 'wrap-reverse' }}>
      <div className='col-md-4 pt-4 pt-md-0'>
        <ActionButton
          variant='outline-primary'
          action={previousTitle}
          submit={previousAction}
          loading={false}
        />
      </div>
      <div className='col-md-8'>
        <ActionButton
          variant='success'
          action={nextTitle}
          submit={nextAction}
          loading={loading}
        />
      </div>
    </div>
  )
}
