export const DisplayFullNameByUserType = (user) => {
  if (!user.UserType) {
    return ''
  }

  const prefix = user.UserType.Prefix ? `${user.UserType.Prefix} ` : ''
  const suffix = user.UserType.Suffix ? `, ${user.UserType.Suffix}` : ''

  return `${prefix}${user.FirstName} ${user.LastName}${suffix}`
}

export const VisitFullName = (data) => {
  const prefix = data.UserTypePrefix ? `${data.UserTypePrefix} ` : ''
  const suffix = data.UserTypeSuffix ? `, ${data.UserTypeSuffix}` : ''

  return `${prefix}${data.DoctorUserFirstName} ${data.DoctorUserLastName}${suffix}`
}

export const ConsentFullName = (user) => {
  // eslint-disable-next-line no-prototype-builtins
  if (user.hasOwnProperty('userType')) {
    const prefix = user.userType.prefix ? `${user.userType.prefix} ` : ''
    const suffix = user.userType.suffix ? `, ${user.userType.suffix}` : ''

    return `${prefix}${user.firstName} ${user.lastName}${suffix}`
  }

  return ''
}

export const TreatmentPlanFullName = (data) => {
  if (!data.UserType) {
    return ''
  }

  const prefix = data.UserType.Prefix ? `${data.UserType.Prefix} ` : ''
  const suffix = data.UserType.Suffix ? `, ${data.UserType.Suffix}` : ''

  return `${prefix}${data.DoctorUserFirstName} ${data.DoctorUserLastName}${suffix}`
}
