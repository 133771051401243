import React from 'react'
import { Route } from 'react-router-dom'

export default ({
  component: Component,
  layout: Layout,
  props: cProps,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => {
      if (!Layout) {
        return <Component {...props} {...cProps} />
      }

      return (
        <Layout>
          <Component {...props} {...cProps} />
        </Layout>
      )
    }}
  />
)
