import React from 'react'
import Alert from '../../_components/Alerts/Alert'
import moment from 'moment'
import EmailInput from '../../_components/Inputs/EmailInput'
import ActionButton from '../../_components/Buttons/ActionButton'
import DateOfBirthInput from '../../_components/Inputs/DateOfBirthInput'

import { Form } from 'react-bootstrap'
import { connect } from 'react-redux'
import { alertActions } from '../../_actions/alert.actions'
import { forgotService } from '../../_services/forgot.service'
import { withTranslation } from 'react-i18next'
import { authenticationService } from '../../_services/authentication.service'
import { isValidEmail, isValidPhone } from '../../_helpers/regex'
import { handleEnter, handleNextInput } from '../../_helpers/handleEnter'

class ForgotUsernamePage extends React.Component {
  constructor(props) {
    super(props)

    if (authenticationService.currentUserValue) {
      this.props.history.push('/')
    }

    this.state = {
      user: '',
      type: '',
      errors: [],
      loading: false,
      userFound: null,
      dateOfBirth: ''
    }
    this.validate = this.validate.bind(this)
    this.handleSetState = this.handleSetState.bind(this)
    this.handleFindUsername = this.handleFindUsername.bind(this)
  }

  validate(e) {
    e.preventDefault()

    let type = ''
    let user = this.state.user
    const errors = {}
    const { dateOfBirth } = this.state

    if (dateOfBirth.length === 0) {
      errors.dateOfBirth = 'Date of Birth is required.'
    } else if (dateOfBirth.replace(/\D/g, '').length !== 8) {
      errors.dateOfBirth = 'Invalid format ex. 03/15/1980.'
    } else if (!moment(dateOfBirth, 'MM/DD/YYYY').isValid()) {
      errors.dateOfBirth = 'Date of Birth is not valid.'
    }

    if (user.length === 0) {
      errors.user = 'Mobile Phone or Email is required.'
    } else if (isValidEmail(user)) {
      type = 'email'
    } else if (isValidPhone(user)) {
      user = user.match(/\d/g).join('')
      type = 'phoneNumber'
    } else {
      errors.user = 'Please enter a valid email address or phone.'
    }

    this.setState({ errors })

    if (!errors.user && !errors.dateOfBirth) {
      this.toggleLoading()
      this.handleFindUsername(user, type, dateOfBirth)
    }
  }

  handleFindUsername(user, type, dateOfBirth) {
    const date = dateOfBirth.split('/')
    const dob = date[2] + '-' + date[0] + '-' + date[1]

    forgotService
      .findUsername(user, type, dob)
      .then((response) => {
        this.props.dispatch(alertActions.success('Username Found'))
        this.setState({ userFound: true })
        this.toggleLoading()
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          this.props.dispatch(alertActions.error('Username Not Found'))
          this.setState({ userFound: false })
        } else {
          this.props.dispatch(
            alertActions.error('An error occurred. Please try again.')
          )
        }
        this.toggleLoading()
      })
  }

  toggleLoading = () => {
    this.setState((prevState) => {
      return {
        ...prevState,
        loading: !prevState.loading
      }
    })
  }

  handleSetState(value) {
    this.setState(value)
  }

  render() {
    const { t, alert } = this.props
    const { user, errors, loading, dateOfBirth, userFound } = this.state

    return (
      <div className='p-4'>
        <h3 className='text-center py-3'>{t('Forgot Username')}</h3>
        {userFound === true && <Alert alert={alert} />}

        {userFound !== true && (
          <Form>
            <p className='text-center'>{t('Forgot Username Instructions')}</p>
            <Alert alert={alert} />

            <DateOfBirthInput
              errors={errors}
              setDOB={this.handleSetState}
              dateOfBirth={dateOfBirth}
              handleEnter={handleNextInput}
            />

            <EmailInput
              value={user}
              errors={errors}
              label='Mobile Phone or Email'
              valueName='user'
              setValue={this.handleSetState}
              handleEnter={(e) => (handleEnter(e) ? this.validate(e) : '')}
            />

            <div className='form-group pt-3'>
              <ActionButton
                action='Next'
                variant='success'
                submit={this.validate}
                loading={loading}
              />
            </div>
          </Form>
        )}
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    alert: state.alert.nextRouteMessage
  }
}
export default connect(mapStateToProps)(withTranslation()(ForgotUsernamePage))
