import React from 'react'
import Alert from '../../../_components/Alerts/Alert'
import TextInput from '../../../_components/Inputs/TextInput'
import ActionButton from '../../../_components/Buttons/ActionButton'

import { Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { handleEnter } from '../../../_helpers/handleEnter'

export default function ForgotPasswordForm(props) {
  const { alert, errors, loading, username, handleFindUser, handleSetState } =
    props
  const { t } = useTranslation()

  return (
    <Form>
      <p className='text-center pt-4'>{t('Forgot Password Instructions')}</p>
      <Alert alert={alert} />
      <div>
        <div className='py-4'>
          <TextInput
            errors={errors}
            value={username}
            handleEnter={(e) => (handleEnter(e) ? handleFindUser(e) : '')}
            valueName='username'
            label='Username'
            setValue={handleSetState}
          />
        </div>
        <div className='form-group pt-sm-5'>
          <ActionButton
            action='Next'
            variant='success'
            submit={handleFindUser}
            loading={loading}
          />
        </div>
      </div>
    </Form>
  )
}
