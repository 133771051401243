import React, { useState } from 'react'
import moment from 'moment'
import useWindowDimensions from '../../../_helpers/dimensions'

import { Modal, Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { userActions } from '../../../_actions/user.actions'
import { dependentService } from '../../../_services/dependent.service'
import { PatientAddFamilyMember } from '@axshealth/design-library'
import { useDispatch, useSelector } from 'react-redux'

export default function AddFamilyMember({
  showAddFamilyMember,
  hideAddFamilyMember
}) {
  const { t } = useTranslation()

  const [day, setDay] = useState('')
  const [year, setYear] = useState('')
  const [month, setMonth] = useState()
  const [gender, setGender] = useState('')
  const [errors, setErrors] = useState({})
  const [loading, setLoading] = useState(false)
  const [lastName, setLastName] = useState('')
  const [firstName, setFirstName] = useState('')
  const [dateOfBirth, setDateOfBirth] = useState('')

  const dispatch = useDispatch(null)
  const currentUser = useSelector((state) => state.user.currentUser)

  const { width, breakPointSM } = useWindowDimensions()
  const mobile = width < breakPointSM

  const handleHideAddFamilyMember = () => {
    setDay('')
    setYear('')
    setMonth('')
    setGender('')
    setErrors('')
    setLastName('')
    setFirstName('')
    setDateOfBirth('')
    hideAddFamilyMember()
  }

  const handleAddFamilyMember = () => {
    const errors = {}

    if (firstName.trim() === '') {
      errors.firstName = 'First name is required.'
    }
    if (lastName.trim() === '') {
      errors.lastName = 'Last name is required.'
    }
    if (gender.length === 0) {
      errors.gender = 'Gender is required.'
    }
    if (dateOfBirth.length === 0) {
      errors.dateOfBirth = 'Date of Birth is required.'
    }

    setErrors(errors)

    if (
      !errors.firstName &&
      !errors.lastName &&
      !errors.gender &&
      !errors.dateOfBirth
    ) {
      const familyMember = {
        gender,
        lastName,
        firstName,
        dateOfBirth: moment(dateOfBirth).format('YYYY-MM-DD')
      }

      setLoading(true)

      dependentService
        .addDependent(currentUser.userId, familyMember)
        .then(() => {
          setLoading(false)
          handleHideAddFamilyMember()
          dispatch(userActions.storeUser())
          window.alert(t('Family Member Added!'))
        })
        .catch((error) => {
          setLoading(false)
          window.alert(
            t(
              'Family Member could not be added at this time. Please check your connection and try again.'
            )
          )
          console.log(error.response)
        })
    }
  }

  return (
    <Modal
      size='lg'
      show={showAddFamilyMember}
      onHide={handleHideAddFamilyMember}
      keyboard={false}
      centered={mobile}
    >
      <Modal.Header>
        <Modal.Title>{t('Add Family Member')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <PatientAddFamilyMember
          day={day}
          year={year}
          month={month}
          gender={gender}
          errors={errors}
          lastName={lastName}
          firstName={firstName}
          setDay={(day) => setDay(day)}
          setYear={(year) => setYear(year)}
          setMonth={(month) => setMonth(month)}
          setGender={(gender) => setGender(gender)}
          setLastName={(lastName) => setLastName(lastName)}
          setFirstName={(firstName) => setFirstName(firstName)}
          setDateOfBirth={(dateOfBirth) => setDateOfBirth(dateOfBirth)}
        />
      </Modal.Body>
      <Modal.Footer
        style={
          mobile ? { flexDirection: 'column-reverse', flexWrap: 'nowrap' } : {}
        }
      >
        <Button
          block={mobile}
          disabled={loading}
          variant='outline-primary'
          onClick={handleHideAddFamilyMember}
        >
          {t('Back')}
        </Button>
        <Button
          block={mobile}
          disabled={loading}
          variant='success'
          onClick={handleAddFamilyMember}
        >
          {loading ? `${t('Adding')}...` : t('Add Family Member')}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
