import apiVersion from '../_helpers/api-version'
import axiosInstance from '../_helpers/axiosConfig'

export const forgotService = {
  sendVerifyCode,
  resetPassword,
  findUsername
}

function findUsername(user, type, dateOfBirth) {
  return axiosInstance
    .request({
      method: 'POST',
      url: `${
        process.env.REACT_APP_API_URL
      }/${apiVersion()}/patient/forgot-username`,
      data: { [type]: user, dateOfBirth: dateOfBirth }
    })
    .then((response) => {
      return response.data.data
    })
}

function sendVerifyCode(user) {
  return axiosInstance
    .request({
      method: 'POST',
      url: `${
        process.env.REACT_APP_API_URL
      }/${apiVersion()}/patient/forgot-password`,
      data: { username: user }
    })
    .then((response) => {
      return response.data.data
    })
}

function resetPassword(user, newPassword, code) {
  return axiosInstance
    .request({
      method: 'PATCH',
      url: `${
        process.env.REACT_APP_API_URL
      }/${apiVersion()}/patient/forgot-password`,
      data: { username: user, password: newPassword, code: code }
    })
    .then((response) => {
      return response
    })
}
