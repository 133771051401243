import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import moment from 'moment'

import AxSLogo from '../../images/logo@3x.png'
import AxSLogoAlt from '../../images/logo-alt@3x.png'
import { authenticationService } from '../../_services/authentication.service'
import VerifiedForm from './Components/VerifiedForm'
import LanguageButton from '../../_components/Language/LanguageLinkButton'
import { isValidPassword } from '../../_helpers/regex'
import { registrationService } from '../../_services/registration.service'

import { userActions } from '../../_actions/user.actions'
import { alertActions } from '../../_actions/alert.actions'
import { UNPROCESSABLE_ENTITY } from 'http-status'
import { getCookie } from '../../_helpers/cookieJar'

class VerifiedPage extends React.Component {
  constructor(props) {
    super(props)

    if (
      !props.location.state ||
      !props.location.state.formData ||
      !props.location.state.verifiedType ||
      !props.location.state.verifiedToken
    ) {
      this.props.history.push('/')
    }

    if (authenticationService.currentUserValue) {
      this.props.history.push('/')
    }

    this.state = {
      firstName:
        props.location.state && props.location.state.formData
          ? props.location.state.formData.firstName
          : '',
      lastName:
        props.location.state && props.location.state.formData
          ? props.location.state.formData.lastName
          : '',
      dateOfBirth: moment(
        props.location.state && props.location.state.formData
          ? props.location.state.formData.dateOfBirth
          : null,
        'YYYY-MM-DD'
      ).format('MM/DD/YYYY'),
      gender: '',
      username: '',
      password: '',
      terms: false,
      errors: {},
      loading: false,
      logo: null,
      logoAlt: null,
      clientName: null
    }

    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount() {
    const clientLogo = getCookie('client-logo')
    const clientName = getCookie('client-name')

    this.setState({
      logo: clientLogo || AxSLogo,
      logoAlt: clientLogo || AxSLogoAlt,
      clientName: clientName || 'Your healthcare provider'
    })
  }

  handleSetUsername = (value) => {
    const errors = {}
    this.setState(value)

    if (!this.isValidUsername(value.username)) {
      errors.username =
        'Username can not have spaces and must be all lowercase characters.'
    }
    this.setState({ errors })
  }

  isValidUsername = (username) => {
    const hasSpace = /\s+/
    const hasUpperCase = /[A-Z]+/
    return !(hasSpace.test(username) || hasUpperCase.test(username))
  }

  handleSubmit() {
    const errors = {}

    this.setState({ errors, loading: true })

    const {
      firstName,
      lastName,
      gender,
      username,
      password,
      dateOfBirth,
      terms
    } = this.state

    if (firstName.length === 0) {
      errors.firstName = this.props.t('First name is required.')
    }

    if (lastName.length === 0) {
      errors.lastName = this.props.t('Last name is required.')
    }

    if (gender.length === 0) {
      errors.gender = this.props.t('Gender is required.')
    }

    if (username.length === 0) {
      errors.username = this.props.t('Username is required.')
    }

    if (password.length === 0) {
      errors.password = this.props.t('Password is required.')
    }

    if (this.isValidUsername(username) === false) {
      errors.username =
        'Username can not have spaces and must be all lowercase characters.'
    }

    if (isValidPassword(password) === false) {
      errors.password = this.props.t('Password is invalid.')
    }

    if (dateOfBirth.length === 0) {
      errors.dateOfBirth = this.props.t('Date of Birth is required.')
    } else if (dateOfBirth.replace(/\D/g, '').length !== 8) {
      errors.dateOfBirth = this.props.t('Invalid format ex. 03/15/1980.')
    } else if (!moment(dateOfBirth, 'MM/DD/YYYY').isValid()) {
      errors.dateOfBirth = this.props.t('Date of Birth is not valid.')
    }

    if (!terms) {
      errors.terms = this.props.t('Please agree to the Terms of Use.')
    }

    if (
      !errors.firstName &&
      !errors.lastName &&
      !errors.dateOfBirth &&
      !errors.gender &&
      !errors.username &&
      !errors.password &&
      !errors.terms
    ) {
      this.registerUser()
    } else {
      this.setState({ errors, loading: false })
    }
  }

  registerUser() {
    registrationService
      .deepLinkRegister(
        this.state.firstName,
        this.state.lastName,
        this.state.dateOfBirth,
        this.state.gender,
        this.state.username,
        this.state.password,
        this.state.terms,
        this.props.location.state.verifiedType,
        this.props.location.state.verifiedToken
      )
      .then(() => {
        const referrer = this.props.location.state
          ? this.props.location.state.from
          : '/'

        this.props.dispatch(
          userActions.login(this.state.username, this.state.password, referrer)
        )
      })
      .catch((error) => {
        if (error.response && error.response.status === UNPROCESSABLE_ENTITY) {
          if (error.response.data.verifiedToken) {
            this.props.history.push('/deep-link')

            this.props.dispatch(
              alertActions.error(
                this.props.t(
                  'Please verify the date of birth since it has expired since the last verification.'
                )
              )
            )
          } else {
            this.setState({
              errors: error.response ? error.response.data : '',
              loading: false
            })
          }
        } else {
          this.props.dispatch(
            alertActions.error(
              this.props.t('An error occurred. Please try again.')
            )
          )
        }
      })
  }

  render() {
    const { t } = this.props

    return (
      <div className='container' style={{ height: '100vh' }}>
        <div className='row h-100'>
          <div className='col-12 my-auto'>
            <div className='text-center d-lg-none'>
              <img
                src={this.state.logoAlt}
                alt='AxS Logo'
                style={{ height: '5em' }}
              />
              <p className='mt-3 mb-4'>
                {t('Intro Client Copy', {
                  clientName: t(this.state.clientName)
                })}
              </p>
              <p>{t('Please create an account to continue.')}</p>
            </div>

            <div className='row'>
              <div
                className='col-12 col-lg-5 d-none d-lg-block p-0 text-white text-center'
                style={{ backgroundColor: '#1ab2e9' }}
              >
                <div className='p-5 mt-4 font-weight-light'>
                  <img
                    src={this.state.logo}
                    alt='Logo'
                    style={{ height: '5em' }}
                  />
                  <p className='mt-5'>
                    {t('Intro Client Copy', {
                      clientName: t(this.state.clientName)
                    })}
                  </p>
                  <p>{t('Please create an account to continue.')}</p>
                  <p className='mt-5'>{t('Already have an account?')}</p>
                  <Button
                    as={Link}
                    to='/login'
                    block
                    variant='light'
                    className='text-muted'
                  >
                    {t('Sign In')}
                  </Button>
                </div>
              </div>
              <div className='col-12 col-lg-7 px-lg-0'>
                <div className='card h-100'>
                  <div className='card-body p-lg-5'>
                    <h4 className='text-center'>{t('Create Account')}</h4>
                    <p
                      className='text-center font-weight-bold mt-3'
                      style={{ fontSize: '14px' }}
                    >
                      {t(
                        "Please enter primary account holder's information below. You will be able to add family members after you create your account"
                      )}
                    </p>

                    <VerifiedForm
                      values={{
                        firstName: this.state.firstName,
                        lastName: this.state.lastName,
                        dateOfBirth: this.state.dateOfBirth,
                        gender: this.state.gender,
                        username: this.state.username,
                        password: this.state.password
                      }}
                      errors={this.state.errors}
                      loading={this.state.loading}
                      handleChange={(value) => this.setState(value)}
                      handleSubmit={this.handleSubmit}
                      handleSetUsername={this.handleSetUsername}
                      handleTermsClick={(e) => {
                        e.preventDefault()

                        window.open(
                          'https://www.axshealthapp.com/terms-of-use/',
                          '_blank'
                        )
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='col-12'>
            <div className='text-center mt-4'>
              <LanguageButton />

              <small>v{process.env.REACT_APP_API_VERSION}</small>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    alert: state.alert.nextRouteMessage
  }
}
export default connect(mapStateToProps)(withTranslation()(VerifiedPage))
