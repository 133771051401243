import { doctorConstants as constants } from '../_constants/doctor.constants'

const initialState = {
  doctor: {},
  doctors: []
}

export function doctor(state = initialState, action) {
  if (action.type === constants.DOCTORS_GET_SUCCESS) {
    return {
      ...state,
      doctors: action.doctors
    }
  }
  if (action.type === constants.DOCTORS_GET_FAILURE) {
    return {
      ...state,
      doctorsLoadFailed: true
    }
  }
  if (action.type === constants.DOCTOR_GET_SUCCESS) {
    return {
      ...state,
      doctor: action.doctor
    }
  }
  return state
}
