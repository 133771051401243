import { alertConstants as constants } from '../_constants/alert.constants'

const initialState = {
  delayMessage: {},
  nextRouteMessage: {}
}

export function alert(state = initialState, action) {
  if (action.type === constants.WARNING) {
    return {
      ...state,
      nextRouteMessage: {
        type: 'warning',
        message: action.message,
        heading: action.heading
      }
    }
  }
  if (action.type === constants.INFO) {
    return {
      ...state,
      nextRouteMessage: {
        type: 'info',
        message: action.message,
        heading: action.heading
      }
    }
  }
  if (action.type === constants.SUCCESS) {
    return {
      ...state,
      nextRouteMessage: {
        type: 'success',
        message: action.message,
        heading: action.heading
      }
    }
  }
  if (action.type === constants.ERROR) {
    return {
      ...state,
      nextRouteMessage: {
        type: 'danger',
        message: action.message,
        heading: action.heading
      }
    }
  }
  if (action.type === constants.DELAY) {
    return {
      ...state,
      delayMessage: {
        type: action.color,
        message: action.message,
        heading: action.heading
      }
    }
  }
  if (action.type === constants.CLEAR_DELAY) {
    return {
      ...state,
      delayMessage: {}
    }
  }
  if (action.type === constants.CLEAR) {
    return {
      ...state,
      nextRouteMessage: {}
    }
  }
  return state
}
