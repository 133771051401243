import { deepLinkConstants as constants } from '../_constants/deeplink.constants'

const initialState = {
  data: {}
}

export function deepLink(state = initialState, action) {
  if (action.type === constants.DEEP_LINK_SET_DATA) {
    return {
      ...state,
      data: action.data
    }
  }

  return state
}
