import React, { Fragment, useState } from 'react'
import CheckBoxInput from '../Inputs/CheckBoxInput'
import RenderValidateMessage from '../Validation/RenderValidateMessage'

import { Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { handleNextInput } from '../../_helpers/handleEnter'

export default function ConfirmPasswordInput(props) {
  const {
    label,
    errors,
    password,
    setValue,
    valueName,
    handleEnter,
    confirmLabel,
    confirmPassword,
    confirmValueName,
    stacked = false,
    hideShowPassword = false
  } = props
  const [showPassword, setShowPassword] = useState(false)
  const { t } = useTranslation()

  return (
    <Fragment>
      <div className='row'>
        <div className={stacked ? 'col-12' : 'col-12 col-lg-6'}>
          <Form.Label
            className={`${valueName in errors ? 'text-danger' : ''} `}
          >
            {t(label)}
          </Form.Label>
          <Form.Control
            type={showPassword ? 'text' : 'password'}
            value={password}
            onKeyDown={handleNextInput}
            isInvalid={valueName in errors}
            onChange={(e) => setValue({ password: e.target.value })}
          />
          <RenderValidateMessage errors={errors} field={valueName} /> <br />
        </div>
        <div className='col'>
          <Form.Label
            className={confirmValueName in errors ? 'text-danger' : ''}
          >
            {t(confirmLabel)}
          </Form.Label>
          <Form.Control
            type={showPassword ? 'text' : 'password'}
            value={confirmPassword}
            onKeyDown={handleEnter}
            isInvalid={confirmValueName in errors}
            onChange={(e) => setValue({ confirmPassword: e.target.value })}
          />
          <RenderValidateMessage errors={errors} field={confirmValueName} />{' '}
        </div>
      </div>
      {!hideShowPassword && (
        <div className='row'>
          <div className='col'>
            <br />
            <CheckBoxInput
              text='Show Password'
              valueName='showPassword'
              value={showPassword}
              defaultChecked
              toggleValue={() => setShowPassword(!showPassword)}
            />
          </div>
        </div>
      )}
    </Fragment>
  )
}
