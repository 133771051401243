import React, { Fragment } from 'react'
import CheckBoxInput from '../Inputs/CheckBoxInput'
import RenderValidateMessage from '../Validation/RenderValidateMessage'

import { Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

export default function PasswordInput(props) {
  const {
    label,
    errors,
    password,
    valueName,
    setPassword,
    handleEnter,
    showPassword,
    setShowPassword,
    hideShowPassword = false
  } = props
  const { t } = useTranslation()

  return (
    <Form.Group>
      {label && (
        <Form.Label className={valueName in errors ? 'text-danger' : ''}>
          {t(label)}
        </Form.Label>
      )}
      <Form.Control
        type={showPassword ? 'text' : 'password'}
        value={password}
        onKeyDown={handleEnter}
        isInvalid={valueName in errors}
        onChange={(e) => setPassword({ password: e.target.value })}
      />
      <RenderValidateMessage errors={errors} field={valueName} />
      {!hideShowPassword && (
        <Fragment>
          <br />
          <CheckBoxInput
            text='Show Password'
            valueName='showPassword'
            value={showPassword}
            defaultChecked
            toggleValue={() => setShowPassword(!showPassword)}
          />
        </Fragment>
      )}
    </Form.Group>
  )
}
