import React, { useCallback, useState } from 'react'
import { Alert } from 'react-bootstrap'
import { connect } from 'react-redux'
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'

function Attachment({ selected, attachment, removeSelected }) {
  const { t } = useTranslation()
  const [invalid, setInvalid] = useState(false)

  const onDrop = useCallback(async (acceptedFiles) => {
    setInvalid(false)

    if (acceptedFiles.length === 0) {
      setInvalid(true)
    } else {
      const file = acceptedFiles[0]

      const base64 = await new Promise((resolve, reject) => {
        // eslint-disable-next-line no-undef
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result.split(',')[1])
        reader.onerror = (error) => reject(error)
      })
      selected({ base64: base64, ...file })
    }
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    accept: 'image/png, image/jpeg, application/pdf'
  })

  return (
    <>
      {invalid && <Alert variant='danger'>{t('Invalid File Type')}</Alert>}
      {Object.values(attachment).length === 0 && (
        <div
          {...getRootProps()}
          className='bg-light p-4 text-center text-muted border telehealth__add-attachment'
        >
          <input {...getInputProps()} />
          {isDragActive ? (
            <p className='m-0'>{t('Drop the file here')} ...</p>
          ) : (
            <>
              <p className='m-0 d-none d-lg-block'>
                {t("Drag 'n' drop file here, or click to select a file")}
              </p>
              <p className='m-0 d-block d-lg-none'>
                {t('Click to select a file')}
              </p>
            </>
          )}
        </div>
      )}

      <ul>
        {Object.values(attachment).length > 0 && (
          <li className='list' key={attachment.path}>
            {' '}
            {attachment.path}{' '}
            <a
              href='!#'
              onClick={(e) => {
                e.preventDefault()
                removeSelected()
              }}
            >
              {t('Remove')}
            </a>
          </li>
        )}
      </ul>

      <div>
        <small>{t('Files must be JPG, PNG, or PDF')}</small>
      </div>
    </>
  )
}

export default connect()(Attachment)
