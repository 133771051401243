import React, { Component } from 'react'
import UAParser from 'ua-parser-js'
import MuteButton from './Components/MuteButton.js'
import EndCallButton from './Components/EndCallButton.js'
import SynchronousVideo from '../../../_services/video.service'
import StopCameraButton from './Components/StopCameraButton.js'
import SwitchCameraButton from './Components/SwitchCameraButton.js'
import MediaSettingsModal from './Components/MediaSettingsModal.js'
import MediaSettingsButton from './Components/MediaSettingsButton.js'

import { connect } from 'react-redux'
import { Spinner } from 'react-bootstrap'
import { history } from '../../../_helpers/history'
import { alertActions } from '../../../_actions/alert.actions.js'
import { withTranslation } from 'react-i18next'
import { telehealthService } from '../../../_services/telehealth.service.js'

import './style.css'

class Video extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      videoFacing: 'user',
      videoDevices: '',
      mobileStreams: [],
      videoOrientation: window.orientation === 0 ? 'portrait' : 'landscape',
      audioInputDevices: '',
      audioOutputDevices: '',
      messageLabel: 'Connecting',
      showMediaSettings: false
    }

    this.parser = new UAParser()
    this.deviceType = ''
    this.loadingLocal = true
    this.handleMessage = this.handleMessage.bind(this)
    this.checkOrientation = this.checkOrientation.bind(this)
    this.handleThrowError = this.handleThrowError.bind(this)
    this.handleGetMediaDevices = this.handleGetMediaDevices.bind(this)
    this.handleSwitchMobileCameras = this.handleSwitchMobileCameras.bind(this)
  }

  checkOrientation() {
    const orientation = window.orientation === 0 ? 'portrait' : 'landscape'
    const mediaSettingsBtn = document.getElementById('mediaSettingsBtn')
    const localMediaLoader = document.getElementById('local-media-loader')
    const localMediaContainer = document.getElementById('local-media')

    if (orientation === 'portrait') {
      localMediaContainer.classList.remove('local-media-landscape')
      localMediaLoader.classList.remove('local-loader-landscape')
      mediaSettingsBtn.classList.remove('media-button-landscape')

      localMediaContainer.classList.add('local-media-portrait')
      localMediaLoader.classList.add('local-loader-portrait')
      mediaSettingsBtn.classList.add('media-button-portrait')
    } else if (orientation === 'landscape') {
      localMediaContainer.classList.remove('local-media-portrait')
      localMediaLoader.classList.remove('local-loader-portrait')
      mediaSettingsBtn.classList.remove('media-button-portrait')

      localMediaContainer.classList.add('local-media-landscape')
      localMediaLoader.classList.add('local-loader-landscape')
      mediaSettingsBtn.classList.add('media-button-landscape')
    }
    this.setState({ videoOrientation: orientation })
  }

  componentDidMount() {
    this.props.dispatch(alertActions.clearDelay())

    if (history.location.state === undefined) {
      history.push('/telehealth')
      this.props.dispatch(
        alertActions.error(
          'An error occurred and we could not get your telehealth appointment at this time. Please try again.'
        )
      )
    } else {
      this.deviceType = this.parser.getDevice().type
      window.addEventListener('orientationchange', this.checkOrientation, false)

      const localMediaLoader = document.getElementById('local-media-loader')
      const localMediaContainer = document.getElementById('local-media')
      const remoteMediaContainer = document.getElementById('remote-media')

      this.synchronousVideo = new SynchronousVideo(
        localMediaLoader,
        localMediaContainer,
        remoteMediaContainer,
        this.deviceType,
        this.handleThrowError,
        history.goBack,
        this.props.t
      )
      this.synchronousVideo.subscribe(this.handleMessage)

      // If mobile or tablet
      if (this.deviceType !== undefined) {
        this.checkOrientation()
      }

      // Get video token
      telehealthService
        .getVideoToken(history.location.state.id)
        .then((response) => {
          this.synchronousVideo.connect(
            response.token,
            this.handleGetMediaDevices
          )
        })
        .catch(() => {
          this.props.dispatch(
            alertActions.error(
              'A video connection could not be establish at this time. Please try again later.'
            )
          )
        })
    }
  }

  componentWillUnmount() {
    window.removeEventListener(
      'orientationchange',
      this.checkOrientation,
      false
    )

    // Stop all mobile tracks
    if (this.state.mobileStreams.length > 0) {
      this.state.mobileStreams.forEach((stream) => {
        const tracks = stream.getTracks()
        tracks.forEach((track) => track.stop())
      })
    }
    if (history.location.state !== undefined) {
      this.handleEndCall()
    }
  }

  handleGetMediaDevices() {
    // If desktop device, get all media devices
    if (this.deviceType === undefined) {
      navigator.mediaDevices.enumerateDevices().then((mediaDevices) => {
        let count = 1
        const video = []
        const audioInput = []
        const audioOutput = []
        mediaDevices.forEach((mediaDevice) => {
          if (mediaDevice.kind === 'videoinput') {
            video.push({
              id: mediaDevice.deviceId,
              name: mediaDevice.label || `Camera ${count}`
            })
          } else if (mediaDevice.kind === 'audioinput') {
            audioInput.push({
              id: mediaDevice.deviceId,
              name: mediaDevice.label || `Audio Input ${count}`
            })
          } else if (mediaDevice.kind === 'audiooutput') {
            audioOutput.push({
              id: mediaDevice.deviceId,
              name: mediaDevice.label || `Audio Output ${count}`
            })
          }
          count++
        })
        this.setState({
          videoDevices: video,
          audioInputDevices: audioInput,
          audioOutputDevices: audioOutput
        })
      })
    }
    // If mobile or tablet device, get front/back facing camera devices
    else {
      const video = {}
      const streams = []

      this.handleGetMobileMediaDevices('user', streams, video)
        .then(() => {
          this.handleGetMobileMediaDevices('environment', streams, video).then(
            () => {
              this.setState({
                videoDevices: video,
                mobileStreams: streams,
                loading: false
              })
              // Set the video device to the front facing camera
              this.synchronousVideo.handleUpdateVideoDevice(video.user, 'user')
            }
          )
        })
        .catch((error) => {
          console.error(error)
        })
    }
  }

  handleGetMobileMediaDevices(facing, streams, video) {
    return navigator.mediaDevices
      .getUserMedia({ video: { facingMode: facing }, audio: false })
      .then((stream) => {
        const mobileVideoTrack = stream.getTracks()[0]
        const deviceId = mobileVideoTrack.getSettings().deviceId
        video[facing] = deviceId

        streams.push(stream)
      })
      .catch((error) => {
        console.error(error)
      })
  }

  handleSwitchMobileCameras() {
    // If not desktop
    if (this.deviceType !== undefined) {
      const cameraFacing =
        this.state.videoFacing === 'user' ? 'environment' : 'user'

      this.setState({ videoFacing: cameraFacing })
      this.synchronousVideo.handleUpdateVideoDevice(
        this.state.videoDevices[cameraFacing],
        cameraFacing
      )
    }
  }

  handleThrowError() {
    this.props.dispatch(
      alertActions.delay(
        'A video connection could not be establish at this time. Either your device does not support video or you have not allowed video and audio permissions. Please check your permissions and try again later.',
        'danger'
      )
    )
  }

  handleMessage(message) {
    if (message.includes(this.props.t('has joined'))) {
      setTimeout(() => {
        document.getElementById('message-label').style.opacity = '0'
      }, 1500)
    }
    this.setState({
      messageLabel: message
    })
  }

  handleEndCall() {
    this.synchronousVideo.endCall()
  }

  render() {
    if (history.location.state === undefined) {
      return null
    } else {
      return (
        <div className='row' id='telemedicineVideo'>
          <div className='col-12'>
            {this.deviceType === undefined && (
              <MediaSettingsModal
                show={this.state.showMediaSettings}
                hide={() => this.setState({ showMediaSettings: false })}
                videoDevices={this.state.videoDevices}
                synchronousVideo={this.synchronousVideo}
                audioInputDevices={this.state.audioInputDevices}
                audioOutputDevices={this.state.audioOutputDevices}
                selectVideoDevice={(deviceId) => {
                  return this.synchronousVideo.handleUpdateVideoDevice(deviceId)
                }}
                selectAudioInputDevice={(deviceId) => {
                  return this.synchronousVideo.handleUpdateAudioInputDevice(
                    deviceId
                  )
                }}
                selectAudioOutputDevice={(deviceId) => {
                  return this.synchronousVideo.handleUpdateAudioOutputDevice(
                    deviceId
                  )
                }}
              />
            )}

            <div id='remote-media' className='text-center' />

            <Spinner
              id='local-media-loader'
              animation='border'
              role='status'
              variant='light'
            />

            <div id='local-media' onClick={this.handleSwitchMobileCameras} />

            <div id='message-label'>
              {this.props.t(this.state.messageLabel)}
            </div>

            <div
              id='telemedicineVideo--links'
              className='row mx-auto mx-sm-0 mb-3'
            >
              {this.deviceType === undefined ? (
                <MediaSettingsButton
                  showSettings={() =>
                    this.setState({ showMediaSettings: true })
                  }
                />
              ) : (
                <SwitchCameraButton
                  loading={this.state.loading}
                  toggleCamera={this.handleSwitchMobileCameras}
                  facing={this.state.videoFacing}
                />
              )}

              {((this.deviceType === 'mobile' &&
                this.state.videoOrientation === 'portrait') ||
                this.deviceType !== 'mobile') && (
                <div className='row m-auto m-sm-0'>
                  <div className='col-4'>
                    <MuteButton
                      handleMute={(isMuted) =>
                        this.synchronousVideo.handleMute(isMuted)
                      }
                    />
                  </div>
                  <div className='col-4'>
                    <StopCameraButton
                      handleStopVideo={(isStopped) =>
                        this.synchronousVideo.handleStopVideo(isStopped)
                      }
                    />
                  </div>
                  <div className='col-4'>
                    <EndCallButton
                      handleEndCall={() => {
                        this.handleEndCall()
                        history.goBack()
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )
    }
  }
}

export default connect()(withTranslation()(Video))
