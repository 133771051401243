import apiVersion from '../_helpers/api-version'
import axiosInstance from '../_helpers/axiosConfig'
import { authHeader } from '../_helpers/auth-header'

export const eventService = {
  getEvent,
  verifyDob,
  getByToken,
  getTimeline,
  confirmPatient,
  getByVerifiedToken
}

function getTimeline(direction, today) {
  return axiosInstance
    .request({
      method: 'GET',
      headers: authHeader(),
      url: `${
        process.env.REACT_APP_API_URL
      }/${apiVersion()}/patient/timeline?direction=${direction}&today=${today}`
    })
    .then((response) => {
      return response.data
    })
}

function getEvent(eventId) {
  return axiosInstance
    .request({
      method: 'GET',
      headers: authHeader(),
      url: `${
        process.env.REACT_APP_API_URL
      }/${apiVersion()}/patient/event/${eventId}`
    })
    .then((response) => {
      return response.data
    })
}

function confirmPatient(profileId, eventId, verifiedToken) {
  return axiosInstance.request({
    method: 'POST',
    headers: authHeader(),
    data: { profileId, verifiedToken },
    url: `${
      process.env.REACT_APP_API_URL
    }/${apiVersion()}/patient/event/${eventId}/family-member`
  })
}

function getByToken(token) {
  return axiosInstance.request({
    method: 'GET',
    headers: authHeader(),
    url: `${
      process.env.REACT_APP_API_URL
    }/${apiVersion()}/patient/event/${token}/deep-link`
  })
}

function getByVerifiedToken(dlToken, verifiedToken) {
  return axiosInstance.request({
    method: 'GET',
    headers: authHeader(),
    url: `${
      process.env.REACT_APP_API_URL
    }/${apiVersion()}/patient/event/${dlToken}/confirm/${verifiedToken}`
  })
}

function verifyDob(dateOfBirth, dlToken) {
  return axiosInstance.request({
    method: 'POST',
    headers: authHeader(),
    url: `${
      process.env.REACT_APP_API_URL
    }/${apiVersion()}/patient/event/${dlToken}/deep-link`,
    data: { dateOfBirth }
  })
}
