import React, { Component } from 'react'

class MediaSettingsButton extends Component {
  render() {
    return (
      <button
        id='mediaSettingsBtn'
        onClick={(e) => {
          e.preventDefault()
          this.props.showSettings()
        }}
        type='button'
        className='teleVideoBtn on'
      >
        <i className='fa fa-cog' />
      </button>
    )
  }
}

export default MediaSettingsButton
