import React from 'react'
import { useTranslation } from 'react-i18next'

export default function RenderValidateMessage(props) {
  const { errors, field } = props
  const message = errors[field]
  const { t } = useTranslation()
  return (
    <div id={`${field}-error`} className='text-danger hide'>
      {t(message)}
    </div>
  )
}
