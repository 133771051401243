import React, { Fragment, useEffect } from 'react'
import EmailInput from '../../../../_components/Inputs/EmailInput'
import NumberInput from '../../../../_components/Inputs/NumberInput'
import ActionButton from '../../../../_components/Buttons/ActionButton'
import PhoneNumberInput from '../../../../_components/Inputs/PhoneNumberInput'
import useWindowDimensions from '../../../../_helpers/dimensions'
import RenderValidateMessage from '../../../../_components/Validation/RenderValidateMessage'

import { useTranslation } from 'react-i18next'
import { handleNextInput } from '../../../../_helpers/handleEnter'
import {
  Col,
  Row,
  Card,
  Form,
  Fade,
  InputGroup,
  Button,
  Collapse
} from 'react-bootstrap'

export default function ContactInfo({
  email,
  errors,
  isInvalid,
  initPhone,
  initEmail,
  phoneNumber,
  loadingUser,
  currentStep,
  updatingPhone,
  updatingEmail,
  updateRequest,
  contactUpdated,
  updatingContact,
  verificationCode,
  sendingVerifyCode,
  handleSetState,
  handleSendVerifyCode,
  handleUpdateContactInfo
}) {
  const { t } = useTranslation()
  const { width, breakPointLG } = useWindowDimensions()

  useEffect(() => {
    if (phoneNumber !== initPhone) {
      handleSetState({ updatingPhone: phoneNumber.length > 0 })
    }
    if (email !== initEmail) {
      handleSetState({ updatingEmail: email.length > 0 })
    }
  }, [phoneNumber, email])

  const SendVerifyCodeBtn = () => {
    return (
      <ActionButton
        action='Send Verification Code'
        variant='outline-primary'
        submit={handleSendVerifyCode}
        disabled={sendingVerifyCode || updatingContact || updateRequest}
        loading={sendingVerifyCode}
      />
    )
  }

  const VerifyCodeInput = () => {
    return (
      <Fragment>
        <InputGroup style={{ flexWrap: 'nowrap' }}>
          <NumberInput
            errors={{}}
            style={{ borderRadius: '.25rem 0 0 .25rem' }}
            value={verificationCode}
            label={false}
            className='mb-0'
            placeholder={t('Verification Code')}
            valueName='verificationCode'
            setValue={(value) =>
              handleSetState({
                verificationCode: isNaN(value.verificationCode)
                  ? null
                  : value.verificationCode
              })
            }
            handleEnter={handleNextInput}
          />
          <InputGroup.Append>
            {verificationCode ? (
              <Button
                id='contactUpdateInfo'
                style={{
                  width:
                    width < breakPointLG
                      ? '7em'
                      : updatingContact
                      ? document.getElementById('contactUpdateInfo').clientWidth
                      : 'fit-content'
                }}
                variant='outline-success'
                disabled={
                  loadingUser ||
                  contactUpdated ||
                  updatingContact ||
                  updateRequest
                }
                onClick={handleUpdateContactInfo}
              >
                {contactUpdated ? (
                  t('Updated!')
                ) : updatingContact ? (
                  <i className='fa fa-spinner fa-spin fa-fw' />
                ) : (
                  t('Update')
                )}
              </Button>
            ) : (
              <Button
                id='contactSendCode'
                style={{
                  width:
                    width < breakPointLG
                      ? '7em'
                      : sendingVerifyCode
                      ? document.getElementById('contactSendCode').clientWidth
                      : 'fit-content'
                }}
                variant='outline-primary'
                onClick={handleSendVerifyCode}
                disabled={sendingVerifyCode || updatingContact || updateRequest}
              >
                {sendingVerifyCode ? (
                  <i className='fa fa-spinner fa-spin fa-fw' />
                ) : (
                  t('Resend')
                )}
              </Button>
            )}
          </InputGroup.Append>
        </InputGroup>
        <RenderValidateMessage errors={errors} field='verificationCode' />
      </Fragment>
    )
  }

  return (
    <Row>
      <Col xs='12'>
        <Card className='pb-lg-1' style={{ borderRadius: 0 }}>
          <Card.Body>
            <Row>
              <Col xs='12' className='my-3 my-lg-0'>
                <h4>{t('Contact Info')}</h4>
              </Col>
            </Row>
            <Row className='mt-lg-3'>
              <Col xs='12'>
                <Form>
                  <Form.Group className='mb-0'>
                    <Row className='mb-lg-2'>
                      <Col
                        xs='12'
                        lg='4'
                        className='mt-2 px-lg-0 text-lg-right'
                      >
                        <Form.Label className={isInvalid('phoneNumber')}>
                          {t('Phone Number')}
                        </Form.Label>
                      </Col>
                      <Col xs='12' lg='4'>
                        <PhoneNumberInput
                          label={false}
                          errors={errors}
                          setValue={(value) => {
                            handleSetState({
                              phoneNumber: value.phoneNumber
                            })
                          }}
                          disabled={updatingEmail}
                          phoneNumber={phoneNumber}
                          handleEnter={handleNextInput}
                        />
                      </Col>

                      {updatingPhone && currentStep === 'ENTER_VERIFY_CODE' ? (
                        <Fade in={currentStep === 'ENTER_VERIFY_CODE'}>
                          <Col xs='12' lg='4' className='d-none d-lg-block'>
                            <div className='mt-4 mt-sm-0'>
                              {VerifyCodeInput()}
                            </div>
                          </Col>
                        </Fade>
                      ) : (
                        <Col xs='12' lg='4' className='d-none d-lg-block'>
                          <Fade in={updatingPhone}>
                            <div className={!updatingPhone ? 'd-none' : ''}>
                              {SendVerifyCodeBtn()}
                            </div>
                          </Fade>
                        </Col>
                      )}
                    </Row>

                    <Row className='mb-2'>
                      <Col
                        xs='12'
                        lg='4'
                        className='mt-2 px-lg-0 text-lg-right'
                      >
                        <Form.Label className={isInvalid('email')}>
                          {t('Email')}
                        </Form.Label>
                      </Col>
                      <Col xs='12' lg='4'>
                        <EmailInput
                          label={false}
                          errors={errors}
                          value={email}
                          disabled={updatingPhone}
                          valueName='email'
                          setValue={(value) => {
                            handleSetState({
                              email: value.email
                            })
                          }}
                          handleEnter={handleNextInput}
                        />
                      </Col>

                      {updatingEmail && currentStep === 'ENTER_VERIFY_CODE' ? (
                        <Fade in={currentStep === 'ENTER_VERIFY_CODE'}>
                          <Col xs='12' lg='4' className='d-none d-lg-block'>
                            <div className='mt-4 mt-sm-0'>
                              {VerifyCodeInput()}
                            </div>
                          </Col>
                        </Fade>
                      ) : (
                        <Col xs='12' lg='4' className='d-none d-lg-block'>
                          <Fade in={updatingEmail}>
                            <div className={!updatingEmail ? 'd-none' : ''}>
                              {SendVerifyCodeBtn()}
                            </div>
                          </Fade>
                        </Col>
                      )}
                    </Row>

                    <Collapse in={updatingEmail || updatingPhone}>
                      <Row className='text-left'>
                        {currentStep === 'ENTER_VERIFY_CODE' ? (
                          <Fade in={currentStep === 'ENTER_VERIFY_CODE'}>
                            <Col xs='12' className='d-block d-lg-none'>
                              <div className='my-3'>{VerifyCodeInput()}</div>
                            </Col>
                          </Fade>
                        ) : (
                          <Col xs='12' className='d-block d-lg-none'>
                            <Fade in={updatingEmail || updatingPhone}>
                              <div
                                className={
                                  !updatingEmail && !updatingPhone
                                    ? 'd-none'
                                    : 'my-3'
                                }
                              >
                                {SendVerifyCodeBtn()}
                              </div>
                            </Fade>
                          </Col>
                        )}
                      </Row>
                    </Collapse>
                  </Form.Group>
                </Form>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  )
}
