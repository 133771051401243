import React, { Component } from 'react'

class EndCallButton extends Component {
  render() {
    return (
      <button
        id='endCallBtn'
        onClick={(e) => {
          e.preventDefault()
          this.props.handleEndCall()
        }}
        type='button'
        className='teleVideoBtn endCall'
      >
        <i className='fa fa-times' />
      </button>
    )
  }
}

export default EndCallButton
