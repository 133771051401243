import React, { useEffect, useRef } from 'react'
import { Spinner } from 'react-bootstrap'

import '../style.css'

export default function SendMessageChatBox({
  message,
  isSending,
  isTyping,
  sendBtnStatus,
  handleShowFile,
  handleSetMessage,
  handleSendMessage
}) {
  const textRef = useRef()

  useEffect(() => {
    let stoppedTimeout = false

    textRef.current.addEventListener('keyup', () => {
      if (stoppedTimeout === false) {
        isTyping(true)
      }

      clearTimeout(stoppedTimeout)

      stoppedTimeout = setTimeout(() => {
        stoppedTimeout = false
        isTyping(false)
      }, 3000)
    })

    return () => clearTimeout(stoppedTimeout)
  }, [])

  return (
    <div id='message-send-container' className='container-lg'>
      <div className='row py-2'>
        <div className='col-12'>
          <textarea
            ref={textRef}
            id='message-textarea'
            rows='4'
            value={message}
            onChange={handleSetMessage}
            className='form-control border-0 font-weight-light px-0'
            aria-label='With textarea'
            placeholder='Type your message'
          />
        </div>

        <div className='col-12 d-flex justify-content-between mt-2'>
          <div className='mt-2 mb-1'>
            <div className='row'>
              <div className='col-auto'>
                <button
                  type='button'
                  onClick={handleShowFile}
                  className='btn send-file-btn p-0'
                >
                  <i className='fa fa-upload pr-1' aria-hidden='true' /> Send
                  File
                </button>
              </div>
            </div>
          </div>

          <button
            type='button'
            onClick={handleSendMessage}
            className={`btn send-message-btn px-2 py-1 my-auto ${sendBtnStatus}`}
          >
            {isSending && message ? (
              <Spinner size='sm' animation='border' role='status' />
            ) : (
              <span className='font-weight-light'>
                <i className='fa fa-paper-plane fa-xs' aria-hidden='true' />
                &nbsp;&nbsp;Send
              </span>
            )}
          </button>
        </div>
      </div>
    </div>
  )
}
