import React from 'react'
import RenderValidateMessage from '../Validation/RenderValidateMessage'
import { useTranslation } from 'react-i18next'

export default function CheckBoxInput(props) {
  const {
    errors = {},
    required = false,
    text,
    value,
    valueName,
    defaultChecked,
    toggleValue
  } = props
  const { t } = useTranslation()
  return (
    <div className='form-check'>
      <input
        style={{ height: '1.5em', width: '1.5em' }}
        className='form-check-input'
        type='checkbox'
        checked={defaultChecked && value}
        onChange={toggleValue}
      />
      <label className='form-check-label pl-3 pt-1' onClick={toggleValue}>
        <small>{t(text)}</small>
        {required && (
          <RenderValidateMessage errors={errors} field={valueName} />
        )}
      </label>
    </div>
  )
}
