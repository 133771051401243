import React, { Fragment, useState } from 'react'
import useWindowDimensions from '../../../_helpers/dimensions'
import AddFamilyMemberModal from './AddFamilyMember'

import { useTranslation } from 'react-i18next'
import { Modal, Row, Col } from 'react-bootstrap'

import {
  AddFamilyMember,
  PatientConfirmButton
} from '@axshealth/design-library'

export default function ChangeFamilyMemberModal({
  show,
  changing,
  selected,
  patients,
  handleClose,
  changePatient
}) {
  const { t } = useTranslation()
  const [patientClicked, setPatientClicked] = useState('')
  const [showAddFamilyMember, setShowAddFamilyMember] = useState(false)

  const { width, breakPointMD, breakPointSM } = useWindowDimensions()
  const mobile = width < breakPointSM

  return (
    <Fragment>
      <AddFamilyMemberModal
        showAddFamilyMember={showAddFamilyMember}
        hideAddFamilyMember={() => setShowAddFamilyMember(false)}
      />

      <Modal
        size='lg'
        show={show && !showAddFamilyMember}
        onHide={handleClose}
        backdrop='static'
        centered={width < breakPointMD}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t('Change Family Member')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              {patients.length > 0 &&
                patients.map((patient, index) => {
                  return (
                    <div key={index}>
                      <PatientConfirmButton
                        card={mobile}
                        dark={mobile}
                        btnText='Change'
                        patient={patient}
                        loading={
                          changing && patient.userProfileId === patientClicked
                        }
                        disabled={changing}
                        confirmed={patient.userProfileId === selected}
                        handleConfirm={(userProfileId) => {
                          setPatientClicked(userProfileId)
                          changePatient(userProfileId)
                        }}
                      />
                      {mobile ? <br /> : <hr />}
                    </div>
                  )
                })}
            </Col>
          </Row>
          <Row>
            <Col
              xs='12'
              lg={{ span: 8, offset: 2 }}
              className='text-center mb-3'
            >
              <AddFamilyMember
                handleShowAddFamilyMember={() => setShowAddFamilyMember(true)}
              />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </Fragment>
  )
}
