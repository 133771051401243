import { setCookie } from '../_helpers/cookieJar'
import { authenticationConstants as constants } from '../_constants/authentication.constants'

const initialState = {
  jwt: null,
  username: '',
  loggingIn: false,
  hasAccount: false,
  showPassword: false,
  verifiedToken: '',
  rememberUsername: false,
  preferredPlatform: false,
  preferredTimestamp: false,
  preferredPlatformModule: false
}

export function authentication(state = initialState, action) {
  if (action.type === constants.AUTHENTICATION_LOGIN_REQUEST) {
    return {
      ...state,
      loggingIn: true
    }
  }

  if (action.type === constants.AUTHENTICATION_LOGIN_FAILURE) {
    return {
      ...state,
      loggingIn: false
    }
  }

  if (action.type === constants.AUTHENTICATION_LOGIN_SUCCESS) {
    setCookie('hasAccount', true)
    return {
      ...state,
      loggingIn: false,
      hasAccount: true,
      jwt: action.user.authToken
    }
  }

  if (action.type === constants.AUTHENTICATION_SET_VERIFIED_TOKEN) {
    return {
      ...state,
      verifiedToken: action.token
    }
  }

  if (action.type === constants.AUTHENTICATION_CLICK_PREFERRED_PLATFORM) {
    return {
      ...state,
      preferredPlatformModule: action.module,
      preferredTimestamp: false
    }
  }

  if (action.type === constants.AUTHENTICATION_DISPLAY_PREFERRED_PLATFORM) {
    return {
      ...state,
      preferredPlatformModule: action.module
    }
  }

  if (action.type === constants.AUTHENTICATION_SET_PREFERRED_PLATFORM) {
    return {
      ...state,
      preferredPlatform: action.platform,
      preferredTimestamp: action.timestamp
    }
  }

  if (action.type === constants.AUTHENTICATION_SET_USERNAME) {
    return {
      ...state,
      username: action.username
    }
  }

  if (action.type === constants.AUTHENTICATION_REMEMBER_USERNAME) {
    return {
      ...state,
      rememberUsername: action.rememberUsername
    }
  }

  if (action.type === constants.AUTHENTICATION_SHOW_PASSWORD) {
    return {
      ...state,
      showPassword: action.showPassword
    }
  }

  return state
}
