import React, { Component } from 'react'
import classNames from 'classnames'
import RenderProfilePicture from '../../../../_helpers/profile-picture'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

class Online extends Component {
  constructor(props) {
    super(props)

    this.state = {
      onlineUser: props.user
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.user !== this.props.user) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        onlineUser: this.props.user
      })
    }
  }

  render() {
    const profileClass = classNames({
      'user-online': this.state.onlineUser.typing === false
    })

    return (
      <div className='mr-1'>
        <OverlayTrigger
          overlay={
            <Tooltip>{`${this.state.onlineUser.firstName} ${this.state.onlineUser.lastName} Active`}</Tooltip>
          }
        >
          <div>
            <RenderProfilePicture
              height={25}
              width={25}
              image={this.state.onlineUser.profileUrl}
              circle
              cached
              className={profileClass}
            />
            {this.state.onlineUser.typing && (
              <div className='typing'>
                <div className='dot' />
                <div className='dot' />
                <div className='dot' />
              </div>
            )}
          </div>
        </OverlayTrigger>
      </div>
    )
  }
}

export default Online
