import React from 'react'

import { Link } from 'react-router-dom'
import { history } from '../../../_helpers/history'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { ProviderConfirmList } from '@axshealth/design-library'

import {
  Col,
  Row,
  Card,
  Alert,
  Button,
  Spinner,
  Dropdown,
  Breadcrumb
} from 'react-bootstrap'

function MessageManyLocation({ doctor }) {
  return (
    <Dropdown>
      <Dropdown.Toggle variant='success' block id='dropdown-basic'>
        Message {doctor.lastName}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {doctor.locations.data.map((location, index) => {
          return (
            <Dropdown.Item
              key={index}
              as={Link}
              to={{
                pathname: '/messages/stream',
                state: { doctorId: doctor.id, locationId: location.id }
              }}
            >
              {location.name}
            </Dropdown.Item>
          )
        })}
      </Dropdown.Menu>
    </Dropdown>
  )
}

function MessageSingleLocation({ doctor }) {
  return (
    <Link
      to={{
        pathname: '/messages/stream',
        state: { doctorId: doctor.id, locationId: doctor.locations.data[0].id }
      }}
      className='btn btn-success btn-block'
    >
      Message {doctor.lastName}
    </Link>
  )
}

function Message({ doctor }) {
  return (
    <Link
      to={{
        pathname: '/messages/stream',
        state: { doctorId: doctor.id }
      }}
      className='btn btn-success btn-block'
    >
      Message {doctor.lastName}
    </Link>
  )
}

const Providers = ({ loading, doctors }) => {
  const { t } = useTranslation()

  return (
    <Row className='mt-3'>
      <Col sm={12}>
        <Breadcrumb>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/home' }}>
            {t('Home')}
          </Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/messages' }}>
            {t('Messages')}
          </Breadcrumb.Item>
          <Breadcrumb.Item active>{t('View')}</Breadcrumb.Item>
        </Breadcrumb>
      </Col>

      <Col sm={12}>
        <Row>
          <Col className='my-2'>
            <Alert variant='primary'>
              {t('Please select a provider to message')}.
            </Alert>
          </Col>
        </Row>

        <Row>
          <Col>
            {loading || !doctors ? (
              <Spinner animation='border' />
            ) : (
              <Card className='telehealth__card'>
                <Card.Body>
                  {doctors.map((doctor, index) => {
                    return (
                      <div key={doctor.id} className='row p-2'>
                        <div
                          style={{
                            backgroundImage: `url(${doctor.profileUrl})`,
                            backgroundSize: 'cover',
                            borderRadius: '50%',
                            backgroundRepeat: 'no-repeat',
                            height: '60px',
                            width: '60px'
                          }}
                          className='col-auto d-flex flex-column justify-content-center'
                        />
                        <div className='col d-flex flex-column justify-content-center'>
                          <p className='mb-1'>
                            {doctor.firstName} {doctor.lastName}
                          </p>
                          <p className='text-muted'>{doctor.provider.name}</p>
                        </div>
                        <div className='col-sm-3 d-flex flex-column justify-content-center'>
                          {doctor.locations.data.length > 1 && (
                            <MessageManyLocation doctor={doctor} />
                          )}

                          {doctor.locations.data.length === 1 && (
                            <MessageSingleLocation doctor={doctor} />
                          )}

                          {doctor.locations.data.length === 0 && (
                            <Message doctor={doctor} />
                          )}
                        </div>
                      </div>
                    )
                  })}
                </Card.Body>
              </Card>
            )}
          </Col>
        </Row>
        <Row sm='12' md='4'>
          <Col>
            <Button
              as={Link}
              to='/messages'
              variant='outline-primary'
              className='mt-4'
              block
            >
              {t('Cancel')}
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

function mapStateToProps(state) {
  return {
    doctors: state.user.doctors,
    loading: state.loading.loading,
    patient: state.user.currentUser
  }
}
export default connect(mapStateToProps)(Providers)
