import React from 'react'
import LinkButton from '../Buttons/LinkButton'

import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { languageConstants } from '../../_constants/language.constants'

export default function LanguageButton(props) {
  const { i18n } = useTranslation()
  const dispatch = useDispatch()

  const { textColor } = props

  return (
    <>
      <div className='language-selector text-center'>
        <LinkButton
          btn=''
          link='/'
          noUnderline
          color={textColor}
          handleClick={(e) => {
            e.preventDefault()
            dispatch({ type: languageConstants.TOGGLE_LANGUAGE_MODAL })
          }}
        >
          <span className='font-weight-light'>
            {i18n.language === 'en' && <span>English</span>}
            {i18n.language === 'es' && <span>Español</span>}
          </span>
        </LinkButton>
      </div>
    </>
  )
}
