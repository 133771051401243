import React, { Component, Fragment } from 'react'
import Logo from '../../images/logo.png'
import LanguageLinkButton from '../../_components/Language/LanguageLinkButton'
import RenderProfilePicture from '../../_helpers/profile-picture'
import NotificationDropdown from '../Notifications/NotificationDropdown'

import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { history } from '../../_helpers/history'
import { userActions } from '../../_actions/user.actions'
import { withTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { notificationService } from '../../_services/notifications.service'
import { notificationConstants } from '../../_constants/notification.constants'
import { Nav, Navbar, NavDropdown, Spinner, Button } from 'react-bootstrap'

import {
  faUserCog,
  faSignOutAlt,
  faUserFriends
} from '@fortawesome/free-solid-svg-icons'

import './style.css'

class NavBar extends Component {
  constructor(props) {
    super(props)

    this.state = {
      unlisten: '',
      location: '',
      toggleMenu: false,
      showManageFamilyMembers: false
    }
    this.timeout = false
    this.unlisten = null
    this.notificationInterval = false
  }

  componentDidMount() {
    this.props.dispatch(userActions.storeUser())

    this.timeout = setTimeout(() => {
      this.getAllNotifications()

      this.notificationInterval = setInterval(() => {
        this.getAllNotifications()
      }, 60 * 1000)
    }, 1000)

    this.unlisten = history.listen((location) => {
      this.setState({ toggleMenu: false })
    })
  }

  componentWillUnmount() {
    if (this.notificationInterval) {
      clearInterval(this.notificationInterval)
    }

    if (this.timeout) {
      clearTimeout(this.timeout)
    }

    this.unlisten()
  }

  getAllNotifications() {
    notificationService
      .getAll()
      .then((response) => {
        this.props.dispatch({
          type: notificationConstants.SET_ALL_NOTIFICATIONS,
          notifications: response
        })
      })
      .catch((error) => {
        console.error(error)
      })
  }

  openNav = () => {
    document.getElementById('mySidepanel').style.left = '0em'
  }

  logout = () => {
    this.props.dispatch(userActions.logout())
  }

  DesktopView = () => {
    const { t, patient } = this.props
    return (
      <>
        <Navbar
          id='navbar'
          className='py-0 w-100 d-none d-lg-inline-flex'
          expand='lg'
        >
          <div className='container'>
            <Navbar.Brand as={Link} to='/home' className='navbar-brand'>
              <img
                src={Logo}
                alt='AxS Health Logo'
                height='50'
                className='d-line-block align-top'
              />
            </Navbar.Brand>

            <Nav className='ml-auto mr-2 notification__icon'>
              <NotificationDropdown />
            </Nav>

            <NavDropdown
              title={
                Object.keys(patient).length > 0 ? (
                  <span className='font-weight-light'>
                    {patient.firstName} {patient.lastName}
                  </span>
                ) : (
                  <Spinner
                    size='sm'
                    className='text-white-50'
                    animation='border'
                    variant='light'
                  />
                )
              }
              className='p-0'
            >
              <div className='py-2 ml-4'>
                <b>{t('User Settings')}</b>
              </div>

              <NavDropdown.Item
                as={Link}
                to='/settings/edit-profile'
                className='py-2'
              >
                <FontAwesomeIcon icon={faUserCog} className='mr-3' />
                {t('Edit Profile')}
              </NavDropdown.Item>

              <NavDropdown.Item className='py-2' as={Link} to='/family-members'>
                <FontAwesomeIcon icon={faUserFriends} className='mr-3' />
                {t('Family Members')}
              </NavDropdown.Item>

              <NavDropdown.Divider className='m-0' />

              <NavDropdown.Item className='py-2' onClick={this.logout}>
                <FontAwesomeIcon icon={faSignOutAlt} className='mr-3' />
                {t('Logout')}
              </NavDropdown.Item>
            </NavDropdown>

            <Nav className='mr-1'>
              {Object.keys(patient).length > 0 && (
                <RenderProfilePicture
                  image={patient.profileUrl}
                  cached
                  width={25}
                  height={25}
                  className='m-auto'
                />
              )}
            </Nav>

            <Nav>
              <LanguageLinkButton textColor='nav-link' />
            </Nav>
          </div>
        </Navbar>
      </>
    )
  }

  MobileView = () => {
    const { t, patient } = this.props
    return (
      <>
        <Navbar id='navbar' className='py-0 pl-0 d-lg-none py-2' expand='lg'>
          <Nav
            className='hamburger-menu mr-auto'
            onClick={(e) => this.openNav()}
          >
            <Button>
              <i
                className='fa fa-bars'
                style={{ fontSize: '2em', color: 'white' }}
              />
            </Button>
          </Nav>

          <div className='ml-auto mr-4 notification__icon'>
            <NotificationDropdown />
          </div>

          <Nav
            onClick={(e) =>
              this.setState((prevState) => {
                return { ...prevState, toggleMenu: !prevState.toggleMenu }
              })
            }
          >
            <NavDropdown
              show={false}
              title={
                Object.keys(patient).length > 0 ? (
                  <span className='font-weight-light'>
                    {patient.firstName} {patient.lastName}
                  </span>
                ) : (
                  <Spinner
                    size='sm'
                    className='text-white-50'
                    animation='border'
                    variant='light'
                  />
                )
              }
            />
          </Nav>

          <Nav>
            <LanguageLinkButton type='icon' textColor='nav-link ml-3' />
          </Nav>

          <Navbar.Collapse
            in={this.state.toggleMenu}
            className='ml-3 mobile__dropdown'
          >
            <NavDropdown.Item
              className='py-3'
              as={Link}
              to='/settings/edit-profile'
            >
              <FontAwesomeIcon icon={faUserCog} className='mr-3' />
              {t('Edit Profile')}
            </NavDropdown.Item>

            <NavDropdown.Item className='py-3' as={Link} to='/family-members'>
              <FontAwesomeIcon icon={faUserFriends} className='mr-3' />
              {t('Family Members')}
            </NavDropdown.Item>

            <NavDropdown.Divider className='m-0' />

            <NavDropdown.Item className='py-3' onClick={this.logout}>
              <FontAwesomeIcon icon={faSignOutAlt} className='mr-3' />
              {t('Logout')}
            </NavDropdown.Item>
          </Navbar.Collapse>
        </Navbar>
      </>
    )
  }

  render() {
    return (
      <Fragment>
        {this.DesktopView()}
        {this.MobileView()}
      </Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    patient: state.user.currentUser
  }
}
export default connect(mapStateToProps)(withTranslation()(NavBar))
