import Cookies from 'universal-cookie'

const cookies = new Cookies()

const defaultOptions = {
  path: '/',
  domain:
    process.env.NODE_ENV === 'development'
      ? process.env.REACT_APP_DOMAIN
      : `.${process.env.REACT_APP_DOMAIN}`
}

export function getCookie(name) {
  return cookies.get(name, defaultOptions)
}

export function setCookie(name, value, options = {}) {
  const combinedOptions = {
    secure: options.secure || true,
    maxAge: options.maxAge || 28800,
    httpOnly: options.httpOnly || false,
    path: options.path || defaultOptions.path,
    domain: options.domain || defaultOptions.domain
  }

  return cookies.set(name, value, combinedOptions)
}

export function removeCookie(name) {
  return cookies.remove(name, defaultOptions)
}
